<template>
    <tr :key="index + '-text-row'">
        <td 
            class="row-action-cell"
            @click="editingDisabled || row.additionalFreeText.length > 9 ? '' : addCommentRow(row.id)"
          >
            <i class="fa fa-plus-circle white"></i>
          </td>
        <td class="comment-row" colspan="10">
            <input
                maxlength="96"
                class="form-control form-control-sm"
                @keyup.ctrl.enter="addRow"
                @keyup.ctrl.c="copyRow(row)"
                @keyup.ctrl.delete="deleteRow(row.id)"
                v-model="info"
                v-bind:placeholder="$t('product.comment')"
                :disabled="editingDisabled"
            />
        </td>
    </tr>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: ["row", "index", "editingDisabled"],
    computed: {
        info: {
            get: function() {
                return this.row.info;
            },
            set: function(value) {
                this.$emit("update", { field: "info", value });
            }
        }
    },
    methods: {
        ...mapActions("invoice", [
            "addRow",
            "addCommentRow",
            "deleteRow",
            "copyRow",
            "addToInvalidFields"
        ])
    }
};
</script>
<style scoped>

.form-control-sm {
  padding: 0 2em 0 0.5em;
}

.table tbody + tbody {
  border-top: none;
}
.white {
  padding-top: 0.5em;
  color: #96cad4;
}

input {
  width: 100%;
  border-radius: 0;
}
.form-control-sm {
  height: 2.25em !important;
}
select {
  border-radius: 0;
}
.row-action-cell {
  padding: 0 1em 0 1em;
  cursor: pointer;
}
.row-action-cell-split {
  padding: 0.5em 0 0.5em 0;
  color: #96cad4;
  font-size: 16px;
  width: 50%;
  cursor: pointer;
}
.row-action-cell-split:hover {
  background-color: #5294a7;
}
.row-action-cell:hover {
  background-color: #5294a7;
}

@media screen and (min-width: 1441px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }
}
@media screen and (max-width: 1440px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }
}

@media only screen and (max-width: 800px) {
  #row-viewer {
    margin-top: 0.5em;
    margin-bottom: 2rem;
  }
  .flip-table .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .flip-table * html .cf {
    zoom: 1;
  }
  .flip-table *:first-child + html .cf {
    zoom: 1;
  }

  .flip-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-table th,
  .flip-table td {
    margin: 0;
    vertical-align: top;
  }
  .flip-table th {
    text-align: left;
    min-height: 40px;
  }

  .flip-table table {
    display: block;
    position: relative;
    width: 100%;
  }
  .flip-table thead {
    display: block;
    float: left;
    width: 7em;
  }
  .flip-table tbody {
    display: block;
    width: 60%;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .flip-table thead tr {
    display: block;
    border-top: 1px solid #dee2e6;
  }
  .flip-table th {
    display: block;
    text-align: center;
    border: none;
  }
  .flip-table tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .flip-table td {
    display: block;
    min-height: 40px;
    text-align: left;
    width: 100%;
  }
  .flip-table td > input {
    height: 100%;
  }

  .row-action-cell-split {
    text-align: center;
  }

}
@media only screen and (max-width: 426px) {
  .flip-table tbody {
    width: 300px;
  }
}
@media only screen and (max-width: 376px) {
  .flip-table tbody {
    width: 190px;
  }
}
/* FLIP END */
</style>
