import Vue from 'vue'
import App from './App'
import router from './router'
import createStore  from './store'
import VueResource from 'vue-resource'
import { ClientTable, ServerTable } from 'vue-tables-2'
import vuexI18n from 'vuex-i18n'
import { messages } from './locale'
import InvoiceBuilder from './components/InvoiceBuilder'
import PurchaseInvoice from './components/purchaseInvoice/PurchaseInvoice'
import { tokenHandler } from '@/client'

var $ = require('jquery');
window.$ = $;
require('bootstrap');
require('./style/style.scss')

Vue.config.productionTip = false;
Vue.use(VueResource)
Vue.use(ClientTable, {}, false)
Vue.use(ServerTable, {}, false)
window.onload = function() {
  let loaded = false
  setTimeout(() => {
    if (!loaded) {
      localStorage.removeItem("invoice")
    }
  }, 2000)
  window.iFrameResizer = {
    messageCallback: function(message) {
      let userdata = {...message[0], ...message[1], locale:message[message.length-1].locale}
      if (!userdata.authtoken) {
        userdata.authToken = ''
      } else {
        userdata.authToken = userdata.authtoken
        delete userdata.authtoken
      }
      let skin = 'apix'
      import(`./assets/customstyle/${skin}.css`)

      let app = message[message.length-1].app
      loaded = true
      let component = app === "InvoiceBuilder" ? InvoiceBuilder : PurchaseInvoice
      let store = createStore(userdata, app)

      Vue.use(tokenHandler, { store, startToken: userdata.authToken })
      Vue.use(vuexI18n.plugin, store)

      Vue.i18n.add('fi', messages.fi)
      Vue.i18n.add('en', messages.en)
      Vue.i18n.add('sv', messages.sv)
      Vue.i18n.add('svfi', messages.svfi)
      let locale = userdata.locale || 'fi'
      Vue.i18n.set(locale)

      Vue.directive('click-outside', {
        bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
              // and if it did, call method provided in attribute value
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.clickOutsideEvent)
        },
        unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent)
        },
      });

      new Vue({
        router: router,
        store,
        render: h => h(App)
      }).$mount('#app')
      let name = app.charAt(0).toLowerCase()+app.slice(1)
      router.replace({ name })

    }
  }
}