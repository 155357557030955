/* eslint-disable */

import { updateField } from 'vuex-map-fields'
import { initialState } from './initialState.js'
import { initInvoice } from './initInvoice.js'
import { formatNumber, addDays, createFinvoice, sumRows, nullToEmpty, nullToEmptyObj, validateNumberInput, calculateReferenceVerification } from '../../utils'
import { pvAddressesFI, pvAddressesEN, pvAddressesSV } from '../../utils/addresses'
import { codes, operators } from '@/assets'
import { sendInvoiceZip } from '../../client'
import Vue from 'vue'
const uuidv4 = require('uuid/v4')

export const mutations = {
    updateField,

    SET_AUTH_TOKEN(state, data) {
        state.userData.authToken = data
    },
    SELECT_INVOICE(state, { data, isNew }) {
        if (isNew) {
            data.invoiceId = uuidv4()
        }
        let languages = {
            "en_US": "en",
            "fi_FI": "fi",
            "sv_SE": "sv",
            "sv_FI": "svfi"
        }
        let initState = initInvoice(data.invoiceId, state.userData)
        let b = initState.invoiceField.billingInfo
        data = nullToEmptyObj(data)
        b.BIC = data.BIC
        b.paymentMethod = data.paymentMethod
        let bAcc = {Type:'', Value: ''}
        if (state.userData.laskumappiSkin && state.userData.laskumappiSkin === "financelink") {
            if (state.bankAccounts.length > 0) {
                bAcc = state.bankAccounts[0]
            }
        } else {
            bAcc = (state.bankAccounts.find(b => b.Value === data.bankAccount) || { Type: "", Value: "" })
        }
        b.bankAccount = bAcc

        b.orderReference = data.orderReference
        b.currency = { value: data.currency, name: data.currency }
        b.discount = validateNumberInput(data.discount) ? formatNumber(data.discount) : data.discount
        b.dueDate = data.dueDate
        b.contactPerson = data.contactPerson
        b.creditNote = data.creditNote
        b.invoiceLanguage = languages[data.language]
        b.invoiceNumber = data.invoiceNumber
        if (state.userData.entryPoint === "laskumappi" && isNew) {
            b.bankReference =
                data.invoiceNumber + calculateReferenceVerification(data.invoiceNumber);
        } else {
            b.bankReference = data.bankReference
        }
        b.rounding = data.cashRounding
        b.selectedTaxCategory = data.taxCountry.toLowerCase()
        b.sentDate = data.invoiceDate
        initState.status = "OPEN"
        if (isNew) {
            initState.appendices.StorageID = ""
            initState.appendices.StorageKey = ""
            initState.appendices.files = []
        } else {
            initState.appendices.StorageID = data.storageId
            initState.appendices.StorageKey = data.storageKey
        }

        b.eInvoiceAddress = { name: "", value: "" }
        b.operator = { name: "", value: "" }
        b.sendereInvoiceAddress = ""
        b.reverseVAT = data.reverseVAT || false

        initState.invoiceField.additionalInfo.additionalInfo = data.freeText
        initState.invoiceField.additionalInfo.deliveryMethod = data.deliveryMethod
        initState.invoiceField.additionalInfo.deliveryDay = data.deliveryDate
        initState.invoiceField.additionalInfo.vatFreeText = data.vatFreeText
        b.paymentCondition = data.paymentTermsDays
        if (data.penaltyInterest) {
            b.penaltyInterest =  formatNumber(data.penaltyInterest)
        }

        let roles = {
            "BUYER": "customerAddress",
            "PAYER": "billingAddress",
            "DELIVERY": "deliveryAddress",
            "ISSUER": "billingInfo"
        }
        let operatorList = state.userData.entryPoint === "laskumappi" ? operators.fi : operators.se
        //Addresses
        let customerNumber = null
        if (data.anyParties) {
        data.anyParties.forEach(a => {
            a = nullToEmptyObj(a)
            let c = initState.invoiceField[roles[a.role]]
            if (a.role !== "DELIVERY") {
                c.businessId = a.businessId
            }
            if (a.role === "ISSUER") {
                c.fax = a.fax
                c.email = a.email
                c.www = a.www
                c.phone = a.phone
            }

            c.name = a.name
            c.postal = a.postal
            c.zipCode = a.zipCode
            c.address = a.address
            c.country = codes[state.userData.locale].find(country => country.value === a.country) || a.country
            if (a.vatid && a.role === "ISSUER") {
                c.taxCode = a.vatid
            }
            if (a.role !== "ISSUER") {
                customerNumber = a.customerNumber
            }

            if (a.eInvoiceAddress && a.role === "PAYER") {
                b.eInvoiceAddress = { name: "other", value: "other" }
                b.eInvoiceAddressManual = a.eInvoiceAddress
                b.operator = operatorList.find(o => o.name === a.operator) || { name: "", value: "" }
            }

            if (a.eInvoiceAddress && a.role === "ISSUER") {
                b.sendereInvoiceAddress = a.eInvoiceAddress
            }
            if (a.role === "PAYER" && a.email) {
                b.eInvoiceAddress = { name: "EMAIL", value: "EMAIL" }
                b.receiverEmail = a.email
            }

        })
    }

        const processNum = (value) => {
            return value === null ? nullToEmpty(value) : formatNumber(value)
        }
        if (data.invoiceLines) {

            let rows = data.invoiceLines.map(i => {
                let id = ++initState.currentRowId
                return {
                    productId: null,
                    id: id,
                    name: i.name,
                    nameIsValid: null,
                    code: i.code,
                    codeIsValid: null,
                    ammount: processNum(i.ammount),
                    ammountIsValid: null,
                    unit: i.unit,
                    price: processNum(i.price),
                    priceIsValid: null,
                    discount: processNum(i.discount),
                    discountIsValid: null,
                    VAT: i.VAT,
                    withoutVAT: 0,
                    totalVAT: 0,
                    total: 0,
                    info: i.info,
                    additionalFreeText: i.additionalFreeText
                }
            })
            initState.productRow = rows
        }

        state = Object.assign(state, initState)
        state.spinner = false
        state.editingDisabled = false
        if (!isNew) {
            this.dispatch('invoice/listAppendices', { storageId: data.storageId, storageKey: data.storageKey })
        }
        if (customerNumber) {
            this.dispatch('invoice/fetchSelectedCustomerRecord', { token: null, number: customerNumber })
        }

        //  this.dispatch('invoice/updateSum')

    },

    SET_PREFILL_DATA(state, data) {
        let initState = initialState()
        //   state = Object.assign(state, initState)
        state = Object.assign(state, data)
    },

    SET_EINVOICE_ADDRESSES(state, data) {
/*         let operatorList = state.userData.entryPoint === "laskumappi" ? operators.fi : operators.se
        let currAddr = state.invoiceField.billingInfo.eInvoiceAddress
        state.eInvoiceAddresses = data
        if (state.customerRecordSelected && state.customerRecordSelected.eInvoiceAddress) {
            if (data.find(a => a.value === state.customerRecordSelected.eInvoiceAddress)) {
                state.invoiceField.billingInfo.eInvoiceAddress = { name: state.customerRecordSelected.eInvoiceAddress, value: state.customerRecordSelected.eInvoiceAddress }
            } else if (state.customerRecordSelected.eInvoiceAddress === "TULOSTUS"){ 
                state.invoiceField.billingInfo.eInvoiceAddress = {name: "TULOSTUS", value: "TULOSTUS"}
            } else {
                state.invoiceField.billingInfo.eInvoiceAddress = { name: 'other', value: 'other' }
                state.invoiceField.billingInfo.eInvoiceAddressManual = state.customerRecordSelected.eInvoiceAddress
                state.invoiceField.billingInfo.operator = operatorList.find(o => o.name === state.customerRecordSelected.operator) || { name: "", value: "" }
            }
        } else if (state.invoiceField.billingInfo.eInvoiceAddress.value === "other") {
            let addr = data.find(a => a.value === state.invoiceField.billingInfo.eInvoiceAddressManual)
            if (addr) {
                state.invoiceField.billingInfo.eInvoiceAddress = addr
                state.invoiceField.billingInfo.operator = { name: "", value: "" }
            }
            if (state.invoiceField.billingInfo.eInvoiceAddressManual === "TULOSTUS") {
                state.invoiceField.billingInfo.eInvoiceAddress = {name: "TULOSTUS", value: "TULOSTUS"}
            }
            
        } else if (currAddr.value === "EMAIL") {

        } else if (currAddr.value === "TULOSTUS") {
            state.invoiceField.billingInfo.eInvoiceAddress = {name: "TULOSTUS", value: "TULOSTUS"}
        } else {
            state.invoiceField.billingInfo.eInvoiceAddress = data[0]
        } */

    },
    RESET_EINVOICE_ADDRESSES(state) {
/*         let operatorList = state.userData.entryPoint === "laskumappi" ? operators.fi : operators.se
        let addr = state.invoiceField.billingInfo.eInvoiceAddress.value
        state.eInvoiceAddresses = []
        if (addr === "other" || addr === "EMAIL" || addr === "TULOSTUS") {
        } else if (state.customerRecordSelected && state.customerRecordSelected.eInvoiceAddress) {
            state.invoiceField.billingInfo.eInvoiceAddress = { name: 'other', value: 'other' }
            state.invoiceField.billingInfo.eInvoiceAddressManual = state.customerRecordSelected.eInvoiceAddress
            state.invoiceField.billingInfo.operator = operatorList.find(o => o.name === state.customerRecordSelected.operator) || { name: "", value: "" }
        } else {
            state.invoiceField.billingInfo.eInvoiceAddress = { name: '', value: '' }
        } */
    },
    DESELECT_CUSTOMER_RECORD(state) {
        state.customerRecordSelected = null
        let fields = ["name", "address", "postal", "zipCode", "country", "businessId"]

        fields.forEach(k => {
            state.invoiceField.billingAddress[k] = ""
        })
        state.invoiceField.billingInfo.eInvoiceAddress = {name: "", value: ""}
    },
    CUSTOMER_SELECTED(state, data) {
        state.customerRecordSelected = data
    },
    SET_SELECTED_CUSTOMER_DATA(state, number) {
        let locale = state.userData.locale
        let localizedData = locale === 'fi' ? pvAddressesFI : locale === 'en' ? pvAddressesEN : pvAddressesSV
        let data = localizedData.find(a => a.customerNumber === number)
        state.customerRecordSelected = data
        let buyer = null
        let payer = data.addresses.find(a => a.role === "PAYER")
        let delivery = null

        let fields = ["name", "address", "postal", "zipCode", "country", "businessId"]
/*         if (buyer) {
            fields.forEach(k => {
                if (k === "country") {
                    if (buyer[k]) {
                        state.invoiceField.customerAddress[k] = codes[locale].find(
                            country =>
                                country.value.toLowerCase() === buyer.country.toLowerCase()
                        );
                    } else {
                        state.invoiceField.customerAddress[k] = buyer[k]
                    }
                } else {
                    state.invoiceField.customerAddress[k] = buyer[k]
                }
            })
        } else {
            fields.forEach(k => {
                state.invoiceField.customerAddress[k] = ""
            })
        }
 */
        if (payer) {
            fields.forEach(k => {
                if (k === "country") {
                    if (payer[k]) {
                        state.invoiceField.billingAddress[k] = codes[locale].find(
                            country =>
                                country.value.toLowerCase() === payer.country.toLowerCase()
                        );
                    } else {
                        state.invoiceField.billingAddress[k] = payer[k]
                    }
                } else {
                    state.invoiceField.billingAddress[k] = payer[k]
                }
            })
        } else {
            fields.forEach(k => {
                state.invoiceField.billingAddress[k] = ""
            })
        }

        fields.pop()
/*         if (delivery) {
            fields.forEach(k => {
                if (k === "country") {
                    if (delivery[k]) {
                        state.invoiceField.deliveryAddress[k] = codes[locale].find(
                            country =>
                                country.value.toLowerCase() === delivery.country.toLowerCase()
                        );
                    } else {
                        state.invoiceField.deliveryAddress[k] = delivery[k]
                    }
                } else {
                    state.invoiceField.deliveryAddress[k] = delivery[k]
                }
            })
        } else {
            fields.forEach(k => {
                state.invoiceField.deliveryAddress[k] = ""
            })
        } */


        state.invoiceField.billingInfo.eInvoiceAddress = {name: data.eInvoiceAddress, value: data.eInvoiceAddress}
    },

    SET_ADDRESS_DATA(state, { data, field }) {
        if (!data.YritysLopetettu) {
            state.invoiceField[field].name = data.Toiminimi
            state.invoiceField[field].businessId = data.YTunnus
            state.invoiceField[field].address = data.YrityksenPostiOsoite.Katuosoite
            state.invoiceField[field].zipCode = data.YrityksenPostiOsoite.Postinumero
            state.invoiceField[field].postal = data.YrityksenPostiOsoite.Toimipaikka
            let locale = state.userData.locale
            state.invoiceField[field].country = codes[locale].find(country => {
                return country.value === data.YrityksenPostiOsoite.Maakoodi
            })
        }
    },
    SET_USER_CONTACT_INFO(state, data) {
        state.invoiceField.billingInfo.contactPerson = data.ContactPerson || ''
        let locale = state.userData.locale
        if (data.AnyParty) {
            state.invoiceField.billingInfo.name = data.AnyParty.Name || ''
            state.invoiceField.billingInfo.address = data.AnyParty.Street1 || ''
            state.invoiceField.billingInfo.zipCode = data.AnyParty.PostCode || ''
            state.invoiceField.billingInfo.postal = data.AnyParty.City || ''
            let email = data.Data.find(row => { return row.Type === "EMAIL" }) || ''
            let phone = data.Data.find(row => { return row.Type === "PHONE" }) || ''
            let www = data.Data.find(row => { return row.Type === "WWW" }) || ''
            let fax = data.Data.find(row => { return row.Type === "FAX" }) || ''
            state.invoiceField.billingInfo.www = www.Value || ''
            state.invoiceField.billingInfo.email = email.Value || ''
            state.invoiceField.billingInfo.fax = fax.Value || ''
            state.invoiceField.billingInfo.phone = phone.Value || ''
            state.invoiceField.billingInfo.country = codes[locale].find(country => {
                return country.value === data.AnyParty.CountryCode
            }) || ''
            state.invoiceField.billingInfo.businessId = data.AnyParty.BusinessID || ''
            state.invoiceField.billingInfo.penaltyInterest = data.AnyParty.PenaltyInterest && formatNumber(data.AnyParty.PenaltyInterest) || '0,00'
            state.invoiceField.billingInfo.paymentCondition = data.DefaultPaymentDays || 1

        }
        state.invoiceField.billingInfo.sentDate = new Date().toISOString().split('T')[0]
    },
    SET_USER_BILLING_DATA(state, data) {
        if (data.Data) {
            if (state.userData.laskumappiSkin && state.userData.laskumappiSkin === "financelink") {

            } else {
                state.bankAccounts = data.Data
                .filter(value => value.Type === 'IBAN' || value.Type === 'BANKGIRO' || value.Type === 'PLUSGIRO' || value.Type === 'OTHER')
            }
            let sendereInvoiceAddress = data.Data.find(row => { return row.Type === "EINVOICEADDRESS" }) || ''
            if (sendereInvoiceAddress === '' || sendereInvoiceAddress.Value === '') {
                state.userData.sendereInvoiceAddress = ''
                if (state.invoiceField.billingInfo.country !== '' && state.invoiceField.billingInfo.country.value === 'FI') {
                    state.invoiceField.billingInfo.sendereInvoiceAddress = "0037" + state.invoiceField.billingInfo.businessId.replace("-", "")
                }
            } else {
                state.userData.sendereInvoiceAddress = sendereInvoiceAddress.Value
            }
        }
    },

    SET_USER_EXTRA_INFO(state, data) {
        if (data.VatID != "") {
            state.invoiceField.billingInfo.taxCode = data.VatID
        }
    },

    SET_USER_LOGO(state, data) {
        state.invoiceLogo = data
    },

    ADD_ROW(state) {
        let previousVAT = state.productRow[state.productRow.length - 1] && state.productRow[state.productRow.length - 1].VAT || '0'
        let id = ++state.currentRowId
        state.productRow.push({
            productId: null,
            id: id,
            name: '',
            nameIsValid: null,
            code: '',
            codeIsValid: null,
            ammount: '',
            ammountIsValid: null,
            unit: 'piece',
            price: '',
            priceIsValid: null,
            discount: '',
            discountIsValid: null,
            VAT: previousVAT,
            withoutVAT: 0,
            totalVAT: 0,
            total: 0,
            info: '',
            additionalFreeText: []
        });
    },

    COPY_ROW(state, data) {
        let id = ++state.currentRowId
        state.productRow.push({
            productId: null,
            id: id,
            name: data.name,
            nameIsValid: data.nameIsValid,
            code: data.code,
            codeIsValid: data.codeIsValid,
            ammount: data.ammount,
            ammountIsValid: data.ammountIsValid,
            unit: data.unit,
            price: data.price,
            priceIsValid: data.priceIsValid,
            discount: data.discount,
            discountIsValid: data.discountIsValid,
            VAT: data.VAT,
            withoutVAT: data.withoutVAT,
            totalVAT: data.totalVAT,
            total: data.total,
            info: data.info,
            additionalFreeText: [...data.additionalFreeText]
        })
    },
    ADD_COMMENT_ROW(state, index) {
        state.productRow.find(r => r.id === index).additionalFreeText.push("")
    },
    DELETE_COMMENT_ROW(state, {rowId, commentIndex}) {
        state.productRow.find(r => r.id === rowId).additionalFreeText.splice(commentIndex, 1)

    },

    UPDATE_SUM(state, data) {
        state.taxations = data

    },
    UPDATE_APPENDICES(state, data) {
        state.appendices.files = data
    },

    DELETE_ROW(state, id) {
        let rows = state.productRow.filter(row => {
            return row.id !== id
        })
        state.productRow = rows
    },

    SAVE_FORM(state) {
    },

    ADD_INVALID_FIELDS(state, obj) {
        if (obj.name === 'rowInfo') {
            state.invalidFields.productRow[obj.index] = obj.fields
        } else {
            state.invalidFields[obj.name] = obj.fields
        }
    },
    VALIDATE_AND_SEND_INVOICE2(state) {
        let invoiceXML = createFinvoice(state)
        console.log(invoiceXML)
        
/*          let saveInvoice = Object.assign({}, state)
        saveInvoice.status = "SENT"
        this.dispatch("invoice/updateInvoiceToDb", saveInvoice).then(response => {
            console.log("invoice updated", response)
        })

        this.dispatch("invoice/newInvoice")
        this.dispatch("invoice/fetchPrefillCustomerData", null)
        localStorage.removeItem("invoice") */
 /*        let products = state.productRow
        products.forEach(p => {
            let newProduct = {
                code: p.code,
                name: p.name,
                unitPrice: p.price.replace(",", "."),
                unit: p.unit,
                taxPercent: p.VAT.toString()
            }
            if (p.productId) {
                newProduct.productId = p.productId
                this.dispatch('product/updateProduct', { data: newProduct, token: state.userData.authToken }).then(() => {
                    console.log('productUpdate', newProduct)
                }).catch(error => {
                    console.log('errorUpdating', error)
                })
            } else {
                this.dispatch('product/addProduct', { data: newProduct, token: state.userData.authToken }).then(() => {
                    console.log('productAdd', newProduct)
                }).catch(error => {
                    console.log('errorAdding', error)
                })
            }
        })

        let addresses = []
        let c, addr;
        if (state.invoiceField.customerAddress.businessId) {
            c = state.invoiceField.customerAddress
            addr = {
                address: c.address,
                businessId: c.businessId,
                country: c.country.value,
                postal: c.postal,
                role: "BUYER",
                zipCode: c.zipCode,
                name: c.name
            }
            addresses.push(addr)
        }
        let request = {
            eInvoiceAddress: "",
            operator: "",
            customerNumber: state.customerRecordSelected ? state.customerRecordSelected.customerNumber : 0,
            addresses: null,
            isFavorite: state.customerRecordSelected && state.customerRecordSelected.isFavorite ? 1 : 0
        }
        if (state.invoiceField.billingAddress.businessId) {
            c = state.invoiceField.billingAddress
            addr = {
                address: c.address,
                businessId: c.businessId,
                country: c.country.value,
                postal: c.postal,
                role: "PAYER",
                zipCode: c.zipCode,
                name: c.name
            }
            addresses.push(addr)
        }

        if (state.invoiceField.deliveryAddress.name) {
            c = state.invoiceField.deliveryAddress
            addr = {
                address: c.address,
                country: c.country.value,
                postal: c.postal,
                role: "DELIVERY",
                zipCode: c.zipCode,
                name: c.name,
                businessId: ""
            }
            addresses.push(addr)
        }
        request.addresses = addresses
        if (state.invoiceField.billingInfo.eInvoiceAddress.value) {
            if (state.invoiceField.billingInfo.eInvoiceAddress.value === "other") {
                request.eInvoiceAddress = state.invoiceField.billingInfo.eInvoiceAddressManual
                request.operator = state.invoiceField.billingInfo.operator.name
            } else {
                request.eInvoiceAddress = state.invoiceField.billingInfo.eInvoiceAddress.value
                request.operator = state.customerRecordSelected && state.customerRecordSelected.operator || "Apix Messaging Oy"
            }
        }
        let token = state.userData.authToken
        if (state.customerRecordSelected) {
            request.originalNumber = request.customerNumber
            this
                .dispatch("customer/updateCustomer", { req: request, token })
                .then(data => {
                    console.log("update customer", request);
                })
                .catch(error => {
                    console.log("failed updating customer", request, error)
                });
        } else {
            this
                .dispatch("customer/addCustomer", { req: request, token })
                .then(data => {
                    console.log("new customer", request);
                })
                .catch(error => {
                    console.log("failed adding customer", request, error)
                });
        } */
    },
    VALIDATE_AND_SEND_INVOICE(state) {
        let valid = true
        for (const key of Object.keys(state.invalidFields)) {
            if (state.invalidFields[key].length > 0) {
                valid = false
            }
        }
        let invalidFields = []
        let rowValidations = ['nameIsValid', 'ammountIsValid', 'priceIsValid', 'discountIsValid', 'codeIsValid']
        let rowInd = 1
        state.productRow.map(row => {
            rowValidations.forEach(field => {
                if (field === 'discountIsValid' && row[field] === null) {
                } else if (row[field] === null || row[field] === false) {
                    row[field] = false
                    valid = false
                    invalidFields.push(Vue.i18n.translate("invoiceForm.row") + rowInd + ": " +
                        Vue.i18n.translate("product." + field.slice(0, -7)) + "\n")
                }
            })
            rowInd++
        })
        if (valid) {
            let invoiceXML = createFinvoice(state)
            console.log(invoiceXML)
            state.spinner = true
            state.invoiceField.editingDisabled = true
            let soft = state.userData.entryPoint === "fakturamappen" ? "Fakturamappen" : "Laskumappi"
            if (state.userData.laskumappiSkin && state.userData.laskumappiSkin === "financelink") {
                soft = "LaskumappiFL"
            }
            sendInvoiceZip(state.userData.TransferID, state.userData.TransferKey, invoiceXML, soft).then(response => {
                let status = response.body.Response.Status
                if (status === "ERR") {
                    window.alert(Vue.i18n.translate("invoiceForm.sendingFail") + ": " + response.body.Response.FreeText[1].$)
                } else if (status === "OK") {
                    //Add or update product record
                    let products = state.productRow
                    products.forEach(p => {
                        let newProduct = {
                            code: p.code,
                            name: p.name,
                            unitPrice: p.price.replace(",", "."),
                            unit: p.unit,
                            taxPercent: p.VAT.toString()
                        }
                        if (p.productId) {
                            if (!state.invoiceField.billingInfo.reverseVAT) {
                                newProduct.productId = p.productId
                                this.dispatch('product/updateProduct', { data: newProduct, token: state.userData.authToken }).then(() => {
                                    console.log('productUpdate', newProduct)
                                }).catch(error => {
                                    console.log('errorUpdating', error)
                                })
                            }
                        } else {
                            this.dispatch('product/addProduct', { data: newProduct, token: state.userData.authToken }).then(() => {
                                console.log('productAdd', newProduct)
                            }).catch(error => {
                                console.log('errorAdding', error)
                            })
                        }
                    })

                    let addresses = []
                    let c, addr;
                    if (state.invoiceField.customerAddress.businessId) {
                        c = state.invoiceField.customerAddress
                        addr = {
                            address: c.address,
                            businessId: c.businessId,
                            country: c.country.value,
                            postal: c.postal,
                            role: "BUYER",
                            zipCode: c.zipCode,
                            name: c.name
                        }
                        addresses.push(addr)
                    }
                    let request = {
                        eInvoiceAddress: "",
                        operator: "",
                        customerNumber: state.customerRecordSelected ? state.customerRecordSelected.customerNumber : 0,
                        addresses: null,
                        isFavorite: state.customerRecordSelected && state.customerRecordSelected.isFavorite ? 1 : 0
                    }
                    if (state.invoiceField.billingAddress.businessId) {
                        c = state.invoiceField.billingAddress
                        addr = {
                            address: c.address,
                            businessId: c.businessId,
                            country: c.country.value,
                            postal: c.postal,
                            role: "PAYER",
                            zipCode: c.zipCode,
                            name: c.name
                        }
                        addresses.push(addr)
                    }

                    if (state.invoiceField.deliveryAddress.name) {
                        c = state.invoiceField.deliveryAddress
                        addr = {
                            address: c.address,
                            country: c.country.value,
                            postal: c.postal,
                            role: "DELIVERY",
                            zipCode: c.zipCode,
                            name: c.name,
                            businessId: ""
                        }
                        addresses.push(addr)
                    }
                    request.addresses = addresses
                    if (state.invoiceField.billingInfo.eInvoiceAddress.value) {
                        if (state.invoiceField.billingInfo.eInvoiceAddress.value === "other") {
                            request.eInvoiceAddress = state.invoiceField.billingInfo.eInvoiceAddressManual
                            request.operator = state.invoiceField.billingInfo.operator.name
                        } else {
                            request.eInvoiceAddress = state.invoiceField.billingInfo.eInvoiceAddress.value
                            request.operator = state.customerRecordSelected && state.customerRecordSelected.operator || "Apix Messaging"
                        }
                    }
                    let token = state.userData.authToken
/*                     if (state.customerRecordSelected && addresses.length > 0) {
                        request.originalNumber = request.customerNumber
                        this
                            .dispatch("customer/updateCustomer", { req: request, token })
                            .then(data => {
                                console.log("update customer", request);
                            })
                            .catch(error => {
                                console.log("failed updating customer", request, error)
                            });
                    } else if (addresses.length > 0) {
                        this
                            .dispatch("customer/addCustomer", { req: request, token })
                            .then(data => {
                                console.log(data)
                                console.log("new customer", request);
                            })
                            .catch(error => {
                                console.log("failed adding customer", request, error)
                            });
                    } */
                    let saveInvoice = Object.assign({}, state)
                    saveInvoice.status = "SENT"
                    this.dispatch("invoice/updateInvoiceToDb", saveInvoice).then(response => {
                        console.log("invoice updated", response)
                    })

                    window.alert(Vue.i18n.translate("invoiceForm.sendingSuccess"))
                   /*  let id = uuidv4()
                    let initState = initInvoice(id, state.userData)
                    state = Object.assign(state, initState) */
                    this.dispatch("invoice/newInvoice")
                   // this.dispatch("invoice/fetchPrefillCustomerData", null)
                    localStorage.removeItem("invoice")
                }
                state.spinner = false
                state.invoiceField.editingDisabled = false
            }).catch(error => {
                console.log(error)
                state.spinner = false
                state.invoiceField.editingDisabled = false
                window.alert(Vue.i18n.translate("invoiceForm.sendingFail") + ": " + error)
            })
        } else {
            let invalidFieldsStr = ''
            for (const key of Object.keys(state.invalidFields)) {
                if (state.invalidFields[key].length > 0) {
                    if (key !== "productRow") {
                        invalidFieldsStr += "\n" + Vue.i18n.translate("invoiceForm." + key) + ":\n"
                        let strings = []
                        state.invalidFields[key].forEach(field => {
                            strings.push(Vue.i18n.translate("invoiceForm." + field))
                        })
                        let str = strings.join(", ")
                        invalidFieldsStr += str
                    }

                }
            }
            if (invalidFields) {
                invalidFieldsStr += "\n" + invalidFields.join("")
            }

            window.alert(Vue.i18n.translate("invoiceForm.invalidInput") + invalidFieldsStr)
        }
    },
    UPDATE_ROW(state, newRow) {
        console.log(newRow)
         const index = state.productRow.findIndex(r => r.id === newRow.id)
     //    let newRows = [...state.productRow.slice(0, index-1), newRow, ...state.productRow.slice(index+1, state.productRow.length)]
      //  newRows.splice(index, 1, newRow)
       // Vue.set(state.productRow, index, newRow)
       console.log("replacing row at " + index + " with " + newRow)
        state.productRow.splice(index, 1, newRow)
      //  Vue.set(state, "productRow", newRows)

    },
    UPDATE_ROW_FIELD(state, req) {
        const index = state.productRow.findIndex(r => r.id === req.rowId)
        state.productRow[index][req.event.field] = req.event.value
    },
    UPDATE_FIELD(state, obj) {
        state.invoiceField[obj.formGroup][obj.dataField] = obj.value
        //console.log(state)
    },
    VALIDATE_FIELD(state, obj) {
        state.invoiceField[obj.formGroup].validations[obj.dataField + 'IsValid'] = obj.value
    },

    INIT_INVOICE(state, num) {
        let id = uuidv4()
        let initState = initInvoice(id, state.userData)
        if (state.userData.laskumappiSkin && state.userData.laskumappiSkin === 'financelink'){
            initState.invoiceField.billingInfo.bankAccount = state.bankAccounts[0] || {Type: '', Value: ''}
        }
        initState.invoiceField.billingInfo.invoiceNumber = num
        if (state.userData.entryPoint === "laskumappi") {
            initState.invoiceField.billingInfo.bankReference =
                num + calculateReferenceVerification(num);
        }
        state = Object.assign(state, initState)
        state.status = "OPEN"
        localStorage.removeItem("invoiceId")
        
    },

    CREATE_APPENDIX_STORAGE(state, data) {
        Object.keys(data).forEach(item => {
            state.appendices[item] = data[item]
        })
    },

    SET_APPENDIX_FILES(state, data) {
        state.appendices.files = data
    },

    SET_SPINNER(state, data) {
        state.spinner = data
    },

    SET_DISABLED(state, data) {
        state.invoiceField.editingDisabled = data
    },

    TOGGLE_SECTION(state, data) {
        let parts = data.path.split('.')
        if (parts.length === 1) {
            state.sectionDisplay[data.path] = data.value
        } else {
            state.sectionDisplay[parts[0]][parts[1]] = data.value

        }
    },

    SET_DELIVERY_TYPE(state, data) {
        state.deliveryType = data
    },

}