export const fi = {
  common: {
    invoiceWriter: "Laskun kirjoitus",
    invoice: "Luo myyntilasku",
    purchaseInvoice: "Luo ostolasku",
    customerRecord: "Asiakasrekisteri",
    productRecord: "Tuoterekisteri",
    send: "Lähetä",
    delete: "Poista",
    reset: "Tyhjennä",
    new: "Uusi",
    upload: "Lataa",
    appendices: "Liitteet",
    invoices: "Laskut"
  },
  trafficLight: {
    GRAY: "Maksuviivemerkintää ei ole noudettu",
    GREEN: "Vastaanottajalla ei ole tiedossa maksuviiveitä",
    YELLOW: "Vastaanottajalla on maksuviiveitä"
  },
  purchaseInvoice: {
    uploadPDF: "Lataa laskun kuva PDF-muodossa",
    inputManually: "Syötä laskun tiedot käsin",
    sendToScanning: "Lähetä pdf skannauspalveluun",
    ready: "Valmis",
    basic: "Perustiedot",
    recipient: "Maksun saaja",
    taxBase: "Verokanta",
    withoutVAT: "Veroton määrä",
    totalVAT: "Veron määrä",
    total: "Yhteensä",
    image: "Kuva",
    back: "Takaisin",
    products: "Tuotteet",
    pce: "Kpl"
  },
  appendices: {
    selectFiles: "Valitse tiedosto(t)",
    size: "Koko",
    type: "Tyyppi",
    name: "Nimi"
  },
  invoiceForm: {
    addAccount: "Lisää tili",
    here: "täältä",
    name: "Yrityksen nimi",
    address: "Osoite",
    confirmReset: "Varmista laskun tyhjennys",
    contactPerson: "Yhteyshenkilö",
    businessId: "Y-tunnus",
    Ytunnus: "Y-tunnus",
    orgNumber: "Organisaatio numero",
    customerId: "Asiakasnumero",
    zipCode: "Postinumero",
    postal: "Postitoimipaikka",
    country: "Maa",
    bankAccount: "Tilinumero",
    bankReference: "Maksuviite",
    orderReference: "Tilausviite",
    invoiceNumber: "Laskun numero",
    invoiceChannel: "Laskukanava",
    sentDate: "Laskun päivä",
    dueDate: "Eräpäivä",
    penaltyInterest: "Viivästyskorko",
    discount: "Alennus-%",
    paymentCondition: "Maksuehto",
    deliveryMethod: "Toimitustapa",
    billingAddress: "Laskutusosoite",
    deliveryAddress: "Toimitusosoite",
    additionalInfo: "Lisätietoja",
    deliveryDay: "Toimituspäivä",
    customerAddress: "Asiakkaan osoite",
    paymentInfo: "Maksutiedot",
    rowInfo: "Rivitiedot",
    row: "Rivi",
    currency: "Valuutta",
    SEK: "Ruotsin kruunu",
    EUR: "Euro",
    NOK: "Norjan kruunu",
    rounding: "Pyöristys",
    reverseVAT: "Käänteinen ALV",
    vatFreeText: "Käänteisen veron selite",
    defaultVatFreeText: "AVL 8 c §:n mukaan arvonlisäveron maksaa ostaja",
    creditNote: "Hyvityslasku",
    VATCountry: "ALV maa",
    daysShort: "pv",
    total: "Summa",
    billingInfo: "Laskutustiedot",
    eInvoiceAddress: "Verkkolaskuosoite",
    addressInfo: "Laskuttajan tiedot",
    paymentSolution: "Maksutapa",
    eInvoiceAddressManual: "Muu verkkolaskuosoite",
    eInvoiceAddrRemoved: "Ei käytössä",
    operator: "Operaattori",
    orgId: "Organisaatio tunnus",
    email: "Sähköposti",
    fax: "Faksi",
    www: "Kotisivu",
    phone: "Puhelinnumero",
    changePrefillInfo: "Muuta esitäytettäviä tietoja täältä",
    finland: "suomi",
    england: "englanti",
    sweden: "ruotsi",
    invoiceLanguage: "Laskun kieli",
    BIC: "BIC",
    noeInvoiceAddress: "Ei verkkolaskuosoitteita",
    noAccounts: "Ei tilejä käyttäjällä",
    sendingFail: "Laskun lähetys epäonnistui",
    sendingSuccess: "Lasku lähetetty",
    invalidInput: "Tarkista laskun tiedot",
    taxCode: "ALV numero",
    other: "Syötä verkkolaskuosoite",
    sendToPrinting: "Lähetä tulostukseen",
    sendToEmail: "Lähetä sähköpostiin",
    receiverEmail: "Sähköpostiosoite",
    copyRow: "Kopioi rivi",
    deleteRow: "Poista rivi",
    addRow: "Lisää rivi",
    sendereInvoiceAddress: "Lähettäjän verkkolaskuosoite",
    barcode: "Viivakoodi",
    select: "Valitse",
    letterWillBeSent: "Lasku lähetetään kirjeenä",
    peppolAddrNotFound: "Peppol-osoitetta ei löytynyt",
    peppolInvalidFormat: "Peppol-osoite tulee alkaa neljällä numerolla ja kaksoispisteellä",
    emailSendingAttachments: "Käytä pdf-liitteitä sähköpostilla lähetettäessä"
  },
  invoice: {
    title: "LASKU",
    titleCreditNote: "HYVITYSLASKU",
    orderReference: "Viitteenne",
    contactPerson: "Käsittelijämme",
    orderer: "Tilaaja",
    billingAddress: "Laskutusosoite",
    deliveryAddress: "Toimitusosoite",
    paymentCondition: "Maksuehto",
    dueDate: "Eräpäivä",
    deliveryDate: "Toimituspäivä",
    deliveryMethod: "Kuljetustapa",
    penaltyInterest: "Viivästyskorko",
    vatNumber: "Alv-numero",
    customerNumber: "Asiakasnumero",
    bankReference: "Maksuviite",
    discount: "Alennus",
    type: {
      eInvoice: "Verkkolasku",
      Paper: "Kirje",
      email: "Sähköposti"
    },
    row: {
      name: "Nimike",
      amount: "Määrä",
      price: "A-hinta",
      withoutVAT: "Veroton määrä",
      VAT: "Alv",
      discount: "Alennus",
      total: "Yhteensä",
      sum: "summa",
      withoutVATSum: "Veroton summa",
      VATSum: "Verollinen summa",
    },
    rounding: "Pyöristys",
    amountToPay: "Maksettava",
    bankInfo: "Pankin tiedot",
    phone: "Puh",
    fax: "Fax",
    email: "E-mail",
    vatNumShort: "Alv no",
    Ytunnus: "Y-tunnus",
    businessId: "Y-tunnus",
    orgNumber: "Org. no.",
    flTransferClause: "Tämä saaminen on siirretty Finance Link Oy:lle. Maksun voi suorittaa pätevästi vain tilille IBAN: FI3215963000106205, BIC: NDEAFIHH. Saamiseen  kohdistuvat huomautukset on tehtävä välittömästi osoitteeseen laskutus@financelink.fi. Käsittelemme henkilötietoja kotisivuillamme financelink.fi/oikeudellista-tietoa olevan tietosuojaselosteen mukaisesti."
  },
  invoiceRecord: {
    noInvoices: "Ei laskuja",
    invoices: "Laskut",
    saveIncomplete: "Tallenna keskeneräisenä",
    useAsTemplate: "Ota pohjaksi",
    delete: "Poista",
    continue: "Jatka",
    incomplete: "Keskeneräiset",
    sent: "Lähetetyt",
    choose: "Valitse lasku"
  },
  product: {
    addRow: "Lisää rivi",
    name: "Tuote",
    code: "Koodi",
    ammount: "Määrä",
    unit: "Yksikkö",
    price: "Hinta",
    discount: "Ale-%",
    VAT: "ALV",
    withoutVAT: "Ilman ALV",
    totalVAT: "Alv-summa",
    total: "Summa",
    comment: "Lisätieto",
    delete: "Poista",
    search: "Tuote tai koodi",
    searchShort: "Hae",
    edit: "Muokkaa",
    setFavorite: "Aseta suosikiksi",
    save: "Tallenna",
    cancel: "Peru",
    back: "Takaisin",
    moreActions: "Lisää toimintoja",
    add: "Lisää",
    duplicateProduct: "Tuote on jo listassa",
    removeSelected: "Poista valitut",
    noResults: "Ei tuloksia",
    overTen: "Yli 10 tulosta",
    confirmDelete: "Vahvista poistaminen",
    table: {
      count: 'Näytetään {from}-{to} tuloksista|{count} tulosta|Yksi tulos',
      filter: 'Rajaa tuloksia:',
      filterPlaceholder: 'Hae',
      limit: 'Tuloksia:',
      page: 'Sivu:',
      noResults: 'Ei tuloksia',
      filterBy: 'Hae {column}lla',
      loading: 'Haetaan...',
      defaultOption: 'Valitse {column}'
    },
  },
  customer: {
    setFavorite: "Aseta suosikiksi",
    address: "Osoite",
    searchShort: "Hae",
    save: "Tallenna",
    back: "Takaisin",
    create: "Uusi",
    delete: "Poista",
    newCustomer: "Uusi asiakas",
    createNew: "Luo",
    addressType: "Osoitetyyppi",
    officialAddress: "Virallinen osoite",
    billingAddress: "Laskutusosoite",
    deliveryAddress: "Toimitusosoite",
    customerSearch: "Hae asiakasta",
    eInvoiceAddress: "Verkkolaskuosoite",
    customerNumber: "Asiakasnumero",
    isInUse: "on käytössä"
  },
  units: {
    cm: "cm",
    lot: "lot",
    gram: "g",
    hour: "h",
    person: "hlö",
    kilogram: "kg",
    month: "kk",
    kilometer: "km",
    piece: "kpl",
    kilowattHour: "kWh",
    liter: "l",
    box: "ltk",
    minute: "min",
    meter: "m",
    squareMeter: "m²",
    cubicMeter: "m³",
    millimeter: "mm",
    bottle: "plo",
    can: "tlk",
    bag: "pss",
    day: "pv",
    roll: "rl",
    page: "sivu",
    sack: "sk",
    series: "sarja",
    tube: "tuubi",
    ton: "t",
    year: "vuosi",
    week: "vko",
    fullDay: "vrk"
  },
  countries: {
    finland: "Suomi",
    sweden: "Ruotsi",
    england: "Iso-Britannia",
    netherlands: 'Alankomaat',
    austria: 'Itävalta'
  }
}