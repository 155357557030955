<template>

  <div class="container">
    <action-bar :toggle="mobileToggleInvoice"></action-bar>
    <div id="main-section" class="row no-gutters">
      <div class="col-xl-6 col-lg-12">
        <div class="row col-xl no-gutters">
          <div class="col-xl-6 col-lg-4 addresses">
            <billing-address></billing-address>
<!--             <customer-address></customer-address>
            <delivery-address></delivery-address> -->
          </div>
          <div class="col-xl-6 col-lg-8">
            <sender-info></sender-info>
            <billing-info></billing-info>
            <additional-info></additional-info>
          </div>
        </div>
      </div>
      <div v-if="windowWidth > 1200" class="invoice-viewer-container col-xl-6 col-lg-12">
        <invoice-viewer :windowWidth="windowWidth" :isMobile="isMobile"></invoice-viewer>
      </div>
    </div>
    <div class="row">
      <row-viewer :windowWidth="windowWidth" :isMobile="isMobile"></row-viewer>
    </div>
    <div v-if="windowWidth <= 1200">
      <invoice-viewer :windowWidth="windowWidth" :isMobile="isMobile"></invoice-viewer>
    </div>
    <spinner></spinner>
  </div>
</template>

<script>
import ActionBar from "./ActionBar.vue"
import Sidebar from "@/components/common/Sidebar.vue";
import AdditionalInfo from "./AdditionalInfo.vue";
import BillingAddress from "./BillingAddress.vue";
import BillingInfo from "./BillingInfo.vue";
import SenderInfo from "./SenderInfo.vue";
import CustomerAddress from "./CustomerAddress.vue";
import DeliveryAddress from "./DeliveryAddress.vue";
import InvoiceViewer from "./InvoiceViewer.vue";
import RowViewer from "./RowViewer.vue";
import Spinner from "./Spinner";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { dragscroll } from "vue-dragscroll";
const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  name: "InvoiceBuilder",
  directives: {
    dragscroll: dragscroll
  },
  data() {
    return {
      mobileShowInvoice: false,
      windowWidth: window.innerWidth,
      saveTimer: null
    };
  },

  components: {
    CustomerAddress,
    BillingAddress,
    DeliveryAddress,
    BillingInfo,
    SenderInfo,
    InvoiceViewer,
    AdditionalInfo,
    RowViewer,
    ActionBar,
    Spinner,
    Sidebar
  },
  computed: {
    ...mapFields(["selectedInvoice", "userData"]),
    isMobile: function() {
      return this.windowWidth <= 425;
    }
  },
  methods: {
    ...mapActions("invoice", [
      "resetForm",
      "sendInvoice",
      "fetchInvoice",
      "fetchPrefillData",
      "newInvoice"
    ]),
    mobileToggleInvoice: function() {
      this.mobileShowInvoice = !this.mobileShowInvoice;
    },
    debounceSave: function() {
      if (this.saveTimer) clearTimeout(this.saveTimer);
      var that = this;
      this.saveTimer = setTimeout(function() {
        let state = that.$store.state.invoice;
        console.log("SAVED", state);
        that.$store.dispatch("invoice/updateInvoiceToDb", state);
      }, 1000);
    }
  },
  created: function() {
    this.$store.dispatch("invoice/disableEditing", true);
    this.$store.dispatch("invoice/spin", true);
    this.$store
      .dispatch("invoice/fetchToken", {
        tid: this.userData.TransferID,
        tkey: this.userData.TransferKey
      })
      .then(data => {
        this.$store.dispatch("invoice/fetchPrefillBillingData", data);
          this.$store.dispatch("invoice/getLatestInvoice").catch(error => {
            console.log(error)
            this.$store.dispatch("invoice/newInvoice", data);
          })
        this.$store.dispatch("invoice/disableEditing", false);
        this.$store.dispatch("invoice/spin", false);
      });
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    }); 
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "invoice/SAVE_FORM" &&
        this.$store.state.invoice.status === "OPEN"
      ) {
        this.debounceSave();
      }
    });
  },
  beforeMount: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* allow row-viewer to be clicked */
.invoice-viewer-container {
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .addresses {
    height: 100%;
  }
  .invoice-viewer-container {
  }
}
@media screen and (max-width: 620px) {
  .invoice-viewer-container {
    /*       zoom: 0.71;
      transform: scale(0.71);   */
  }
  .row {
    width: 100%;
  }
  .col-lg {
  }
  #main-section {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1800px;
    /* height: 1600px; */
  }
  .addresses {
    /* height: 900px; */
  }
}

.col-lg {
}
.row {
  margin: 0;
  padding: 0;
}
</style>
