<template>
    <label class="ckmark-container">
      {{ label }}
      <input
        :disabled="isDisabled"
        type="checkbox"
        v-model="checked"
      >
      <span class="checkmark"></span>
    </label>
</template>

<script>
export default {
    props: ['propValue', 'label', 'isDisabled'],
    computed: {
        checked: {
            get: function() {
                return this.propValue
            },
            set: function(value) {
                this.$emit('check', value)
            }
        }
    }
};
</script>

<style>
.ckmark-container {
  display: block;
  position: relative;
  padding-left: 26px;
  padding-right: 15px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ckmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
      box-shadow: 1px 1px 1px 1px black;
}

/* On mouse-over, add a grey background color */
.ckmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.ckmark-container input:disabled ~ .checkmark{
  background-color: #ccc;

}
/* When the checkbox is checked, add a blue background */
.ckmark-container input:checked ~ .checkmark {
  border-top: 1px solid #96cad4;
  border-left: 1px solid #96cad4;
  background: hsla(192, 61%, 31%, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ckmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ckmark-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 376px) {
  .checkmark {
      background-color: #eee;
      box-shadow: none;
}
.ckmark-container input:checked ~ .checkmark {
  border: none;
}
}
</style>
