<template>
  <form id="customer-address" class="form-container">
    <h4 v-on:click="show" class="opener-link">
      {{ $t("invoiceForm.customerAddress") }}
      <i v-if="display" class="fa fa-minus"/>
      <i v-else class="fa fa-plus"/>
    </h4>
    <div v-bind:class="{'':display, 'hide':!display}">
      <div class="row">
        <input-field
          list="resultNames"
          form-group="customerAddress"
          data-field="name"
          :label="$t('invoiceForm.name')"
          :validation="simpleValidation"
        />
        <div v-if="entryPoint === 'laskumappi' && !laskumappiSkin" class="trafficlight col-lg-2">
          <div
            class="circle"
            :title="$t('trafficLight.'+trafficlight)"
            :class="{'circle_green':trafficlight==='GREEN',
                'circle_gray':trafficlight==='GRAY',
                'circle_yellow':trafficlight==='YELLOW'}"
          ></div>
        </div>
      </div>
      <div class="row">
        <input-field
          list="resultBids"
          form-group="customerAddress"
          data-field="businessId"
          :blur="handleBlur"
          :focus="handleFocus"
          :label="$t('invoiceForm.businessId')"
          :validation="businessIdValidator"
        />
        <datalist id="resultBids">
          <option v-for="(item, index) in customers" :key="index" :value="item.bid"/>
        </datalist>
        <select-field
          v-if="isSafari"
          form-group="customerAddress"
          data-field="country"
          :options="countries"
          :validation="simpleValidation"
        />
        <country-input v-else :countries="countries" form-group="customerAddress"/>
      </div>
      <div class="row">
        <input-field
          form-group="customerAddress"
          data-field="address"
          :label="$t('invoiceForm.address')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row">
        <input-field
          form-group="customerAddress"
          data-field="zipCode"
          :label="$t('invoiceForm.zipCode')"
          :validation="simpleValidation"
        />
        <input-field
          form-group="customerAddress"
          data-field="postal"
          :label="$t('invoiceForm.postal')"
          :validation="simpleValidation"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { mapActions } from "vuex";
import {
  simpleValidation,
  validateYtunnus,
  validateOrgNum,
  validateMinEight
} from "../utils";
import { codes } from "@/assets";
import InputField from "./InputField";
import SelectField from "./SelectField";
import CountryInput from "./CountryInput.vue";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  data() {
    return {
      bidFocused: false,
      simpleValidation,
      validateYtunnus,
      validateOrgNum,
      validateMinEight,
      isSafari: false,
      trafficlight: "GRAY"
    };
  },
  mounted: function() {
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );
    this.$store.subscribeAction((action, state) => {
      if (action.type === "invoice/sendInvoice") {
        this.invalidFields.customerAddress.forEach(field => {
          this.validations[field + "IsValid"] = false;
        });
      }
    });
  },
  computed: {
    ...mapFields([
      "invalidFields",
      "countries",
      "userData.TransferKey",
      "userData.TransferID",
      "userData.authToken",
      "userData.entryPoint",
      "userData.laskumappiSkin",
      "customers",
      "customerRecordSelected",
      "invoiceField.customerAddress",
      "invoiceField.customerAddress.address",
      "invoiceField.customerAddress.businessId",
      "invoiceField.customerAddress.country",
      "invoiceField.customerAddress.name",
      "invoiceField.customerAddress.postal",
      "invoiceField.customerAddress.zipCode",
      "invoiceField.editingDisabled",
      "invoiceField.customerAddress.validations",
      "invoiceField.billingAddress"
    ]),
    display: {
      get: function() {
        return this.$store.getters["invoice/getField"](
          "sectionDisplay.customerAddress"
        );
      },
      set: function(val) {
        this.$store.dispatch("invoice/toggleSection", {
          path: "customerAddress",
          value: val
        });
      }
    },
    emptyFields: function() {
      return (
        this.address === "" &&
        this.country === "" &&
        this.zipCode === "" &&
        this.postal === "" &&
        this.businessId === "" &&
        this.name === ""
      );
    },
    businessIdValidator: function() {
      switch (this.businessIdType) {
        case "Ytunnus":
          return this.validateYtunnus;
        case "orgNumber":
          return this.validateOrgNum;
        default:
          return this.validateMinEight;
      }
    },
    countries: function() {
      return codes[this.$store.getters["invoice/getField"]("userData.locale")];
    },
    businessIdType: function() {
      if (this.country.value) {
        switch (this.country.value) {
          case "FI":
            return "Ytunnus";
          case "SE":
            return "orgNumber";
          default:
            return "businessId";
        }
      } else {
        return "businessId";
      }
    }
  },
  watch: {
    customerAddress: {
      handler: function(value) {
        if (!this.emptyFields) {
          let fields = [
            "name",
            "address",
            "zipCode",
            "postal",
            "country",
          ];
        if (this.businessId !== "") {
          fields.push("businessId")
        } 
          let invalidFields = [];
          fields.forEach(field => {
            if (this.validations[field + "IsValid"] !== true) {
              invalidFields.push(field);
            }
          });
          let name = "customerAddress";
          let obj = { name, fields: invalidFields };
          this.addToInvalidFields(obj);
        } else {
          this.addToInvalidFields({ name: "customerAddress", fields: [] });
        }
      },
      deep: true
    },
    emptyFields: function(value) {
      if (value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "country",
        ];
        if (this.businessId !== "") {
          fields.push("businessId")
        } 
        fields.forEach(field => {
          this.$store.dispatch("invoice/validateField", {
            formGroup: "customerAddress",
            dataField: field,
            value: null
          });
        });
      } else {
        this.display = true;
      }
    },
    businessId: {
      handler: function(value) {
        if (value === "") {
          this.trafficlight = "GRAY";
        }
        if (this.businessIdValidator(value)) {
          let bid = this.businessId;
          if (bid.length >= 9 && this.bidFocused) {
            this.$store.dispatch("invoice/searchCustomer", {
              bid: bid,
              token: this.authToken,
              field: "customerAddress"
            });
          }
          this.$store
            .dispatch("invoice/checkTrafficLight", {
              bids: [bid],
              token: this.authToken
            })
            .then(response => {
              if (response && response.BusinessIDs) {
                this.trafficlight = response.BusinessIDs[0].Trafficlight;
              } else {
                this.trafficlight = "GRAY";
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      },
      deep: false
    },
    businessIdValidator: function(value) {
      this.$store.dispatch("invoice/validateField", {
        formGroup: "customerAddress",
        dataField: "businessId",
        value: value(this.businessId)
      });
    },
    validations: {
      handler: function(value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "businessId",
          "country"
        ];
        let foundInvalid = false;
        fields.forEach(name => {
          if (this.validations[name + "IsValid"] === false) {
            foundInvalid = true;
          }
        });
        if (foundInvalid) {
          this.display = true;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("invoice", ["addToInvalidFields"]),
    show: function() {
      this.display = !this.display;
    },
    handleFocus: function(evt) {
      this.bidFocused = true;
    },
    handleBlur: function(val) {
      this.bidFocused = false;
    }
  },
  components: {
    InputField,
    SelectField,
    CountryInput
  }
};
</script>

<style scoped>
.fa {
  float: right;
}
.hide {
  display: none;
}
#customer-address {
  max-width: 450px;
}
.form-container {
  padding: 1em 1em 1em 1em;
  margin-bottom: 0.5em;
}
.trafficlight {
  position: relative;
  width: 2.5em;
}
.circle {
  position: absolute;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  bottom: 7px;
  right: 0px;
  cursor: pointer;
}

.circle_green {
  background-color: green;
}

.circle_yellow {
  background-color: red;
}

.circle_gray {
  background-color: gray;
}

@media screen and (max-width: 769px) {
  #customer-address {
    max-width: 100%;
    width: 100%;
    margin-left: 0em;
  }
  .circle {
    top: -60px;
    right: -80vw;
    width: 1.2em;
    height: 1.2em;
  }
}
@media screen and (max-width: 426px) {
  .row {
    padding: 0;
  }
  #customer-address {
    margin-left: 0em;
  }
}
</style>
