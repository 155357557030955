<template>
    <div class="col-lg form-group">
        <label class="form-label">{{ $t("invoiceForm." + dataField) }}</label>
        <select :disabled="isDisabled" class="form-control form-control-sm" v-model="fieldValue"
                v-bind:class="{'':validationValue === null, 'is-valid':validationValue === true, 'is-invalid':validationValue === false, 'no-validation':validation === null}">
            
            <option v-if="dataField!=='eInvoiceAddress'" :value="{name:'',value:''}" selected>{{ $t("invoiceForm." + dataField) }}</option>
            <option v-else-if="dataField=='eInvoiceAddress' && options.length === 0" :value="{name:'',value:''}" selected>{{ $t("invoiceForm.noeInvoiceAddress") }}</option>
            
            <option v-for="(item, index) in options" :key="index" :value="item">{{ item.name ||  $t("invoiceForm.noeInvoiceAddress") }}</option>
            <option v-if="dataField==='eInvoiceAddress'" :value="{name:'TULOSTUS',value:'TULOSTUS'}">{{ $t("invoiceForm.sendToPrinting") }}</option>            
            <option v-if="dataField==='eInvoiceAddress'" :value="{name:'EMAIL',value:'EMAIL'}">{{ $t("invoiceForm.sendToEmail") }}</option>           
            <option v-if="dataField==='eInvoiceAddress'" :value="{name:'other', value:'other'}">{{ $t("invoiceForm.other") }}</option> 
           
        </select>
    </div>
</template>

<script>
//import { mapFields } from 'vuex-map-fields'
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
    getterType: 'invoice/getField',
    mutationType: 'invoice/updateField',
})

export default {
    name: 'SelectField',
    props: ['formGroup', 'dataField', 'validation', 'type', 'list', 'fix', 'options', 'skin'],
    computed: {
        fieldValue: {
            get() {
                if (this.dataField === 'country') {
                    return this.options.find(country => country.value === this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.' + this.dataField).value)
                }
                return this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.' + this.dataField) //state.invoiceField[this.formGroup][this.dataField]
            },
            set(value) {
                this.$store.dispatch('invoice/updateField', {formGroup:this.formGroup, dataField:this.dataField, value:value})
            }
        },
        validationValue: {
            get() {
                return this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.validations.' + this.dataField + 'IsValid')
            },
        },
        isDisabled: {
            get() {
                return this.$store.getters['invoice/getField']('invoiceField.editingDisabled') || this.dataField === "eInvoiceAddress"
            }
        }  
    },
    watch: { 
        fieldValue: {
            handler: function(value) {
                if (this.validation) {
                    this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:this.dataField, value:this.validation(value.name)})
                }
            }, deep: true
        }
    }
}
</script>

<style scoped>
.no-validation {
    padding: 0;
}

@media screen and (max-width: 376px) {
    select {
        box-shadow: none;
    }
}
</style>
