<template>
  <div id="purchase-invoice-form">
    <div class="row">
      <purchase-invoice-form-field
        v-model="formData.barcode"
        :valid="validations.barcode"
        v-on:validate="validations.barcode = $event"
        :validator="barcodeValidator"
        :type="'number'"
        :label="$t('invoiceForm.barcode')"
      />
    </div>
    <div class="row">
      <div style="padding:0;" class="col">
        <div class="row col-lg">
          <h4>{{ $t("purchaseInvoice.basic") }}</h4>
        </div>
        <div class="row col-lg">
          <purchase-invoice-form-field
            v-model="formData.sentDate"
            :valid="validations.sentDate"
            v-on:validate="validations.sentDate = $event"
            :validator="simpleValidation"
            :label="$t('invoiceForm.sentDate')"
            type="date"
          />
          <purchase-invoice-form-field
            v-model="formData.invoiceNumber"
            :valid="validations.invoiceNumber"
            v-on:validate="validations.invoiceNumber = $event"
            :validator="simpleValidation"
            :type="'number'"
            :label="$t('invoiceForm.invoiceNumber')"
          />
        </div>
        <div class="row col-lg">
          <purchase-invoice-form-field
            v-model="formData.dueDate"
            :valid="validations.dueDate"
            :validator="simpleValidation"
            v-on:validate="validations.dueDate = $event"
            :label="$t('invoiceForm.dueDate')"
            type="date"
          />
        </div>

        <div class="row col-lg">
          <div class="form-group">
            <label class="form-label">{{ $t("invoiceForm.paymentSolution") }}</label>
            <select
              style="width:7em;"
              class="form-control form-control-sm"
              v-model="formData.paymentMethod"
            >
              <option
                v-for="(method, index) in paymentMethods"
                :key="index"
                :value="method"
              >{{method}}</option>
            </select>
          </div>
          <purchase-invoice-form-field
            v-model="formData.bankAccount"
            :valid="validations.bankAccount"
            :validator="bankAccountValidator"
            v-on:validate="validations.bankAccount = $event"
            :label="$t('invoiceForm.bankAccount')"
          />


        </div>

        <div class="col-lg">
          <purchase-invoice-form-field
            v-model="formData.BIC"
            :valid="validations.BIC"
            v-on:validate="validations.BIC = $event"
            :validator="bicValidator"
            :label="$t('invoiceForm.BIC')"
          />
        </div>
        <div class="col-lg">
          <purchase-invoice-form-field
            v-model="formData.bankReference"
            :valid="validations.bankReference"
            v-on:validate="validations.bankReference = $event"
            :validator="simpleValidation"
            :label="$t('invoiceForm.bankReference')"
          />
        </div>
      </div>
      <div class="col-lg">
        <div class="row col-lg">
          <h4>{{ $t("purchaseInvoice.recipient") }}</h4>
        </div>
        <purchase-invoice-form-field
          v-model="formData.senderBusinessId"
          :valid="validations.senderBusinessId"
          v-on:validate="validations.senderBusinessId = $event"
          :validator="validateMinEight"
          :label="$t('invoiceForm.businessId')"
        />
        <purchase-invoice-form-field
          v-model="formData.senderName"
          :valid="validations.senderName"
          v-on:validate="validations.senderName = $event"
          :validator="simpleValidation"
          :label="$t('invoiceForm.name')"
        />
        <div class="row">
          <div class="form-group col-lg">
            <label class="form-label">{{ $t("invoiceForm.VATCountry")}}</label>
            <select class="form-control form-control-sm" v-model="taxCategories">
              <option :value="getFinnishTaxCategories().reverse()">FI</option>
              <option :value="getSwedishTaxCategories().reverse()">SE</option>
            </select>
          </div>
          <div class="col-lg form-group">
            <label class="form-label">{{ $t("invoiceForm.currency" )}}</label>
            <select class="form-control form-control-sm" v-model="formData.currency">
              <option
                v-for="(item, index) in currencies"
                :key="index"
                :value="item"
              >{{ item.value }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg row tax-breakdown">
      <div style="overflow-x:auto;">
        <table>
          <tr>
            <th>{{ $t("purchaseInvoice.taxBase") }}</th>
            <th>{{ $t("purchaseInvoice.withoutVAT") }}</th>
            <th>{{ $t("purchaseInvoice.totalVAT") }}</th>
            <th>{{ $t("purchaseInvoice.total") }}</th>
          </tr>
          <tbody>
            <purchase-invoice-tax-row
              v-for="(row, index) in rowData"
              v-on:updatedRow="updateRow($event)"
              :key="index"
              :VAT="row.VAT"
            ></purchase-invoice-tax-row>
            <!--                 <td>{{row.VAT}} %</td>
                <td><input class="form-control form-control-sm" v-model="row.totalWithoutVAT"/></td>
                <td>{{row.totalVAT}}</td>
            <td>{{row.total}}</td>-->
            <tr>
              <td></td>
              <td
                style="text-align:left;padding-left:8px;font-weight:bold;"
              >{{ totals.totalWithoutVAT | numToLocale }}</td>
              <td style="font-weight:bold">{{ totals.totalVAT | numToLocale }}</td>
              <td style="font-weight:bold">{{ totals.total | numToLocale }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <button
        class="btn-secondary mr-2"
        v-on:click="$emit('cancel')"
      >{{ $t("purchaseInvoice.back") }}</button>
      <button class="btn-success" :disabled="spinner" v-on:click="createInvoice">{{ $t("purchaseInvoice.ready") }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InputField from "../InputField.vue";
import SelectField from "../SelectField.vue";
import CountryInput from "../CountryInput.vue";
import PurchaseInvoiceFormField from "./PurchaseInvoiceFormField.vue";
import PurchaseInvoiceTaxRow from "./PurchaseInvoiceTaxRow.vue";
import {
  simpleValidation,
  dateValidation,
  validateNumberInput,
  validateBIC,
  countDays,
  getBicCode,
  returnAlwaysTrue,
  formatNumber,
  validateReference,
  validateYtunnus,
  validateMinEight,
  validateOrgNum,
  validateDiscount,
  validateBankAccount,
  toNumber,
  addDays,
  spaceOutIBAN,
  calculateReferenceVerification,
  createPurchaseFinvoice,
  escapeXML,
  getFinnishTaxCategories,
  getSwedishTaxCategories
} from "../../utils";
import { codes, operators } from "../../assets";
import { createHelpers } from "vuex-map-fields";
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});
export default {
  props: ["file"],

  mounted: function() {
    this.rowData.splice(0);
    this.taxCategories.forEach(vat => {
      this.rowData.push({
        VAT: vat,
        totalWithoutVAT: 0,
        totalVAT: 0,
        total: 0
      });
    });
    this.formData.sentDate = moment().format("YYYY-MM-DD")
    this.formData.dueDate = moment().add(30, 'days').format("YYYY-MM-DD")
    if (this.userData.entryPoint !== 'laskumappi') {
      this.formData.currency = {value: "SEK", name:"SEK"}
      this.taxCategories = getSwedishTaxCategories().reverse();
      this.formData.paymentMethod = "PLUSGIRO"
    }
  },
  data() {
    return {
      taxCategories: getFinnishTaxCategories().reverse(),
      paymentMethods: ["IBAN", "BANKGIRO", "PLUSGIRO", "OTHER"],
      storageId: null,
      storageKey: null,
      rowData: [],
      formData: {
        barcode: "",
        invoiceNumber: "",
        sentDate: "",
        dueDate: "",
        bankAccount: "",
        BIC: "",
        bankReference: "",
        currency: {value:"EUR", name:"EUR"},
        senderName: "",
        senderBusinessId: "",
        VATCountry: "FIN",
        productRows: [],
        paymentMethod: "IBAN"
      },
/*       formData: {
        barcode: "",
        invoiceNumber: "1234102",
        sentDate: "2018-10-10",
        dueDate: "2018-10-10",
        bankAccount: "FI42 5000 1510 0000 23",
        BIC: "OKOYFIHH",
        bankReference: "12400",
        currency: { value: "EUR", name: "EUR" },
        senderName: "Asdila",
        senderBusinessId: "1231231-1",
        VATCountry: "FIN",
        productRows: [],
        paymentMethod: "IBAN"
      }, */
      validations: {
        barcode: null,
        invoiceNumber: null,
        sentDate: null,
        dueDate: null,
        bankAccount: null,
        BIC: null,
        bankReference: null,
        currency: null,
        senderName: null,
        senderBusinessId: null,
        VATCountry: null,
        productRows: null,
        totalVAT: null,
        totalWithoutVAT: null,
        total: null
      },
      simpleValidation,
      dateValidation,
      validateNumberInput,
      validateBIC,
      returnAlwaysTrue,
      validateYtunnus,
      validateMinEight,
      validateOrgNum,
      formatNumber,
      validateReference,
      codes,
      operators,
      spaceOutIBAN,
      validateDiscount,
      getFinnishTaxCategories,
      getSwedishTaxCategories,
      paymentConditionDays: [
        { name: "1", value: 1 },
        { name: "7", value: 7 },
        { name: "10", value: 10 },
        { name: "14", value: 14 },
        { name: "21", value: 21 },
        { name: "30", value: 30 },
        { name: "45", value: 45 },
        { name: "60", value: 60 },
        { name: "90", value: 90 }
      ]
    };
  },
  methods: {
    numToLocale: function(number) {
      if (typeof number === "string") {
        number = parseFloat(number);
      }
      return number.toLocaleString("fi-FI", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    ...mapActions("invoice", [
      "createPurchaseInvoiceStorage",
      "uploadToScanning",
      "uploadPurchaseInvoice",
      "setStorageMeta",
      "uploadAppendix"
    ]),
    validateForm: function() {
      let required = [
        "invoiceNumber",
        "sentDate",
        "dueDate",
        "bankAccount",
      //  "BIC",
        "bankReference",
        "senderName",
        "senderBusinessId",
        "senderName"
      ];
      let valid = true;
      required.forEach(i => {
        if (!this.validations[i]) {
          valid = false;
          this.validations[i] = false;
        }
      });
      if (this.validations.barcode === false) {
        valid = false;
      }
      return valid;
    },
    barcodeValidator: function(value) {
      if (!value) {
        return null;
      }
      if (!value.startsWith("4")) {
        let errorString =
          "Barcode version is not 4, but: " + value.substring(0, 1);
        return false;
      }
      if (value.length != 1 + 16 + 8 + 3 + 20 + 6) {
        let errorString =
          "Barcode does not have the correct length. Length received: " +
          value.length +
          " expected " +
          (1 + 16 + 8 + 3 + 20 + 6);
        return false;
      }
      return true;
    },
    createInvoice() {
      if (this.validateForm()) {
        this.$store.dispatch('invoice/spin', true)
        this.createStorage()
          .then(response => {
            let storageId = (this.storageId = response.StorageID);
            this.storageKey = response.StorageKey;
            return storageId;
          })
          .then(storageId => {
            return this.setMetadata(storageId);
          })
          .then(response => {
            return this.upload();
          })
          .then(response => {
            let storageId = this.storageId;
            let storageKey = this.storageKey;
            window.parentIFrame.sendMessage(
              JSON.stringify({
                type: "path",
                value: "invoice.view",
                params: { storageId, queryParams: { storageKey } }
              })
            );
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch('invoice/spin', false)
          });
      } else {
        window.alert(this.$t("invoiceForm.invalidInput"));
      }
    },
    createStorage: function() {
      return this.createPurchaseInvoiceStorage({
        tid: this.userData.TransferID,
        tkey: this.userData.TransferKey
      });
    },
    updateRow: function(value) {
      let index = this.rowData.findIndex(row => row.VAT === value.VAT);
      this.$set(this.rowData, index, value);
    },

    setMetadata: function(storageId) {
      let metadata =
        `<Group>
        <Value type="DocumentID">` +
        escapeXML(this.formData.invoiceNumber) +
        `</Value>
        <Value type="DueDate">` +
        escapeXML(this.formData.dueDate.replace(/-/g, "")) +
        `</Value>
        <Value type="SenderVAT">` +
        escapeXML(this.formData.senderBusinessId) +
        `</Value>
        <Value type="TaxAmount">` +
        escapeXML(this.numToLocale(this.totals.totalVAT).replace(",", ".").replace(/\s/g,'')) +
        `</Value>
        <Value type="DocumentDate">` +
        escapeXML(this.formData.sentDate.replace(/-/g, "")) +
        `</Value>
        <Value type="SenderName">` +
        escapeXML(this.formData.senderName) +
        `</Value>
        <Value type="PaymentReference">` +
        escapeXML(this.formData.bankReference) +
        `</Value>
        <Value type="PaymentStatus">NOTPAID</Value>
        <Value type="InvoiceStatus">NEW</Value>
        <Value type="TransferStatus">NEW</Value>
        <Value type="ReceiverVAT">` +
        escapeXML(this.userData.Ytunnus) +
        `</Value>
        <Value type="ImageName">invoice.pdf</Value>
        <Value type="DocumentName">invoice.xml</Value>
        <Value type="AmountToPay">` +
        escapeXML(this.numToLocale(this.totals.total).replace(",", ".").replace(/\s/g,'')) +
        `</Value>
        <Value type="CreatedDate">` +
        escapeXML(moment().format("YYYYMMDDHHmmss")) +
        `</Value>
        <Value type="BankAccount">` +
        escapeXML(this.formData.bankAccount.replace(/ /g, "")) +
        `</Value>
        <Value type="Currency">` +
        escapeXML(this.formData.currency.value) +
        `</Value>
        <Value type="ReceiverName">` +
        escapeXML(this.userData.Name) +
        `</Value>
        <Value type="InvoiceChannel">Manual</Value>
        <Value type="ApprovalStatus">NEUTRAL</Value>
      </Group>`;

      return this.setStorageMeta({
        transferId: this.userData.TransferID,
        storageId,
        transferKey: this.userData.TransferKey,
        metadata
      });
    },
    upload: function() {
      let country = this.validateOrgNum(this.userData.Ytunnus) ? "SE" : "FI";

      let invoice = {
        ...this.formData,
        businessId: this.userData.Ytunnus,
        name: this.userData.Name,

        senderBusinessId: this.formData.senderBusinessId,
        senderName: this.formData.senderName,
        country,
        taxations: this.rowData,
        categories: this.taxCategories,
        totals: this.totals
      };
      let xml = createPurchaseFinvoice(invoice, this.userData.locale);
      
      return this.uploadAppendix({
        tid: this.userData.TransferID,
        tkey: this.userData.TransferKey,
        storageId: this.storageId,
        mediaType: "application/pdf",
        fileName: "invoice.pdf",
        file: this.file,
        role: "image"
      })
        .then(response => {
          return this.uploadAppendix({
            tid: this.userData.TransferID,
            tkey: this.userData.TransferKey,
            storageId: this.storageId,
            mediaType: "text/xml",
            fileName: "invoice.xml",
            file: xml,
            role: "invoice"
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  components: {
    InputField,
    SelectField,
    CountryInput,
    PurchaseInvoiceFormField,
    PurchaseInvoiceTaxRow
  },
  computed: {
    ...mapFields([
      "userData",
      "eInvoiceAddresses",
      "rowsTotal",
      "currencies",
      "taxations",
      "bankAccounts",
      "invoiceField.billingInfo.invoiceLanguage",
      "spinner"
    ]),
    bicValidator: function() {
      if (this.formData.paymentMethod === "OTHER") {
        return function (val) { return null }
      } else {
        return this.validateBIC
      }
    },
    today: function() {
      return new Date().toISOString().split("T")[0];
    },
    totals: function() {
      return this.rowData.reduce(
        (acc, curr) => {
          acc.totalVAT += curr.totalVAT;
          acc.totalWithoutVAT += curr.totalWithoutVAT;
          acc.total += curr.total;
          return acc;
        },
        { totalVAT: 0, totalWithoutVAT: 0, total: 0 }
      );
    },
    bankAccountValidator: function() {
      return value => {
        return validateBankAccount(value, this.formData.paymentMethod);
      };
    }
  },
  filters: {
    numToLocale: function(number) {
      if (typeof number === "string") {
        number = parseFloat(number);
      }
      return number.toLocaleString("fi-FI", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  watch: {
    taxCategories: function(value) {
      this.rowData.splice(0);
      value.forEach(vat => {
        this.rowData.push({
          VAT: vat,
          totalWithoutVAT: 0,
          totalVAT: 0,
          total: 0
        });
      });
    },
        bicValidator: function(val) {
        this.validations.bic =  val(this.formData.BIC)
    },
    "formData.barcode": function(value) {
      if (this.barcodeValidator(value)) {
        let bankAccount = "FI" + value.substring(1, 17);
        this.formData.bankAccount = bankAccount;
        let paymentReference = value.substring(28, 48).replace(/^0+/, "");
        this.formData.bankReference = paymentReference;
        let dueDate = "20" + value.substring(48, 50);
        dueDate += "-" + value.substring(50, 52);
        dueDate += "-" + value.substring(52, 54);
        this.formData.dueDate = dueDate;
      }
    },
    "formData.bankAccount": function(value) {
        let valid = validateBankAccount(value, this.formData.paymentMethod);

        if (valid) {
          if (this.formData.paymentMethod === "IBAN") {
            this.formData.BIC = getBicCode(value);
          } else if (this.formData.paymentMethod === "BANKGIRO") {
            this.formData.BIC = "BGABSESS";
          } else if (this.formData.paymentMethod !== "OTHER"){
            this.formData.BIC = "PGSISESS";
          }
        }


    },
    "formData.paymentMethod": function(value) {
      this.validations.bankAccount = this.bankAccountValidator(
        this.formData.bankAccount
      );
    }
  }
};
</script>

<style scoped>
#purchase-invoice-form {
  background: white;
  border-radius: 15px;
  padding: 0.5em 1em 0.5em 1em;
  margin-left: 1em;
}
table {
  width: 600px;
}
@media screen and (max-width: 768px) {
  .tax-breakdown {
  }
  .tax-breakdown > table {
  }
  #purchase-invoice-form {
    background: white;
    border-radius: 15px;
    padding: 0.5em;
    margin-left: 0em;
  }
  h4 {
    margin-top: 0.4em;
  }
}
@media screen and (max-width: 425px) {
  select {
    width: 100%;
    margin-bottom: 1em;
    box-shadow: none;
  }

  table {
    width: 100%;
  }
}
</style>
