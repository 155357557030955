<template>
    <tr>
        <td>{{VAT | numToLocale}} %</td>
        <td style="position:relative;">
            <input type="number" class="form-control form-control-sm" v-model="input"
        v-bind:class="{'':valid===null, 'is-valid':valid === true, 'is-invalid':valid === false}"/>   
        </td>
        <td>{{totalVAT | numToLocale}}</td>
        <td>{{total | numToLocale}}</td>
    </tr>
</template>

<script>
export default {
    props: ['VAT'],
    data() {
      return {
        input: 0,
        valid: null,

      }
    },
    computed: {
        totalVAT: function() {
            return parseFloat(this.totalWithoutVAT * (this.VAT / 100))
        },
        total: function() {
            return parseFloat(Number(this.totalWithoutVAT) + Number(this.totalVAT))
        },
        totalWithoutVAT: function() {
            let input = this.input
            if (!input) {
                return 0
            }
            if (typeof input === "string") {
                input = input.includes(",") ? input.replace(",", ".") : input
            }
            if (isNaN(Number(input))) {
                return 0
            } else {
                return parseFloat(Number(input))
            }
        }
    },
    watch: {
        totalWithoutVAT: function(value) {
            this.$emit("updatedRow", {VAT: this.VAT, totalWithoutVAT: this.totalWithoutVAT,
            totalVAT: this.totalVAT, total: this.total})
        },
        input: function(value) {
            if (!value) {
                this.valid = null
            }
            if (typeof value === "string") {
                value = value.includes(",") ? value.replace(",", ".") : value
            }
            if (isNaN(Number(value))) {
                this.valid = false
            } else {
                this.valid = true
            }
            
        }
    },
    filters: {
        numToLocale: function(number) {
            if (typeof number === 'string') {
                number = parseFloat(number)
            }
            return number.toLocaleString("fi-FI", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        }
    }
}
</script>

<style scoped>

</style>
