<template>
  <div id="purchase-invoice-container">
    <div v-if="!inputManually" class="col">
      <h4 style="color:white">{{ $t("purchaseInvoice.uploadPDF")}}</h4>
      <div class="row">
        <div>
          <label
            class="file-select d-flex align-start"
            :class="{'cursor':!file}"
            @drop.prevent="drop($event)"
            @dragover.prevent
          >
            <div class="select-button">
              <span v-if="!file">{{ $t("invoiceForm.select") }} PDF</span>
              <div class="file-name" v-if="this.file">
                {{this.file.name}}
                <i @click="file=null" class="fa fa-times close round"></i>
              </div>
            </div>
            <input :disabled="file" accept="application/pdf" :ref="'files'" type="file" @input="handleFileChange" />
          </label>
        </div>
      </div>
      <div v-if="this.file" class="form-group buttons col row">
        <button v-if="userData.entryPoint==='laskumappi'"
        :disabled="spinner"
          class="btn-secondary mr-2"
          v-on:click="sendToScanning"
        >{{ $t("purchaseInvoice.sendToScanning") }}</button>
        <button
          class="btn-secondary"
          v-on:click="inputManually=true"
        >{{ $t("purchaseInvoice.inputManually") }}</button>
      </div>
    </div>
    <div class="row" v-if="inputManually">
      <h2
        style="color:white;margin:0 1em 0.5em 1em;"
      >{{ $t("purchaseInvoice.image") }}: {{this.file.name}}</h2>
    </div>
    <div v-if="this.file" class="input-form" v-bind:class="{'hide':!inputManually}">
      <purchase-invoice-form :file="file" v-on:cancel="inputManually=false"></purchase-invoice-form>
    </div>
    <spinner></spinner>
  </div>
</template>
<script>
import PurchaseInvoiceForm from "./PurchaseInvoiceForm";
import Spinner from "../Spinner";
import { createHelpers } from "vuex-map-fields";
import JSZip from "jszip";
import moment from "moment";
import { escapeXML } from "../../utils"
import { mapActions } from "vuex";
const uuidv4 = require("uuid/v4");

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});
export default {
  data() {
    return {
      file: null,
      inputManually: false,
      storageId: "",
      storageKey: ""
    };
  },
  components: {
    PurchaseInvoiceForm,
    Spinner
  },

  methods: {
    drop: function(e) {
      if (
        e.dataTransfer.files[0] &&
        e.dataTransfer.files[0].type === "application/pdf"
      ) {
        this.file = e.dataTransfer.files[0];
      }
    },

    ...mapActions("invoice", [
      "createReceivingStorage",
      "uploadToScanning",
      "setStorageMeta",
      "uploadAppendix"
    ]),
    handleFileChange(e) {
      if (e) {
        this.file = e.target.files[0];
        this.inputManually = false;
      }
    },
    sendToScanning() {
      this.$store.dispatch("invoice/spin", true);
      let timeStamp = moment().format("YYYYMMDDHHmmss");
      let xml =
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
        "<Request><Content><Group>" +
        '<Value type="FileName">invoice.pdf</Value>' +
        '<Value type="FileSize">' +
        escapeXML(this.file.size) +
        "</Value>" +
        '<Value type="TransferID">' +
        escapeXML(this.userData.TransferID) +
        "</Value>" +
        '<Value type="UniqueCompanyID">' +
        escapeXML(this.userData.UniqueCompanyID) +
        "</Value>" +
        '<Value type="TimeStamp">' +
        escapeXML(timeStamp) +
        "</Value>" +
        "</Group></Content></Request>";

      let zip = new JSZip();
      zip.file("meta.xml", xml);
      zip.file("invoice.pdf", this.file);
      let zipFile = zip
        .generateAsync({ type: "arraybuffer", compression: "DEFLATE" })
        .then(zip => {
          let tkey = this.userData.TransferKey;
          let tid = this.userData.TransferID;
          this.$store
            .dispatch("invoice/uploadToScanning", { tid, tkey, zip })
            .then(response => {
              if (response.body.Response.Status === "OK") {
                window.alert(this.$t("invoiceForm.sendingSuccess"));
                this.file = null;
              } else {
                window.alert(this.$t("invoiceForm.sendingFail"));
              }
              this.$store.dispatch("invoice/spin", false);
            });
        })
        .catch(err => {
          window.alert(this.$t("invoiceForm.sendingFail"));
          this.$store.dispatch("invoice/spin", false);
        });
    }
  },
  computed: {
    ...mapFields(["userData", "spinner"])
  }
};
</script>

<style scoped>
h2 {
  margin: 0;
}
.hide {
  visibility: hidden;
}
.round {
  /*   background: white;
  border-radius: 50%;
  height: 25px;
  width: 25px; */
  vertical-align: bottom;
  margin-left: 10px;
  margin-top: 3px;
  opacity: 1;
}
/* .file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2EA169;

  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
}

.select-button {
  margin-bottom: 10px;
} */
.file-select {
  position: relative;
}
.file-name {
  font-size: 20px;
  color: white;
}
.file-select > .select-button {
  padding: 4rem;

  color: white;
  border: white dashed 1px;
  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
}

.select-button {
  margin-bottom: 10px;
}
.cursor {
  cursor: pointer;
}
/* .file-select > input[type="file"] {
  display: none;
}
 */

/* .input-form {
  transition: all 1s linear;
	-webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
} */
#purchase-invoice-container {
  max-width: 700px;
  min-height: 800px;
}
/* .file-select:hover > .select-button {
    background-color: rgb(155, 155, 134);

} */

.file-select > input[type="file"] {
  display: none;
}
</style>