/*eslint-disable*/
import Vue from 'vue'
import moment from 'moment'
var SHA256 = require('crypto-js/sha256')
var XMLparser = require('xml2json-light')

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL //  "http://localhost:11005" // 
const EXTRA_URL = process.env.VUE_APP_EXTRA_URL
const GOPI_URL = process.env.VUE_APP_GOPI_URL
const APIX_URL = process.env.VUE_APP_APIX_URL
const ADMIN_URL = process.env.VUE_APP_APIX_ADMIN
const AUTH_SERVER = process.env.VUE_APP_AUTH_SERVER
const TERMINAL = process.env.VUE_APP_TERMINAL
const L_API = process.env.VUE_APP_LASKUMAPPI_API

export const tokenHandler = {
    install(Vue, { store, startToken }) {
        Vue.http.interceptors.push((request, next) => {
            const userData = store.state.invoice.userData
            const token = store.state.invoice.userData.authToken

            const hasAuthHeader = request.headers.has('Authorization')
            if (hasAuthHeader) {
                if (!token) {
                    request.headers.set('Authorization', 'Bearer ' + startToken)
                } else {
                    request.headers.set('Authorization', 'Bearer ' + token)
                }
            }

            if (!request.url.includes("seteventlog")) {
                setEventLog(request, userData)
            }

            next((response) => {
                if (response.body && (response.body.error === 'Authentication failed' || response.body.error === 'Token not valid')) {
                    fetchAuthToken(userData.TransferID, userData.TransferKey).then(response => {
                        store.commit('invoice/SET_AUTH_TOKEN', response.token)
                        request.headers.set('Authorization', 'Bearer ' + response.token)
                        return Vue.http(request).then((response) => {
                            return response
                        }).catch((response) => {
                            return response
                        })
                    })
                }
            })
        })
    }
}
function setEventLog(request, userData) {
    let method = request.method
    let url = request.url
    let tid = userData.TransferID
    let tkey = userData.TransferKey
    let user = userData.Email
    let t = moment().utc().format('YYYYMMDDHHmmss');
    let d = digestStringWithValue([btoa(url), user, method, tid, t, tkey]);

    let params = {
        TraID: tid,
        url: btoa(url),
        method,
        user,
        d,
        t
    }
    Vue.http.get(L_API + "/seteventlog", { params: params, }).then(response => {
    }).catch(error => {
        console.log(error)
    })
}

function authParams(tid, tkey) {
    let t = moment().utc().format('YYYYMMDDHHmmss');
    let digest = 'SHA-256:' + SHA256([tid, t, tkey].join('+'));


    return {
        TraID: tid,
        Timestamp: t,
        Digest: digest,
    }
}
function digestStringWithValue(args, value) {
    //Ember.Logger.log(args.join('+'));
    if (value) {
        args.push(SHA256(value));
    }
    //Ember.Logger.log(args.join('+'));
    let digest = 'SHA-256:' + SHA256(args.join('+'));
    //Ember.Logger.log(digest);
    return digest;
}

function parseAddressQueryObj(data) {
    let eInvoiceAddresses = []
    if (Array.isArray(data)) {
        eInvoiceAddresses = data.map(obj => {
            for (let i = 0; i < obj.Value.length; i++) {
                if (obj.Value[i]['@type'] === 'ReceivereInvoiceAddress') {
                    return obj.Value[i].$
                }
            }
        })
        let uniques = eInvoiceAddresses.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        })
        eInvoiceAddresses = uniques.map(a => ({ name: a, value: a }))

    } else {
        for (let i = 0; i < data.Value.length; i++) {
            if (data.Value[i]['@type'] === 'ReceivereInvoiceAddress') {
                eInvoiceAddresses.push({ name: data.Value[i].$, value: data.Value[i].$ })
            }
        }
    }
    if (eInvoiceAddresses.length > 0) {
        return eInvoiceAddresses
    } else {
        throw "No einvoiceaddresses"
    }
}

function transformToObject(data) {
    return XMLparser.xml2json(data)
}


export function fetchAuthToken(tid, tkey) {
    return new Promise((resolve, reject) => {
        let params = authParams(tid, tkey)
        Vue.http.get(AUTH_SERVER, { params: params })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function queryYTJ(bid, token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(GOPI_URL + "/ytj/" + bid, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function fetchCustomerData(token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/customer/get", { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function fetchExtraData(request) {
    return new Promise((resolve, reject) => {
        Vue.http.get(EXTRA_URL + "/extrainfo?cid=" + request.userData.UniqueCompanyID)
        .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function fetchSelectedCustomerData(token, number) {
    return new Promise((resolve, reject) => {
        return Vue.http.get(BACKEND_URL + "/anyparty/record/" + number, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json()).then(data => {
                resolve(data.data)
            }).catch(error => {
                reject(error)
            })
    })
}
export function fetchCustomerLogo(token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/logo", { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json()).then(data => {
                resolve(data.Logo)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export function fetchCustomerNames(token) {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/customer/get", { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function queryAddress(bid, tid, tkey) {
    return new Promise((resolve, reject) => {
        let params = authParams(tid, tkey)
        let header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n";
        let xml = header + '<Request>' +
            '<Content>' +
            '<Group>' +
            '<Value type="ReceiverYtunnus">' + bid + '</Value>' +
            '</Group>' +
            '</Content>' +
            '</Request>'
        Vue.http.put(APIX_URL + "/addressquery", xml,
            {
                headers: { 'Content-Type': 'text/xml', },
                params: { uid: params.TraID, t: params.Timestamp, d: params.Digest },
                responseType: 'text/xml'
            })
            .then((response) => {
                if (!response.ok) {
                    reject(null)
                } else {
                    resolve(parseAddressQueryObj(response.body.Response.Content.Group))
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deliveryMethod(senderBid, receiverBid, senderName, receiverName, tid, tkey, einvoiceAddress) {
    return new Promise((resolve, reject) => {
        let params = authParams(tid, tkey)
        let header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n";
        let xml = header + '<Request>' +
            '<Content>' +
            '<Group>' +
            '<Value type="ReceiverYtunnus">' + receiverBid + '</Value>' +
            '<Value type="SenderYtunnus">' + senderBid + '</Value>' +
            '<Value type="ReceiverName">' + receiverName + '</Value>' +
            '<Value type="SenderName">' + senderName + '</Value>'
            xml += 
            einvoiceAddress ? '<Value type="ReceivereInvoiceAddress">' + einvoiceAddress + '</Value>'
            : '<Value type="ReceivereInvoiceAddress">' + '0007:' + receiverBid.replace('-', '') + '</Value>'
            xml += '</Group>' +
            '</Content>' +
            '</Request>'
        Vue.http.put(APIX_URL + "/method", xml,
            {
                headers: { 'Content-Type': 'text/xml', },
                params: { uid: params.TraID, t: params.Timestamp, d: params.Digest },
                responseType: 'text/xml'
            })
            .then((response) => {
                if (!response.ok) {
                    reject(null)
                } else {
                    resolve(response.body.Response.Content.Group.Value)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function requestFlFactoringData({TraID, tkey, cid, contractId, language}) {
    return new Promise((resolve, reject) => {

        let t = moment().utc().format('YYYYMMDDHHmmss')
        let d = digestStringWithValue([cid, contractId, language, TraID, t, tkey]);
        Vue.http.get(ADMIN_URL + '/getfactoringconfig', { params: { TraID, cid, contractId, language, t, d } })
        .then(response => {
            response = transformToObject(response.bodyText).Response
            if (response.Status === "OK") {
                resolve(response.Content.Group.Value)
            } else {
                reject(response)
            } 
        })
        .catch(error => {
            reject(error)
        })
    })
}

export function sendInvoiceZip(tid, tkey, zipFile, soft, ver) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss')
        if (!soft) {
            soft = 'Laskumappi';
        }
        if (!ver) {
            ver = '1.0';
        }
        let d = digestStringWithValue([soft, ver, tid, t, tkey]);
        Vue.http.put(APIX_URL + '/invoices', zipFile,
            {
                headers: { 'Content-Type': 'application/octet-stream' },
                params: { soft: soft, ver: ver, TraID: tid, t: t, d: d }
            })
            .then((response) => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function isAddressDeleted(tid, tkey, address, operator) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss')
        let d = digestStringWithValue([tid, address, operator, t, tkey])
        Vue.http.get(APIX_URL + '/isaddressremoved', { params: { uid: tid, address, operator, t, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK" && response.StatusCode !== "7D01") {
                    resolve(false)
                } else if (response.Status === "ERR" && response.StatusCode === "7D01") {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function createStorage(tid, tkey, name) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = digestStringWithValue([name, tid, t, tkey])
        Vue.http.get(TERMINAL + '/create',
            { params: { TraID: tid, t, name, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK") {
                    resolve(response.Content.Group)
                } else if (response.Status === "ERR") {
                    reject(response.FreeText[1]["_@ttribute"])
                }
            }).catch(error => {
                reject(error)
            })
    })
}

export function createReceivingStorage(tid, tkey, name) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = digestStringWithValue([name, tid, t, tkey])
        Vue.http.get(TERMINAL + '/createreceiving',
            { params: { TraID: tid, t, name, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK") {
                    resolve(response.Content.Group)
                } else if (response.Status === "ERR") {
                    reject(response.FreeText[1]["_@ttribute"])
                }
            }).catch(error => {
                reject(error)
            })
    })
}

export function storageListFiles(storageId, storageKey) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = digestStringWithValue([storageId, t, storageKey])
        Vue.http.get(TERMINAL + '/listattachment',
            { params: { SID: storageId, t, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK") {
                    if (response.Content.Group) {
                        Array.isArray(response.Content.Group) ?
                            resolve(response.Content.Group) :
                            resolve([response.Content.Group])
                    } else {
                        resolve([])
                    }

                } else if (response.Status === "ERR") {
                    reject(response.FreeText[1]["_@ttribute"])
                }
            }).catch(error => {
                reject(error)
            })

    })
}

export function storageDeleteFile(tid, tkey, storageId, storageKey, fileName) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = digestStringWithValue([storageId, fileName, tid, t, tkey])
        Vue.http.delete(TERMINAL + '/attachment',
            { params: { TraID: tid, SID: storageId, name: fileName, t, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK") {
                    resolve(response)
                } else if (response.Status === "ERR") {
                    reject(response)
                }
            }).catch(error => {
                reject(error)
            })
    })
}

export function storageUploadFile(tid, tkey, storageId, mediaType, fileName, file, role) {
    return new Promise((resolve, reject) => {
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = '';
        if (role) {
            d = digestStringWithValue([storageId, fileName, mediaType, role, tid, t, tkey])
        } else {
            d = digestStringWithValue([storageId, fileName, mediaType, tid, t, tkey])
        }

        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    let response = transformToObject(xhr.response).Response
                    if (response.Status === "OK") {
                        resolve(response)
                    } else if (response.Status === "ERR") {
                        reject(response)
                    }
                } else {
                    reject(transformToObject(xhr.response))
                }
            }
        }
        role = role ? '&role=' + role : ''
        xhr.open("PUT", TERMINAL + '/attachment?TraID=' + tid + '&t=' + t + '&SID=' + storageId + '&mime=' + mediaType + role + '&name=' + fileName + '&d=' + d);
        xhr.overrideMimeType(mediaType);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        file = new Blob([file], { type: mediaType })
        xhr.send(file)
    })
}

export function storageSetMetadata(transferId, storageId, transferKey, metadata) {
    return new Promise((resolve, reject) => {
        let SID = storageId;
        let TraID = transferId;
        let t = moment().utc().format('YYYYMMDDHHmmss');
        let d = digestStringWithValue([SID, TraID, t, transferKey]);
        metadata = "<Request version=\"1.0\">\r\n<Content>" + metadata + "</Content></Request>\r\n"
        /*         let url = ENV.terminalApiRoot + "/metadata?" + Ember.$.param({
                    TraID, SID, t, d
                }); */
        Vue.http.put(TERMINAL + '/metadata', metadata,
            { headers: { 'Content-Type': 'text/xml' }, params: { TraID: TraID, SID: storageId, t, d } })
            .then(response => {
                response = transformToObject(response.bodyText).Response
                if (response.Status === "OK") {
                    resolve(response)
                } else if (response.Status === "ERR") {
                    reject(response)
                }
            }).catch(error => {
                reject(error)
            })
    })
}

export function queryTrafficLight(bid, token) {
    return new Promise((resolve, reject) => {
        Vue.http.put(GOPI_URL + "/trafficlights/check", bid, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function updateInvoice(invoice, token) {
    return new Promise((resolve, reject) => {
        Vue.http.put(BACKEND_URL + "/invoice", invoice, { headers: { 'Authorization': 'Bearer ' + token } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function fetchInvoiceList() {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/invoice", { headers: { 'Authorization': 'Bearer ' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deleteInvoice(uuid) {
    return new Promise((resolve, reject) => {
        Vue.http.delete(BACKEND_URL + "/invoice/" + uuid, { headers: { 'Authorization': 'Bearer ' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getInvoiceWithUUID(uuid) {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/invoice/" + uuid, { headers: { 'Authorization': 'Bearer ' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getLatestInvoice() {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/invoice/latest/", { headers: { 'Authorization': 'Bearer ' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function suggestNextInvoiceNum() {
    return new Promise((resolve, reject) => {
        Vue.http.get(BACKEND_URL + "/invoice/number/", { headers: { 'Authorization': 'Bearer ' } })
            .then((response) => response.json()).then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}