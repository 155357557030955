<template>
  <form id="delivery-address" class="form-container">
    <h4 v-on:click="show" class="opener-link">
      {{ $t("invoiceForm.deliveryAddress") }}
      <i v-if="display" class="fa fa-minus"/>
      <i v-else class="fa fa-plus"/>
    </h4>
    <div v-bind:class="{'':display, 'hide':!display}">
      <div>
        <div class="row col-lg">
          <input-field
            list="resultList"
            form-group="deliveryAddress"
            data-field="name"
            :label="$t('invoiceForm.name')"
            :validation="simpleValidation"
          />
        </div>
        <div class="row col-lg">
          <select-field
            v-if="isSafari"
            form-group="deliveryAddress"
            data-field="country"
            :options="countries"
            :validation="simpleValidation"
          />
          <country-input v-else :countries="countries" form-group="deliveryAddress"/>
        </div>
        <div class="row col-lg">
          <input-field
            form-group="deliveryAddress"
            data-field="address"
            :label="$t('invoiceForm.address')"
            :validation="simpleValidation"
          />
        </div>
        <div class="row col-lg">
          <input-field
            form-group="deliveryAddress"
            data-field="zipCode"
            :label="$t('invoiceForm.zipCode')"
            :validation="simpleValidation"
          />
          <input-field
            form-group="deliveryAddress"
            data-field="postal"
            :label="$t('invoiceForm.postal')"
            :validation="simpleValidation"
          />
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapActions } from "vuex";
import { firstLetterUpper, simpleValidation, validateYtunnus } from "../utils";
import { codes } from "../assets/countrycodes";
import InputField from "./InputField.vue";
import SelectField from "./SelectField.vue";
import CountryInput from "./CountryInput.vue";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  name: "DeliveryAddress",
  data() {
    return {
      simpleValidation,
      validateYtunnus,
      isSafari: false
    };
  },
  mounted: function() {
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );
    this.$store.subscribeAction((action, state) => {
      if (action.type === "invoice/sendInvoice") {
        if (!this.emptyFields) {
          this.invalidFields.deliveryAddress.forEach(field => {
            this.validations[field + "IsValid"] = false;
          });
        }
      }
    });
  },
  computed: {
    ...mapFields([
      "invalidFields",
      "invoiceField.deliveryAddress",
      "invoiceField.deliveryAddress.name",
      "invoiceField.deliveryAddress.country",
      "invoiceField.deliveryAddress.zipCode",
      "invoiceField.deliveryAddress.postal",
      "invoiceField.deliveryAddress.address",
      "invoiceField.deliveryAddress.validations"
    ]),
    display: {
      get: function() {
        return this.$store.getters["invoice/getField"](
          "sectionDisplay.deliveryAddress"
        );
      },
      set: function(val) {
        this.$store.dispatch("invoice/toggleSection", {
          path: "deliveryAddress",
          value: val
        });
      }
    },
    countries: function() {
      return codes[this.$store.getters["invoice/getField"]("userData.locale")];
    },
    emptyFields: function() {
      return (
        this.address === "" &&
        this.country === "" &&
        this.zipCode === "" &&
        this.postal === "" &&
        this.name === ""
      );
    }
  },
  watch: {
    validations: {
      handler: function(value) {
        let fields = ["name", "address", "zipCode", "postal", "country"];
        let foundInvalid = false;
        fields.forEach(name => {
          if (this.validations[name + "IsValid"] === false) {
            foundInvalid = true;
          }
        });
        if (foundInvalid) {
          this.display = true;
        }
      },
      deep: true
    },
    deliveryAddress: {
      handler: function(value) {
        if (!this.emptyFields) {
          let fields = ["name", "address", "zipCode", "postal", "country"];
          let invalidFields = [];
          fields.forEach(field => {
            if (this.validations[field + "IsValid"] !== true) {
              invalidFields.push(field);
            }
          });
          let name = "deliveryAddress";
          let obj = { name, fields: invalidFields };
          this.addToInvalidFields(obj);
        } else {
          this.addToInvalidFields({ name: "deliveryAddress", fields: [] });
        }
      },
      deep: true
    },
    emptyFields: function(value) {
      if (value) {
        let fields = ["name", "address", "zipCode", "postal", "country"];
        fields.forEach(field => {
          this.$store.dispatch("invoice/validateField", {
            formGroup: "deliveryAddress",
            dataField: field,
            value: null
          });
        });
      } else {
        this.display = true;
      }
    }
  },
  methods: {
    show: function() {
      this.display = !this.display;
    },
    ...mapActions("invoice", ["addToInvalidFields"])
  },
  components: {
    InputField,
    SelectField,
    CountryInput
  }
};
</script>
<style scoped>
.hide {
  display: none;
}
.fa {
  float: right;
}
#delivery-address {
  max-width: 450px;
}
@media screen and (max-width: 1024px) {
  h4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 769px) {
  #delivery-address {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0.5em;
    margin-left: 0em;
  }
}
@media screen and (max-width: 426px) {
  .row {
    padding: 0;
  }
  select {
    margin-bottom: 1em;
  }
  #delivery-address {
    margin-left: 0em;
  }
}
</style>
