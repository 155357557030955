<template>
    <div class="css-spinner">
  <div v-if="spinner" class="dots"></div>
</div>

</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'invoice/getField',
  mutationType: 'invoice/updateField',
});

export default {
    name: 'Spinner',
    computed: {
        ...mapFields(['spinner'])
    }
}
</script>

<style>
@keyframes spinner-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.dots {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  font-size: 20px;
  animation: spinner-loader 1500ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(0.5, 0.5, 0.5, 0.5) 1.5em 0 0 0, rgba(0.5, 0.5, 0.5, 0.5) 1.1em 1.1em 0 0, rgba(0.5, 0.5, 0.5, 0.5) 0 1.5em 0 0, rgba(0.5, 0.5, 0.5, 0.5) -1.1em 1.1em 0 0, rgba(0.5, 0.5, 0.5, 0.5) -1.5em 0 0 0, rgba(0.5, 0.5, 0.5, 0.5) -1.1em -1.1em 0 0, rgba(0.5, 0.5, 0.5, 0.5) 0 -1.5em 0 0, rgba(0.5, 0.5, 0.5, 0.5) 1.1em -1.1em 0 0;
  width: 0.75em;
  height: 0.75em;
  overflow: hidden;
}

div.messageContainer {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}

div.notify {
  align-self: flex-end;
  border: 1px solid #888;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #888;
  background-color: white;
  width: 200px;
  margin: 10px 10px 10px auto;
  padding: 10px;
  width: 500px;
}

</style>
