<template>
  <div class="custom-datalist" v-click-outside="hideList">
    <input
      @focus="showList"
      @keyup.exact.ctrl.enter="$emit('addRow')"
      @keyup.exact.ctrl.c="$emit('copyRow')"
      @keyup.exact.ctrl.delete="$emit('deleteRow')"
      @keyup.down="down"
      @keyup.exact.enter="selectEnter(index)"
      @keyup.up="up"
      :disabled="disabled"
      :placeholder="$t('product.code')"
      class="custom-datalist-input form-control"
      v-bind:class="{'':valid==null, 'is-valid':valid == true, 'is-invalid':valid == false}"
      v-model="inputVal"
    >
    <div v-if="show" class="datalist">
      <debounce-input @showList="showList" @hideList="hideList" :searchText="searchText" @debouncedText="searchText = $event"/>
      <div
        v-for="(item, key) in items"
        @mouseenter="mouseOver(key)"
        @mouseleave="index = null"
        v-on:click.stop="select(key)"
        :key="key"
        class="datalist-item"
        v-bind:class="{'selected':key===index}"
        v-on:keyup.down="down(key)"
        v-on:keyup.up="up(key)"
        v-on:
      >
        <div class="item-code-wrap">{{item.code}}</div>
        <div class="item-name-wrap">
          <span class="item-name">{{item.name}}</span>
        </div>
        <i class="fa fa-star yellow" v-if="item.isFavorite"></i>
      </div>
      <div v-if="items == 0">{{ $t("product.noResults") }}</div>
      <div class="list-end-item" v-if="total > 10">{{ $t("product.overTen") }}</div>
    </div>
  </div>
</template>

<script>
import DebounceInput from './DebounceInput'
export default {
  props: ["input", "valid", "disabled"],
  data() {
    return {
      show: false,
      index: null,
      selected: null,
      loading: false,
      searchText: "",
      items: [],
      total: 0
    };
  },
  components: {
    DebounceInput
  },
  computed: {
    inputVal: {
      get() {
        return this.input;
      },
      set(val) {
        this.$emit("changeVal",  val);
      }
    },
    token: function() {
      return this.$store.getters['invoice/getField']('userData.authToken')
    },
    upperBound: function() {
      return this.items.length - 1;
    }
  },
  methods: {
    selectFirst: function() {
      this.selected = this.items[0];
    },
    down: function(key) {
      if (this.index === null) {
        this.index = 0;
      } else if (this.index < this.upperBound) {
        this.index++;
      }
      this.$emit("select", this.items[this.index]);
    },
    up: function(key) {
      if (this.index > 0) {
        this.index--;
        this.$emit("select", this.items[this.index]);
      }
    },
    mouseOver: function(key) {
      this.index = key;
      this.$emit("select", this.items[key]);
    },
    showList: function() {
      let params = {};
      params.limit = 10;
      params.byColumn = "code";
      params.query = this.searchText;
      params.page = 1;
      if (this.searchText) {
        params.orderBy = "code";
        params.ascending = "ASC";
      } else {
        params.ascending = "DESC";
        params.orderBy = "is_favorite";
      }
      this.loading = true
      this.$store.dispatch("product/getProducts", {params, token:this.token}).then(res => {
        this.items = res.data;
        this.total = res.count;
        this.loading = false
      });
      this.show = true;
      this.index = null;
    },
    hideList: function() {
      this.show = false;
      this.index = null;
    },
    selectEnter: function(key) {
      if (key !== null) {
        this.index = null;
        this.$emit("select", this.items[key]);
      }
    },
    select: function(key) {
      this.hideList();
      this.index = key;
      this.$emit("select", this.items[key]);
    }
  },

  watch: {
    searchText: function(val) {
      let params = {};
      params.limit = 10;
      params.byColumn = "code";
      params.query = val;
      params.page = 1;
      if (val) {
        params.orderBy = "code";
        params.ascending = "ASC";
      } else {
        params.ascending = "DESC";
        params.orderBy = "is_favorite";
      }
      this.loading = true
      this.$store.dispatch("product/getProducts", {params, token:this.token}).then(res => {
        this.items = res.data;
        this.total = res.count;
        this.loading = false
      });
    }
  }
};
</script>

<style scoped>
.yellow {
  color: #f5cc27;
  line-height: 1.3;
  margin-left: auto;
}
.selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.custom-datalist {
  position: relative;
  width: 100%;
}
.datalist {
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 2px 4px 6px hsla(0, 0%, 0%, 0.4);
  background-color: white;
  border-radius: 5px;
  max-width: 200px;
}
.datalist-item {
  display: flex;
  flex-direction: row;
  /*   display: block;
 */
  text-align: left;
  padding: 3px 10px 3px 10px;
  color: #41474e;
}
.item-name {
  font-size: 12px;
  /*   text-overflow: ellipsis;
    overflow: hidden;
  white-space: nowrap; */
  color: #76787a;
}

.item-name-wrap {
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-code-wrap {
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-datalist-input {
  width: 100%;
  color: #495057;
  height: 31.5px;
  padding: 0 2em 0 0.5em;
      font-size: .875rem;
    line-height: 1.5;
    border-radius: 0;
}
.list-end-item {
  color: #41474e;
  font-size: 14px;
}
.search-field > input {
  width: 100%;
}
.search-field:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}
.search-icon {
  position: absolute;
  right: 5px;
  font-size: 20px;
  color: hsla(0, 0%, 0%, 0.4);
}


</style>
