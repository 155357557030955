/* eslint-disable */
import {
    isAddressDeleted,
    queryYTJ,
    queryAddress,
    fetchAuthToken,
    fetchCustomerData,
    fetchCustomerLogo,
    fetchInvoiceList,
    createReceivingStorage,
    createStorage,
    storageListFiles,
    storageDeleteFile,
    storageUploadFile,
    sendInvoiceZip,
    storageSetMetadata,
    queryTrafficLight,
    fetchSelectedCustomerData,
    updateInvoice,
    getInvoiceWithUUID,
    deleteInvoice,
    deliveryMethod,
    suggestNextInvoiceNum,
    fetchExtraData,
    getLatestInvoice as getLatestInvoiceApi
} from "../../client";
import { toNumber } from "@/utils";
const uuidv4 = require("uuid/v4");

export const actions = {
    fetchSelectedCustomerRecord({ commit }, { number }) {
        commit("CUSTOMER_SELECTED", true);
        commit("SET_SELECTED_CUSTOMER_DATA", number);
     
    },
    fetchPrefillCustomerData({ commit }, token) {
        fetchCustomerData(token)
            .then(data => {
                commit("SET_USER_CONTACT_INFO", data);
            })
            .catch(error => {
                console.log(error);
            })
    },
    fetchPrefillExtraData({ commit, state }) {
        fetchExtraData(state)
            .then(data => {
                commit("SET_USER_EXTRA_INFO", data);
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchPrefillBillingData({ commit }, token) {
        fetchCustomerData(token)
            .then(data => {
                commit("SET_USER_BILLING_DATA", data);
            })
            .catch(error => {
                console.log(error);
            });

        fetchCustomerLogo(token)
            .then(data => {
                commit("SET_USER_LOGO", data);
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchToken({ commit }, { tid, tkey }) {
        return fetchAuthToken(tid, tkey)
            .then(data => {
                commit("SET_AUTH_TOKEN", data.token);
                return data.token;
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    },
    fetchInvoices({ commit }) {
        return fetchInvoiceList();
    },

    selectInvoice({ commit }, { id, isNew }) {
        return getInvoiceWithUUID(id).then(data => {
            if (isNew) {
                suggestNextInvoiceNum()
                    .then(num => {
                        if (Number(num.data) < 1000) {
                            num.data = 1000;
                        }
                        data.invoiceNumber = num.data;
                        commit("SELECT_INVOICE", { data, isNew });
                    })
                    .catch(error => {
                        console.log(error);
                        commit("SELECT_INVOICE", { data, isNew });
                    });
            } else {
                commit("SELECT_INVOICE", { data, isNew });
            }
            this.dispatch("invoice/fetchPrefillExtraData", data);
        });
    },
    getLatestInvoice({commit}) {
        return getLatestInvoiceApi().then(data => {
                commit("SELECT_INVOICE", { data, isNew: false });
                this.dispatch("invoice/fetchPrefillExtraData", data);
            })
        },

    fetchCurrentInvoiceNumber({ commit }) {},
    deleteRow({ commit }, id) {
        commit("DELETE_ROW", id);
        this.dispatch("invoice/saveForm");
    },

    addRow({ commit }) {
        commit("ADD_ROW");
        this.dispatch("invoice/saveForm");
    },
    updateRow({ commit}, newRow) {
        commit("UPDATE_ROW", newRow)
        this.dispatch("invoice/saveForm");
    },
    updateRowField({commit}, req) {
        commit("UPDATE_ROW_FIELD", req)
        this.dispatch("invoice/saveForm");
    },
    addCommentRow({ commit }, rowId) {
        commit("ADD_COMMENT_ROW", rowId)
    },
    deleteCommentRow({ commit }, {rowId, commentIndex}) {
        commit("DELETE_COMMENT_ROW", {rowId, commentIndex});
        this.dispatch("invoice/saveForm");
    },
    changeRowVATRates({ commit }) {
        commit("CHANGE_ROW_VAT_RATES");
    },

    saveForm({ commit }) {
        commit("SAVE_FORM");
    },

    copyRow({ commit }, row) {
        commit("COPY_ROW", row);
        this.dispatch("invoice/saveForm");
    },

    updateSum({ commit }) {
        commit("UPDATE_SUM");
    },

    updateField({ commit }, { formGroup, dataField, value }) {
        commit("UPDATE_FIELD", {
            formGroup: formGroup,
            dataField: dataField,
            value: value
        });
        this.dispatch("invoice/saveForm");
    },

    validateField({ commit }, { formGroup, dataField, value }) {
        commit("VALIDATE_FIELD", {
            formGroup: formGroup,
            dataField: dataField,
            value: value
        });
    },
    resetValidation({ commit }, { rowId, fieldName }) {
        commit("RESET_ROW_FIELD_VALIDATION", { rowId, fieldName });
    },

    newInvoice({ commit }, data) {
        suggestNextInvoiceNum()
            .then(num => {
                if (Number(num.data) < 1000) {
                    num.data = 1000;
                }
                commit("INIT_INVOICE", num.data);
                this.dispatch("invoice/fetchPrefillCustomerData", data);
                this.dispatch("invoice/fetchPrefillExtraData", data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    toggleSection({ commit }, section) {
        commit("TOGGLE_SECTION", section);
    },
    removeInvoice({ commit }, id) {
        commit("REMOVE_INVOICE_FROM_LIST", id);
    },
    sendInvoice({ commit }) {
        commit("VALIDATE_AND_SEND_INVOICE");
    },

    addToInvalidFields({ commit }, obj) {
        commit("ADD_INVALID_FIELDS", obj);
    },
    searchCustomer({ commit }, { bid, token, field }) {
        bid = bid.trim();
        if (bid.length === 9) {
            queryYTJ(bid, token)
                .then(data => {
                    commit("SET_ADDRESS_DATA", { data, field });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },

    queryEInvoiceAddresses({ commit }, { bid, tid, tkey }) {
        return queryAddress(bid, tid, tkey);
    },

    deliveryMethodQuery(
        { commit },
        {
            receiverBid,
            senderBid,
            senderName,
            receiverName,
            tid,
            tkey,
            eInvoiceAddress
        }
    ) {
        return new Promise((resolve, reject) => {
            deliveryMethod(
                senderBid,
                receiverBid,
                senderName,
                receiverName,
                tid,
                tkey,
                eInvoiceAddress
            )
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    resolve(error);
                });
        });
    },

    resetEInvoiceAddresses({ commit }) {
        commit("RESET_EINVOICE_ADDRESSES");
    },

    isAddressDeleted({ commit }, { tid, tkey, operator, address }) {
        return isAddressDeleted(tid, tkey, address, operator)
            .then(data => {
                if (data === true) {
                    commit("VALIDATE_FIELD", {
                        formGroup: "billingInfo",
                        dataField: "eInvoiceAddressManual",
                        value: false
                    });
                    return data;
                } else if (data === false) {
                    return data;
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    createAppendixStorage({ commit }, { tid, tkey, name }) {
        return createStorage(tid, tkey, name)
            .then(response => {
                let appendices = {};
                response.forEach(item => {
                    appendices[item.Value.type] = item.Value["_@ttribute"];
                });
                appendices.name = name;
                commit("CREATE_APPENDIX_STORAGE", appendices);
                return appendices;
            })
            .catch(error => {
                console.log(error);
            });
    },

    createPurchaseInvoiceStorage({ commit }, { tid, tkey }) {
        let name = uuidv4();
        return createReceivingStorage(tid, tkey, name)
            .then(response => {
                let appendices = {};
                response.forEach(item => {
                    appendices[item.Value.type] = item.Value["_@ttribute"];
                });
                appendices.name = name;
                return appendices;
            })
            .catch(error => {
                console.log(error);
            });
    },

    listAppendices({ commit }, { storageId, storageKey }) {
        storageListFiles(storageId, storageKey)
            .then(response => {
                console.log(response);
                let files = response.map(item => {
                    let file = {};
                    item.Value.forEach(row => {
                        switch (row.type) {
                            case "FileName":
                                file.name = row["_@ttribute"];
                                break;
                            case "FileSize":
                                file.size = row["_@ttribute"];
                                break;
                            case "FileMimeType":
                                file.type = row["_@ttribute"];
                                break;
                        }
                    });
                    return { file, loading: false };
                });
                commit("SET_APPENDIX_FILES", files);
            })
            .catch(error => {
                console.log(error);
            });
    },

    deleteAppendix({ commit }, { tid, tkey, storageId, storageKey, fileName }) {
        return storageDeleteFile(tid, tkey, storageId, storageKey, fileName)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    },

    uploadAppendix(
        { commit },
        { tid, tkey, storageId, mediaType, fileName, file, role }
    ) {
        return storageUploadFile(
            tid,
            tkey,
            storageId,
            mediaType,
            fileName,
            file,
            role
        )
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateAppendices({ commit }, data) {
        commit("UPDATE_APPENDICES", data);
    },

    uploadToScanning({ commit }, { tid, tkey, zip }) {
        return sendInvoiceZip(tid, tkey, zip, "voscan", "1.0")
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    },

    uploadPurchaseInvoice(
        { commit },
        { tid, tkey, uid, metadata, contentType }
    ) {
        return createStorage(tid, tkey, name)
            .then(response => {
                let appendices = {};
                response.forEach(item => {
                    appendices[item.Value.type] = item.Value["_@ttribute"];
                });
                appendices.name = name;
                return appendices;
            })
            .then(result => {
                return storageSetMetadata(
                    tid,
                    result.storageId,
                    tkey,
                    metadata,
                    contentType
                ).then(response => {
                    return response;
                });
            })
            .then(result => {
                return storageUploadFile(
                    tid,
                    tkey,
                    result.storageId,
                    mediaType,
                    fileName,
                    file
                ).then(response => {
                    return response;
                });
            })
            .catch(error => {
                console.log(error);
            });
    },

    setStorageMeta(
        { commit },
        { transferId, storageId, transferKey, metadata }
    ) {
        return storageSetMetadata(transferId, storageId, transferKey, metadata)
            .then(response => {})
            .catch(error => {
                console.log(error);
            });
    },

    checkTrafficLight({ commit }, { bids, token }) {
        return queryTrafficLight({ bids }, token)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateInvoiceToDb({ commit }, state) {
        let languages = {
            en: "en_US",
            fi: "fi_FI",
            sv: "sv_SE",
            svfi: "sv_FI"
        };
        let b = state.invoiceField.billingInfo;
        let a = state.invoiceField.additionalInfo;
        let bv = state.invalidFields.billingInfo;
        let language;
        let invoice = {
            bic: bv.includes("BIC") ? "" : b.BIC,
            bankAccount: b.bankAccount.Value,
            paymentMethod: b.paymentMethod,
            bankReference: bv.includes("bankReference") ? "" : b.bankReference,
            orderReference: b.orderReference,
            currency: b.currency.value,
            discount: b.discount ? toNumber(b.discount) : null,
            dueDate: b.dueDate,
            contactPerson: b.contactPerson,
            creditNote: b.creditNote,
            language: languages[b.invoiceLanguage],
            invoiceNumber: b.invoiceNumber ? toNumber(b.invoiceNumber) : null,
            cashRounding: b.rounding,
            taxCountry: b.selectedTaxCategory.toUpperCase(),
            invoiceDate: b.sentDate || null,
            status: state.status,
            invoiceId: state.invoiceId,
            storageId: state.appendices.StorageID,
            storageKey: state.appendices.StorageKey,
            freeText: a.additionalInfo,
            deliveryMethod: a.deliveryMethod,
            deliveryDate: a.deliveryDay || null,
            paymentTermsDays: b.paymentCondition,
            penaltyInterest: toNumber(b.penaltyInterest),
            reverseVAT: b.reverseVAT || false,
            vatFreeText: a.vatFreeText
        };

        let roles = {
            BUYER: "customerAddress",
            PAYER: "billingAddress",
            DELIVERY: "deliveryAddress",
            ISSUER: "billingInfo"
        };
        let anyparties = [];

        Object.keys(roles).forEach(r => {
            let c = state.invoiceField[roles[r]];
            let addr = {
                address: c.address,
                businessId: c.businessId || "",
                country: c.country.value ? c.country.value : "",
                postal: c.postal,
                role: r,
                zipCode: c.zipCode,
                name: c.name,
                customerNumber:
                    state.customerRecordSelected && r !== "ISSUER"
                        ? state.customerRecordSelected.customerNumber
                        : null,
                einvoiceAddress: "",
                operator: "",
                vatid: ""
            };
            if (r === "ISSUER") {
                addr.www = c.www;
                addr.fax = c.fax;
                addr.phone = c.phone;
                addr.email = c.email;
                addr.vatid = c.taxCode;
                addr.einvoiceAddress = c.sendereInvoiceAddress;
            }

            if (r === "PAYER") {
                if (b.eInvoiceAddress.value === "other") {
                    addr.einvoiceAddress = b.eInvoiceAddressManual;
                } else if (b.eInvoiceAddress.value === "EMAIL") {
                    addr.email = b.receiverEmail;
                    addr.einvoiceAddress = "";
                } else {
                    addr.einvoiceAddress = b.eInvoiceAddress.value;
                }

                if (b.operator.name) {
                    addr.operator = b.operator.name;
                }
            }

            anyparties.push(addr);
        });
        invoice.anyParties = anyparties;
        invoice.invoiceLines = state.productRow.map(r => {
            return {
                ...r,
                ammount: r.ammount !== "" ? toNumber(r.ammount) : null,
                discount: r.discount !== "" ? toNumber(r.discount) : null,
                price: r.price !== "" ? toNumber(r.price) : null,
                totalVAT: r.totalVAT !== "" ? toNumber(r.totalVAT) : null,
                VAT: r.VAT !== "" ? toNumber(r.VAT) : null
            };
        });

        return updateInvoice(invoice, null)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    },

    deleteInvoice({ commit }, uuid) {
        return deleteInvoice(uuid)
            .then(response => {
                return response;
            })
            .catch(error => {
                return error;
            });
    },

    spin({ commit }, data) {
        commit("SET_SPINNER", data);
    },

    disableEditing({ commit }, data) {
        commit("SET_DISABLED", data);
    },

    setDeliveryType({ commit }, data) {
        commit("SET_DELIVERY_TYPE", data);
    }
};
