<template>
  <div id="app">
    <!-- <app-nav/> -->
    <transition>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
/* import AppNav from './components/AppNav.vue' */
export default {

  name: 'App',
/*   components: {
    AppNav
  } */
};
</script>

