import Vue from "vue";
import Vuex from "vuex";
import { invoice } from "./invoice";
import { customer } from "./customer";
import { product } from "./product";

const createStore = (function() {
    let store = null;
    return function(userData, app) {
        if (store) {
            return store;
        } else {
            Vue.use(Vuex);
            let moduleToLoad;
            switch (app) {
                case "InvoiceBuilder":
                    moduleToLoad = invoice(userData);
                    break;
                case "PurchaseInvoice":
                    moduleToLoad = invoice(userData);
                    break;
                default:
                    break;
            }
            const store = new Vuex.Store({
                strict: false,
                getters: {
                    finnishSite() {
                        return Boolean(userData.entryPoint === "laskumappi");
                    },
                    swedishSite() {
                        return Boolean(userData.entryPoint === "fakturamappen");
                    },
                },
                modules: {
                    invoice: moduleToLoad,
                    customer: customer,
                    product: product
                }
            });
            return store;
        }
    };
})();

export default createStore;
