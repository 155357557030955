<template>
    <div class="sidebar-container">
        <div :title="$t('common.customerRecord')" @click="$router.push({name:'customerRecord'})" class="sidebar-button"><i class="sidebar-icon fas fa-2x fa-users"></i></div>
        <div :title="$t('common.invoices')" @click="invoicesOpen = !invoicesOpen" class="sidebar-button"><i class="sidebar-icon far fa-2x fa-file-alt"></i></div>
        <div :title="$t('common.productRecord')" @click="$router.push({name:'productRecord'})" class="sidebar-button"><i class="sidebar-icon fas fa-2x fa-shopping-basket"></i></div>
        <invoices :is-open="invoicesOpen" v-on:close="invoicesOpen = !invoicesOpen"></invoices>
    </div>
</template>

<script>
import Invoices from '@/components/invoices/Invoices.vue'

export default {
    name: "Sidebar",
    data() {
        return {
            invoicesOpen: false,
        }
    },
    components: {
        Invoices
    }

}
</script>

<style scoped>
.sidebar-container {
    float: left;
    display: flex;
    flex-direction: column;
}
.sidebar-icon {
    width: 50px;
    height: 50px;
    background: gray;
    margin-bottom: 0.5rem;
    border-radius: 0 5px 5px 0;
    margin-right: 0.5rem;
    line-height: 1.5;
}
.sidebar-icon:hover {
    background: rgb(97, 97, 97);
}
</style>
