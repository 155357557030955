/*eslint-disable*/
const SHA256 = require("crypto-js/sha256");
import moment from "moment";
import BICFromIBAN from "bic-from-iban";
import { createFinvoice, createPurchaseFinvoice } from "./finvoice";
export { createFinvoice, createPurchaseFinvoice };
import BIC from "bic";

export function countDays(date) {
    if (date) {
        let now = moment();
        date = moment(date).add(1, "days");
        let days = date.diff(now, "days");
        if (days < 0) {
            return 0;
        } else {
            return date.diff(now, "days");
        }
    }
    return 0;
}
export function escapeXML(unsafe) {
    if (typeof unsafe === "string") {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&apos;");
    } else {
        return unsafe;
    }
}
export function addDays(start, days) {
    let date = moment(start).add(days, "days");
    return date.format("YYYY-MM-DD");
}
export function validateYtunnus(bid) {
    if (bid === "") {
        return null;
    }
    if (bid.length >= 3) {
        if (bid.substring(0, 3) === "999") {
            return true
        }
    }
    return bid.length === 9 && bid.charAt(7) === "-";
}
export function validateMinEight(bid) {
    if (bid === "") {
        return null;
    }
    return bid.length >= 8;
}
export function validateVatSimple(vat) {

    if (vat === "") {
        return null;
    }

    if (vat.match(/^([A-Z]{2,3})([A-Z0-9]{7,13})$/)) {
        return true;
    } else {
        return false;
    }
}

function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
    };
    var iban = String(input)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}
function mod97(string) {
    var checksum = string.slice(0, 2),
        fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export function validateBIC(value) {
    if (value) {
        return BIC.isValid(value);
    }
    return null;
}

export function validateFinOVT(bid) {
    return function helper(value) {
        return value === "0037" + bid.replace("-", "");
    };
}

export function validateBankAccount(value, type) {
    if (!value) {
        return null;
    }
    switch (type) {
        case "OTHER": 
            return value.length > 1
        case "IBAN":
            return BICFromIBAN.validateIBAN(value.replace(/\s/g, ""));

        case "BANKGIRO": {
            let parts = value.split("-");
            if (parts.length !== 2) {
                return false;
            }
            if (
                parts.length == 2 &&
                (parts[0].length === 3) | (parts[0].length === 4) &&
                parts[1].length === 4
            ) {
                let serial = parts[0].concat(parts[1]);
                let sum = 0;
                let start = (serial.length - 1) % 2 === 0 ? 1 : 0;
                for (let i = 0; i < serial.length - 1; i++) {
                    let digit;
                    if (start === 1) {
                        if (i % 2 !== 0 && i !== 0) {
                            digit = Number(serial.charAt(i)) * 2;
                            if (digit >= 10) {
                                let firstNum = Number(
                                    digit.toString().charAt(0)
                                );
                                let secNum = Number(digit.toString().charAt(1));
                                sum += firstNum + secNum;
                            } else {
                                sum += digit;
                            }
                        } else {
                            digit = Number(serial.charAt(i));
                            sum += digit;
                        }
                    } else {
                        if (i % 2 === 0 || i === 0) {
                            digit = Number(serial.charAt(i)) * 2;
                            if (digit >= 10) {
                                let firstNum = Number(
                                    digit.toString().charAt(0)
                                );
                                let secNum = Number(digit.toString().charAt(1));
                                sum += firstNum + secNum;
                            } else {
                                sum += digit;
                            }
                        } else {
                            digit = Number(serial.charAt(i));
                            sum += digit;
                        }
                    }
                }
                if (
                    (sum + Number(serial.charAt(serial.length - 1))) % 10 ===
                    0
                ) {
                    return true;
                }
                return false;
            }
            return false;
        }
        case "PLUSGIRO": {
            let parts = value.split("-");
            if (parts.length !== 2) {
                return false;
            }
            if (
                parts[0].length === 6 ||
                (parts[0].length === 5 && parts[1].length === 1)
            ) {
                let serial = parts[0];
                let sum = 0;
                for (let i = 0; i < serial.length; i++) {
                    let digit;
                    if (parts[0].length === 6) {
                        if (i % 2 !== 0 && i !== 0) {
                            digit = Number(serial.charAt(i)) * 2;
                            if (digit >= 10) {
                                let firstNum = Number(
                                    digit.toString().charAt(0)
                                );
                                let secNum = Number(digit.toString().charAt(1));
                                sum += firstNum + secNum;
                            } else {
                                sum += digit;
                            }
                        } else {
                            digit = Number(serial.charAt(i));
                            sum += digit;
                        }
                    } else {
                        if (i % 2 === 0 || i === 0) {
                            digit = Number(serial.charAt(i)) * 2;
                            if (digit >= 10) {
                                let firstNum = Number(
                                    digit.toString().charAt(0)
                                );
                                let secNum = Number(digit.toString().charAt(1));
                                sum += firstNum + secNum;
                            } else {
                                sum += digit;
                            }
                        } else {
                            digit = Number(serial.charAt(i));
                            sum += digit;
                        }
                    }
                }
                if ((sum + Number(parts[1])) % 10 === 0) {
                    return true;
                }
                return false;
            }
            return false;
        }
    }
}
export function spaceOutIBAN(iban) {
    iban = iban.replace(/\s/g, "");
    let chunks = [];
    for (let i = 0; i < iban.length; i = i + 4) {
        chunks.push(iban.slice(i, i + 4));
    }
    return chunks.join(" ");
}
export function firstLetterUpper(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(date) {
    if (date) {
        let arr = date.split("-");
        return arr[2] + "." + arr[1] + "." + arr[0];
    }
    return null;
}

export function formatComma(number) {
    return number.toString().replace(".", ",");
}

export function toNumber(str) {
    if (typeof str === "string") {
        return Number(str.replace(",", ".").replace(/\s/g, ""));
    } else {
        return str;
    }
}

export function roundTwoD(num) {
    num = Math.round(num * 100) / 100;
    return num;
}

export function roundFiveD(num) {
    num = Math.round(num * 100000) / 100000;
    return num;
}

/* Format number to string
 * 1 -> 1,00
 * 1,0 -> 1,00
 *
 *
 */
export function formatNumber(number) {
    let numStr = number.toString().trim();
    if (numStr.includes(".") || numStr.includes(",")) {
        numStr = numStr.replace(".", ",");
        let split = numStr.split(",");
        if (split[1].length === 1) {
            numStr = split[0] + "," + split[1] + "0";
        } else {
            numStr = split[0] + "," + split[1];
        }
    } else {
        numStr += ",00";
    }
    return numStr;
}

export function validateEmail(email) {
    if (email === "") {
        return null;
    }
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/* TRUE:
 * > 0
 *  0,1
 *  1,11
 *  1
 *
 * FALSE:
 * < 0
 * "asd"
 *  0,001
 *  ,0
 *  1,
 *  1.0
 */
export function validateNumberInput(input) {
    let re = /^\d+(\,\d{1,5})?$/;
    return re.test(input);
}
export function validateAmountInput(input) {
    let re = /^\-?\d+(\,\d{1,2})?$/;
    return re.test(input);
}
export function validateOrgNum(input) {
    if (input === "") {
        return null;
    }
    if (input.includes("-")) {
        let arr = input.split("-");
        return arr[0].length === 6 && arr[1].length === 4;
    } else {
        return false;
    }
}

export function validateDiscount(input) {
    if (input) {
        if (typeof input === "string") {
            if (input.includes(",")) {
                let value = Number(input.replace(",", "."));
                if (value > 100) {
                    return false;
                }
            }
            if (Number(input) > 100) {
                return false;
            }
        }
        return validateNumberInput(input);
    }
    return null;
}

export function simpleValidation(str) {
    if (typeof str !== "string") {
        str = String(str)
    }
    if (str.length > 0) {
        return true;
    } else {
        return null;
    }
}

export function returnAlwaysTrue(str) {
    return true;
}

export function dateValidation(date) {
    if (date) {
        let now = moment(new Date());
        date = moment(date);
        let days = date.diff(now, "days");
        return days >= -30 && days <= 30;
    }
    return null;
}

export function validateReferenceFakturamappen(ref) {
    if (ref === "") return null;
    if (ref.length >= 2 && ref.length <= 21) {
        return /^[a-zA-zA-Z0-9]*$/.test(ref);
    }
    return false;
}

export function validateReference(ref) {
    if (!ref) {
        return null;
    }
    if (ref.length < 3) {
        return false;
    }

    if (/^RF../.test(ref)) {
        if (ref.length > 25) {
            return false;
        }
        ref = ref.replace(/^RF../, "");
    } else {
        if (ref.length > 20) {
            return false;
        }
    }

    let verification = ref.substring(ref.length - 1);
    let s = calculateReferenceVerification(ref.substring(0, ref.length - 1));

    if (verification === s) {
        return true;
    } else {
        return false;
    }
}

export function calculateReferenceVerification(str) {
    let ref = str.toString().split("").reverse();
    let sum = 0;
    let weight = [7, 3, 1];
    for (let i = 0; i < ref.length; i++) {
        sum += ref[i] * weight[i % 3];
    }
    let s = sum.toString();
    s = s.substring(0, s.length - 1) + "0";
    s = parseInt(s) + 10;
    s = s - sum;
    if (s === 10) {
        s = 0;
    }
    return s.toString();
}

export function convertBlobToImage(data) {
    var bytes = new Uint8Array(data.length / 2);

    for (var i = 0; i < data.length; i += 2) {
        bytes[i / 2] = parseInt(data.substring(i, i + 2), 16);
    }

    var blob = new Blob([bytes], { type: "image/bmp" });

    return URL.createObjectURL(blob);
}

export function authParams(tid, tkey) {
    let t = moment().utc().format("YYYYMMDDHHmmss");
    let digest = "SHA-256:" + SHA256([tid, t, tkey].join("+"));

    return {
        TraID: tid,
        Timestamp: t,
        Digest: digest,
    };
}

export function getBicCode(iban) {
    if (BICFromIBAN.getBIC(iban)) {
        return BICFromIBAN.getBIC(iban);
    }
    if (!iban) {
        return "";
    }
    iban = iban.toUpperCase().replace(/ /g, "");

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }
    if (iban.startsWith("FI")) {
        let bcode = iban.substring(4, 5);
        let bcode2 = iban.substring(4, 7);
        let bgroup = iban.substring(5, 6);

        if (bcode === "1" || bcode === "2") {
            return "NDEAFIHH";
        }
        if (bcode === "3") {
            if (bgroup === "1") {
                return "HANDFIHH";
            }
            if (bgroup === "3") {
                return "ESSEFIHX";
            }
            if (bgroup === "4") {
                return "DABAFIHX";
            }
            if (bgroup === "6") {
                return "SBANFIHH";
            }
            if (bgroup === "7") {
                return "DNBAFIHX";
            }
            if (bgroup === "8") {
                return "SWEDFIHH";
            }
            if (bgroup === "9") {
                return "SBANFIHH";
            }
        }
        if (bcode === "4") {
            let helsfi_map1 = {
                405: "HELSFIHH",
                497: "HELSFIHH",
                400: "ITELFIHH",
                402: "ITELFIHH",
                403: "ITELFIHH",
                479: "POPFFI22",
                499: "HELSFIHH",
            };

            if (helsfi_map1[bcode2]) {
                return helsfi_map1[bcode2];
            }

            if (
                (bcode2 >= 406 && bcode2 <= 408) ||
                (bcode2 >= 410 && bcode2 <= 412) ||
                (bcode2 >= 414 && bcode2 <= 421) ||
                (bcode2 >= 423 && bcode2 <= 432) ||
                (bcode2 >= 435 && bcode2 <= 452) ||
                (bcode2 >= 454 && bcode2 <= 464) ||
                (bcode2 >= 483 && bcode2 <= 493) ||
                (bcode2 >= 495 && bcode2 <= 496)
            ) {
                return "ITELFIHH";
            }

            if (bcode2 >= 470 && bcode2 <= 478) {
                return "POPFFI22";
            }

            return "HELSFIHH";
        }
        if (bcode === "5") {
            return "OKOYFIHH";
        }
        if (bcode === "6") {
            return "AABAFI22";
        }

        if (bcode2 === "713") {
            return "CITIFIHX";
        }

        if (bcode2 === "799") {
            return "HOLVFIHH";
        }

        if (bcode === "8") {
            return "DABAFIHH";
        }
        return null;
    } else if (iban.startsWith("SE")) {
        let code = Number(iban.slice(4, 7));
        if (code >= 300 && code <= 340) {
            return "NDEASESS";
        } else if (code >= 740 && code <= 760) {
            return "ESSESESS";
        } else if (code >= 790 && code <= 840) {
            return "HANDSESS";
        } else if (code == 710) {
            return "SKIASESS";
        } else if (code == 870 || code == 860) {
            return "SWEDSESS";
        } else if (
            code == 700 ||
            code == 800 ||
            code == 900 ||
            code == 100 ||
            code == 110
        ) {
            return "DABASESX";
        }
    }
}
export function nullToEmpty(value) {
    if (value === null) {
        return "";
    }
    return value;
}

export function nullToEmptyObj(obj) {
    Object.keys(obj).forEach((k) => {
        obj[k] = nullToEmpty(obj[k]);
    });
    return obj;
}

export function getTaxCategories() {
    return {
        'se': [0, 6, 12, 25],
        'fi': [0, 10, 14, 24, 25.5],
        'gb': [0, 19],
        'nl': [0, 21],
        'at': [0, 20]
    };
}

export function getTaxCategoriesForCountry(country) {
    const taxCategories = getTaxCategories();

    if (country in taxCategories) {
        return taxCategories[country];
    } else {
        console.warn("No tax categories for country: %s", country);
        return [];
    }
}

export function getFinnishTaxCategories() {
    return getTaxCategoriesForCountry('fi');
}

export function getSwedishTaxCategories() {
    return getTaxCategoriesForCountry('se');
}

export function getFinnishGeneralVatRate() {
    const curDate = new Date();
    if (curDate.getFullYear() > 2024 || (curDate.getFullYear() === 2024 && curDate.getMonth() >= 8)) {
        return 25.5;
    } else {
        return 24;
    }
}

export function getSwedishGeneralVatRate() {
    return 25;
}

export function initTaxations(country) {
    const taxations = {
        'total': 0,
        'totalVAT': 0,
        'totalWithoutVAT': 0,
        'totalVATRounded:': 0
    };

    for (const taxCat of getTaxCategoriesForCountry(country)) {
        taxations[taxCat] = { VAT: 0, withoutVAT: 0, total: 0 };
    }

    return taxations;
}
