<template>
  <div id="action-bar" class="row">
    <div class="row">
      <button
        :disabled="editingDisabled"
        id="send-btn"
        class="btn btn-success"
        v-on:click="sendInvoice"
      >{{$t("common.send")}}</button>
      <appendices-button/>
      <button
        :disabled="editingDisabled"
        id="reset-btn"
        class="btn btn-secondary"
        v-on:click="resetInvoice"
      >{{$t("common.new")}}</button>
    </div>
    <div class="row">
      <button
        :disabled="editingDisabled"
        id="product-btn"
        @click="$router.push({name:'productRecord'})"
        class="btn btn-secondary"
      >{{$t("common.productRecord")}}</button>
<!--       <button
        :disabled="editingDisabled"
        id="customer-btn"
        @click="$router.push({name:'customerRecord'})"
        class="btn btn-secondary"
      >{{$t("common.customerRecord")}}</button> -->
      <invoices-button id="invoices-btn"/>
    </div>
    <a href class="defaults-link" v-on:click="changePath">{{ $t("invoiceForm.changePrefillInfo") }}</a>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import AppendicesButton from "./appendices/AppendicesButton.vue";
import InvoicesButton from "@/components/invoices/InvoicesButton.vue";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  name: "ActionBar",
  methods: {
    ...mapActions("invoice", [
      "newInvoice",
      "sendInvoice",
      "fetchInvoice",
      "selectInvoice",
      "newInvoice"
    ]),
    resetInvoice() {
      this.newInvoice(null);
    },
    changePath: function() {
      window.parentIFrame.sendMessage(
        JSON.stringify({ type: "path", value: "tools.settings" })
      );
    }
  },

  computed: {
    ...mapFields(["invoiceField.editingDisabled"])
  },

  components: {
    AppendicesButton,
    InvoicesButton
  }
};
</script>

<style>
#action-bar {
  /*     width: 90%;
    position: relative; */
  margin-bottom: 0.5em;
}
#action-bar .btn {
}
.defaults-link {
  color: white;
  margin-left: 10px;
  text-decoration: underline;
}
.defaults-link:hover {
  color: rgb(161, 161, 161);
}
#reset-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 1em;
}
#send-btn {
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#product-btn {
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#customer-btn {
  border-radius: 0;
  margin-right: 0 !important;
  border-right: #5a6268 1px solid;
  border-left: #5a6268 1px solid;
}

#invoices-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media screen and (max-width: 800px) {
  #action-bar .btn {
    padding: 5px 5px 5px 5px;
    margin-bottom: 10px;
  }
}
</style>
