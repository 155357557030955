import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue'
const BACKEND_URL = process.env.VUE_APP_BACKEND_URL // "http://localhost:11005" //

export const product = {
    namespaced: true,
    state: {
    },    
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },

    actions: {
        getProducts({commit}, {params, token}) {
        return new Promise((resolve, reject) => {
            return Vue.http.get(BACKEND_URL + "/product/filter", {params: params, headers: {'Authorization': 'Bearer ' + token}})
            .then(response => response.json()).then(data => {
              resolve(data)
            }).catch(error => {
              console.log(error)
              reject(error)
            })
        })

        },
        updateProduct({commit}, {data, token}) {
            return new Promise((resolve, reject) => {
                return Vue.http.put(BACKEND_URL + "/product", data, {headers: {'Authorization': 'Bearer ' + token}})
                .then(response => response.json()).then(data => {
                  resolve(data)
                }).catch(error => {
                  console.log(error)
                  reject(error)
                })
            })
        },
        favoriteProduct({commit}, {req, token}) {
            return new Promise((resolve, reject) => {
                return Vue.http.put(BACKEND_URL + "/product/favorite", req, {headers: {'Authorization': 'Bearer ' + token}})
                .then(response => response.json()).then(data => {
                  resolve(data)
                }).catch(error => {
                  console.log(error)
                  reject(error)
                })
            })
        },
        addProduct({commit}, {data, token}) {
            return new Promise((resolve, reject) => {
                return Vue.http.post(BACKEND_URL + "/product", data, {headers: {'Authorization': 'Bearer ' + token}})
                .then(response => response.json()).then(data => {
                  resolve(data)
                }).catch(error => {
                  console.log(error)
                  reject(error)
                })
            })
        },
        deleteProducts({commit}, {data, token}) {
            return new Promise((resolve, reject) => {
                return Vue.http.delete(BACKEND_URL + "/product", {body:data, headers: {'Authorization': 'Bearer ' + token}})
                .then(response => response.json()).then(data => {
                  resolve(data)
                }).catch(error => {
                  console.log(error)
                  reject(error)
                })
            })
        },
    }
}
