<template>
  <div class="input-group search-field">
    <input
      @focus="$emit('showList')"
      @blur="$emit('hideList')"
      style="width:100%"
      class="custom-datalist-input"
      v-bind:value="searchText"
      v-on:input="debounceSearch($event.target.value)"
      v-bind:placeholder="$t('product.searchShort')"
    >
    <div class="search-icon">
      <i class="fa fa-search"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchText"],
  data() {
    return {
      searchTimeout: null
    };
  },

  methods: {
    debounceSearch: function(val) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      var that = this;
      this.searchTimeout = setTimeout(function() {
        that.$emit("debouncedText", val);
      }, 500);
    }
  }
};
</script>

<style>
.custom-datalist-input {
  width: 100%;
  color: #495057;
  height: 31.5px;
  padding: 0 2em 0 0.5em;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}
.list-end-item {
  color: #41474e;
  font-size: 14px;
}
.search-field > input {
  width: 100%;
}
.search-field:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}
.search-icon {
  position: absolute;
  right: 5px;
  font-size: 20px;
  color: hsla(0, 0%, 0%, 0.4);
}
</style>
