/* eslint-disable */
import Vue from 'vue'
const uuidv4 = require('uuid/v4')
import { codes } from '../../assets/countrycodes'
import { initInvoice } from './initInvoice.js'
import { eInvoiceAddresses } from "@/utils/addresses"
import { initTaxations, getTaxCategories } from '../../utils'

export function initialState(userData) {
    const locale = userData.locale || 'fi';
    const selectedTaxCategory = userData.entryPoint === 'laskumappi' ? 'fi' : 'se';
    const taxations = initTaxations(selectedTaxCategory);
    const taxCategories = getTaxCategories();

    userData.sendereInvoiceAddress = ''

    let defaultCountry = userData.entryPoint === 'laskumappi' ? codes[locale].find(c => c.value === 'FI') : codes[locale].find(c => c.value === 'SE')
    const state = {
        invoiceField: {
            editingDisabled: false,
            customerAddress: {
                businessId: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                validations: {
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                }
            },
            billingAddress: {
                businessId: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: defaultCountry,
                validations: {
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                }
            },
            deliveryAddress: {
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                validations: {
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                }
            },
            billingInfo: {
                //        customerId: '',
                receiverEmail: '',
                contactPerson: '',
                sentDate: '',
                dueDate: '',
                /* invoiceChannel: '', */
                orderReference: '',
                invoiceNumber: '',
                sendereInvoiceAddress: '',
                bankAccount: { Type: '', Value: '' },
                bankReference: '',
                penaltyInterest: '0,00',
                discount: '',
                paymentCondition: 1,
                paymentMethod: userData.entryPoint === 'laskumappi' ? 'IBAN' : 'PLUSGIRO' || 'IBAN',
                taxCode: '',
                selectedTaxCategory: selectedTaxCategory,
                rounding: false,
                creditNote: false,
                reverseVAT: false,
                currency: userData.entryPoint === 'laskumappi' ? { name: 'EUR', value: 'EUR' } : { name: 'SEK', value: 'SEK' },
                eInvoiceAddress: { name: '', value: '' },
                operator: { name: '', value: '' },
                eInvoiceAddressManual: '',
                BIC: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                businessId: '',
                phone: '',
                fax: '',
                www: '',
                email: '',
                invoiceLanguage: locale || 'fi',
                validations: {
                    //          customerIdIsValid: true,
                    contactPersonIsValid: null,
                    sentDateIsValid: null,
                    sendereInvoiceAddressIsValid: null,
                    //dueDateIsValid: null,
                    //orderReferenceIsValid: null,
                    receiverEmailIsValid: null,
                    invoiceNumberIsValid: null,
                    bankAccountIsValid: null,
                    bankReferenceIsValid: null,
                    penaltyInterestIsValid: true,
                    discountIsValid: null,
                    currencyIsValid: true,
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    BICIsValid: null,
                    countryIsValid: null,
                    eInvoiceAddressManualIsValid: null,
                    operatorIsValid: null,
                    taxCodeIsValid: null
                }
            },
            additionalInfo: {
                deliveryDay: '',
                deliveryMethod: '',
                additionalInfo: '',
                vatFreeText: '',
                validations: {
                    deliveryDayIsValid: null,
                    deliveryMethodIsValid: null,
                    vatFreeTextIsValid: null
                }
            }
        },
        productRow: [{
            productId: null,
            id: 0,
            name: '',
            nameIsValid: null,
            code: '',
            codeIsValid: null,
            ammount: '',
            ammountIsValid: null,
            unit: 'piece',
            price: '',
            priceIsValid: null,
            discount: '',
            discountIsValid: null,
            VAT: 0,
            withoutVAT: 0,
            totalVAT: 0,
            total: 0,
            info: '',
            additionalFreeText: []
        }],
        invalidFields: {
            customerAddress: [],
            billingAddress: [],
            deliveryAddress: [],
            billingInfo: [],
            additionalInfo: [],
        },
        appendices: {
            StorageID: '',
            StorageKey: '',
            StorageQuota: '',
            name: '',
            files: []
        },
        status: null,
        invoiceId: '',
        spinner: false,
        customerRecordSelected: null,
        currencies: [{ name: "EUR", value: "EUR" }, { name: "SEK", value: "SEK" }, { name: "NOK", value: "NOK" }, { name: "USD", value: "USD" }, { name: "GBP", value: "GBP" }, { name: "CHF", value: "CHF" }, { name: "JPY", value: "JPY" }, { name: "PLN", value: "PLN" }],
        eInvoiceAddresses: eInvoiceAddresses,
        invoiceLogo: '',
        bankAccounts: [],
        transferClause: "",
        currentRowId: 0,
        sectionDisplay: {
            billingAddress: true,
            customerAddress: false,
            deliveryAddress: false,
            billingInfo: {
                billing: true,
                address: false,
                payment: true
            },
            additionalInfo: false
        },
        deliveryType: '',
        currentInvoicePage: 1,
        taxCategories: taxCategories,
        /*     taxations: 
            {'24':{VAT: 0, withoutVAT:0, total:0}, 
            '14':{VAT: 0, withoutVAT:0, total:0}, 
            '10':{VAT: 0, withoutVAT:0, total:0}, 
            '0':{VAT: 0, withoutVAT:0, total:0}, 
            'total':0, 
            'totalVAT':0, 
            'totalWithoutVAT':0,
            'totalVATRounded:':0}, */
        taxations: taxations,
        userData: userData
    }
    return state;
}