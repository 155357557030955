<template>
  <div id="row-viewer">
    <h3>{{ $t("invoiceForm.rowInfo") }}</h3>
    <table ref="rowTable" class="table flip-table">
      <thead>
        <tr v-bind:class="{ expand: productRow.length === 0 }">
          <th
            class="row-action-cell"
            @click="editingDisabled ? '' : addRow()"
            :title="$t('invoiceForm.addRow') + ' (ctrl + enter)'"
          >
            <i class="fas fa-plus white"></i>
          </th>
          <th>{{ $t("product.code") }}</th>
          <th>{{ $t("product.name") }}</th>
          <th>{{ $t("product.ammount") }}</th>
          <th>{{ $t("product.unit") }}</th>
          <th>{{ $t("product.price") }}</th>
          <th>{{ $t("product.discount") }}</th>
          <th>{{ $t("product.VAT") }}</th>
          <th>{{ $t("product.withoutVAT") }}</th>
          <th>{{ $t("product.totalVAT") }}</th>
          <th>{{ $t("product.total") }}</th>
        </tr>
      </thead>
      <tbody class="table-rows">
        <template v-for="(row, index) in productRow">
          <row-viewer-row
            :row="row"
            v-on:update="updateRowObject(row, $event)"
            :key="index"
            :index="index"
            :windowWidth="windowWidth"
            v-on:update-comment-row="updateCommentRow(row, $event)"
          ></row-viewer-row>
          <!-- if desktop, render free text as rows see: RowViewerRow -->

          <template v-if="windowWidth > 800">
            <row-viewer-comment-row
              :row="row"
              v-on:update="updateRowObject(row, $event)"
              :key="index + '-info'"
              :index="index"
              v-if="windowWidth > 800"
              :editingDisabled="editingDisabled"
            ></row-viewer-comment-row>
            <template v-for="(freeText, textIndex) in row.additionalFreeText">
              <row-viewer-additional-free-text
                :row="row"
                v-on:update="updateCommentRow(row, $event)"
                :key="index + '-info-' + textIndex"
                :index="index"
                :textIndex="textIndex"
                :value="freeText"
                :editingDisabled="editingDisabled"
              ></row-viewer-additional-free-text>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import {
  firstLetterUpper,
  validateNumberInput,
  simpleValidation,
  validateDiscount,
  formatNumber,
  roundFiveD,
  toNumber,
  roundTwoD,
  initTaxations,
} from "../utils";
import RowViewerProductInput from "@/components/common/RowViewerProductInput";
import RowViewerRow from "@/components/RowViewerRow";
import RowViewerCommentRow from "@/components/RowViewerCommentRow";
import RowViewerAdditionalFreeText from "@/components/RowViewerAdditionalFreeText";
import { createHelpers } from "vuex-map-fields";

const { mapMultiRowFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});
export default {
  /*   mounted: function () {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "invoice/ADD_ROW" ||
        mutation.type === "invoice/COPY_ROW"
      ) {
        this.$nextTick(() => {
          this.$refs.rowTable.scrollTop = this.$refs.rowTable.scrollHeight;
        });
      }
    });
  }, */
  props: ["windowWidth"],
  data() {
    return {};
  },
  components: {
    RowViewerProductInput,
    RowViewerRow,
    RowViewerCommentRow,
    RowViewerAdditionalFreeText,
  },
  computed: {
    ...mapFields([
      "invoiceField.editingDisabled",
      "userData.entryPoint",
      "taxCategories",
      "invoiceField.billingInfo.selectedTaxCategory",
      "invoiceField.billingInfo.rounding",
      "productRow",
      "invalidFields",
    ]),
    VATBreakDown: function () {
      let taxations = initTaxations(this.selectedTaxCategory);

      let total = 0;
      let totalVAT = 0;
      let totalWithoutVAT = 0;
      let totalDiscount = 0;

      //Create breakdown by vat category e.g. (0, 10, 14, 24)
      taxations = this.productRow.reduce((taxations, row) => {
        if (!this.taxCategories[this.selectedTaxCategory].includes(row.VAT)) {
          row.VAT = 0;
        }

        //Aggregate every row
        taxations[row.VAT].VAT += toNumber(row.totalVAT);
        taxations[row.VAT].withoutVAT += toNumber(row.withoutVAT);
        return taxations;
      }, taxations);

      //Recalculate sums with invoice level discount
      if (this.wholeDiscount > 0) {
        this.taxCategories[this.selectedTaxCategory].forEach((category) => {
          let origWithoutVAT = taxations[category].withoutVAT;
          taxations[category].withoutVAT = roundFiveD(
            taxations[category].withoutVAT * (1 - this.wholeDiscount / 100)
          );

          taxations[category].discount = roundFiveD(
            origWithoutVAT - taxations[category].withoutVAT
          );

          taxations[category].VAT = roundFiveD(
            taxations[category].withoutVAT * (category / 100)
          );
        });
      }
      //Round everything
      this.taxCategories[this.selectedTaxCategory].forEach((category) => {
        taxations[category].discount = roundFiveD(taxations[category].discount);
        taxations[category].withoutVAT = roundFiveD(
          taxations[category].withoutVAT
        );
        taxations[category].VAT = roundFiveD(taxations[category].VAT);
      });

      //Round out vat specification sums and calculate totals
      this.taxCategories[this.selectedTaxCategory].forEach((category) => {
        totalWithoutVAT += taxations[category].withoutVAT;

        totalVAT += taxations[category].VAT;

        taxations[category].total = formatNumber(
          roundFiveD(taxations[category].withoutVAT + taxations[category].VAT)
        );

        totalDiscount += taxations[category].discount;

        taxations[category].VAT = formatNumber(taxations[category].VAT);
        taxations[category].withoutVAT = formatNumber(
          taxations[category].withoutVAT
        );

        taxations[category].discount = formatNumber(
          roundFiveD(taxations[category].discount)
        );
      });

      total += totalVAT + totalWithoutVAT;

      //round totals

      total = formatNumber(roundTwoD(total));
      totalVAT = formatNumber(roundFiveD(totalVAT));
      totalWithoutVAT = formatNumber(roundFiveD(totalWithoutVAT));
      totalDiscount = formatNumber(roundTwoD(totalDiscount));

      taxations = {
        ...taxations,
        total,
        totalVAT,
        totalWithoutVAT,
        totalDiscount,
      };
      return taxations;
    },
    /*     total: function () {
      return formatNumber(
        roundFiveD(
          this.productRow.reduce((acc, i) => {
            return (acc += toNumber(i.total));
          }, 0)
        )
      );
    },
    totalWithoutVAT: function () {
      return formatNumber(
        roundFiveD(
          this.productRow.reduce((acc, i) => {
            return (acc += toNumber(i.withoutVAT));
          }, 0)
        )
      );
    },
    totalVAT: function () {
      return formatNumber(
        roundFiveD(
          this.productRow.reduce((acc, i) => {
            return (acc += toNumber(i.totalVAT));
          }, 0)
        )
      );
    }, */
    wholeDiscount: function () {
      let discount = this.$store.getters["invoice/getField"](
        "invoiceField.billingInfo.discount"
      );
      return validateDiscount(discount) ? toNumber(discount) : 0;
    },
  },
  methods: {
    ...mapActions("invoice", [
      "addRow",
      "addCommentRow",
      "deleteCommentRow",
      "deleteRow",
      "copyRow",
      "updateSum",
      "resetValidation",
      "addToInvalidFields",
      "saveForm",
      "updateRow",
      "updateRowField",
    ]),
    updateRowObject: function (rowToUpdate, event) {
      let newRow = Object.assign({}, rowToUpdate);
      newRow[event.field] = event.value;
      this.updateRowField({ rowId: rowToUpdate.id, event });
    },
    updateCommentRow: function (rowToUpdate, event) {
      let freeTexts = [...rowToUpdate.additionalFreeText];
      freeTexts.splice(event.index, 1, event.value);
      this.updateRowObject(rowToUpdate, {
        field: "additionalFreeText",
        value: freeTexts,
      });
    },
  },
  watch: {
    VATBreakDown: {
      handler: function (val) {
        val.totalRounded = formatNumber(Math.round(toNumber(val.total)));
        this.$store.commit("invoice/UPDATE_SUM", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.form-control-sm {
  padding: 0 2em 0 0.5em;
}
#row-viewer {
  margin-top: 0.5em;
  margin-bottom: 20rem;
  width: 100%;
}
.table tbody + tbody {
  border-top: none;
}
.white {
  padding-top: 0.5em;
  color: #96cad4;
}
h3 {
  color: white;
  text-align: left;
}
th {
  color: #96cad4;
  padding: 0 !important;
}
.expand > th {
  min-width: 60px;
}
input {
  width: 100%;
  border-radius: 0;
}
.form-control-sm {
  height: 2.25em !important;
}
select {
  border-radius: 0;
}
.row-action-cell {
  padding: 0 1em 0 1em;
  cursor: pointer;
}
.row-action-cell-split {
  padding: 0.5em 0 0.5em 0;
  color: #96cad4;
  font-size: 16px;
  width: 50%;
  cursor: pointer;
}
.row-action-cell-split:hover {
  background-color: #5294a7;
}
.row-action-cell:hover {
  background-color: #5294a7;
}
/* thead, tbody { display: block; } */

/* th thead {
    position: sticky; top: 0;
}
tbody {
    height: 100px;       /
    overflow-y: auto;   
    overflow-x: hidden;  
display: block;
} */

@media screen and (min-width: 1441px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }
}
@media screen and (max-width: 1440px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }

  @media screen and (max-width: 1200px) {
    #row-viewer {
      margin-bottom: 2rem;
    }
  }
}
@media screen and (min-width: 801px) {
  /*   .table {
    overflow-y: auto;
    height: 400px;
    display: block;
    margin-bottom: 50px;
    padding-bottom: -50px;
  } */
  .table thead th {
    /*     position: sticky;
    top: 0; */
    background-color: #296c7e;
    /* 
    z-index: 1; */
  }
}
@media only screen and (max-width: 800px) {
  #row-viewer {
    margin-top: 0.5em;
    margin-bottom: 2rem;
  }
  .flip-table .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .flip-table * html .cf {
    zoom: 1;
  }
  .flip-table *:first-child + html .cf {
    zoom: 1;
  }

  .flip-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-table th,
  .flip-table td {
    margin: 0;
    vertical-align: top;
  }
  .flip-table th {
    text-align: left;
    min-height: 40px;
  }

  .flip-table table {
    display: block;
    position: relative;
    width: 100%;
  }
  .flip-table thead {
    display: block;
    float: left;
    width: 7em;
  }
  .flip-table tbody {
    display: block;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    width: 550px;
  }
  .flip-table thead tr {
    display: block;
    border-top: 1px solid #dee2e6;
  }
  .flip-table th {
    display: block;
    text-align: center;
    border: none;
  }
  .flip-table tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .flip-table td {
    display: block;
    min-height: 40px;
    text-align: left;
    width: 100%;
  }
  .flip-table td > input {
    height: 100%;
  }

  .row-action-cell-split {
    text-align: center;
  }
}
@media only screen and (max-width: 426px) {
  .flip-table tbody {
    width: 300px;
  }
}
@media only screen and (max-width: 376px) {
  .flip-table tbody {
    width: 190px;
  }
}
/* FLIP END */
</style>
