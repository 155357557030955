/* eslint-disable */
import moment from 'moment'
import Vue from 'vue'
import { toNumber, validateYtunnus, validateOrgNum } from '../utils'

export function createFinvoice(invoice) {
    const taxCategories = invoice.taxCategories[invoice.invoiceField.billingInfo.selectedTaxCategory]
    const currency = invoice.invoiceField.billingInfo.currency.value
    const invoiceLang = invoice.invoiceField.billingInfo.invoiceLanguage
    const minusIfCreditnote = createPrependMinus(invoice.invoiceField.billingInfo.creditNote)
    const isFakturamappen = invoice.userData.entryPoint === "fakturamappen"

    function generateFinvoiceXML(invoice) {
        let doc = window.document.implementation.createDocument(null, "Finvoice", null);
        let finvoice = doc.firstChild;
        finvoice.setAttribute("Version", "2.01");
        if (invoice.invoiceField.billingInfo.eInvoiceAddress.value) {
            finvoice.appendChild(createMessageTransmissionDetails(doc, invoice))
        }
        finvoice.appendChild(createSellerPartyDetails(doc, invoice.invoiceField.billingInfo));
        let contact = doc.createElement("SellerContactPersonName")
        contact.textContent = invoice.invoiceField.billingInfo.contactPerson
        finvoice.appendChild(contact)
        /*     if (invoice.invoiceField.billingInfo.phone || invoice.invoiceField.billingInfo.fax ||
              invoice.invoiceField.billingInfo.email || invoice.invoiceField.billingInfo.www) { */
        finvoice.appendChild(createSellerInformationDetails(doc, invoice.invoiceField.billingInfo))
        //  }

        //   if (invoice.invoiceField.billingAddress.name) {
        finvoice.appendChild(createInvoiceRecipientDetails(doc, invoice.invoiceField.billingAddress))
        /*     } else {
              finvoice.appendChild(createInvoiceRecipientDetails(doc, invoice.invoiceField.customerAddress))
            } */

        let langCode = doc.createElement("InvoiceRecipientLanguageCode")
        if (invoiceLang === 'sv' || invoiceLang === 'svfi') {
            langCode.textContent = 'SV'
            finvoice.appendChild(langCode)
        } else {
            langCode.textContent = invoiceLang.toUpperCase()
            finvoice.appendChild(langCode)
        }
        if (invoice.invoiceField.customerAddress.name) {
            finvoice.appendChild(createBuyerPartyDetails(doc, invoice.invoiceField.customerAddress));
        } else {
            finvoice.appendChild(createBuyerPartyDetails(doc, invoice.invoiceField.billingAddress));

        }

        if (invoice.invoiceField.deliveryAddress.name) {
            finvoice.appendChild(createDeliveryPartyDetails(doc, invoice.invoiceField.deliveryAddress))
        }
        if (invoice.invoiceField.additionalInfo.deliveryDay) {
            finvoice.appendChild(createDeliveryDetails(doc, invoice.invoiceField.additionalInfo))
        }
        let elements = createSpecsAndRows(doc, invoice);
        finvoice.appendChild(createInvoiceDetails(doc, invoice, elements.vats, invoice.taxations, invoice.appendices, invoice.invoiceField.additionalInfo.additionalInfo));
        finvoice.appendChild(createPaymentStatusDetails(doc));
        //finvoice.appendChild(createVirtualBankBarcode(doc, invoice));
        elements.rows.forEach((row) => {
            finvoice.appendChild(row);
        });
        finvoice.appendChild(createEpiDetails(doc, invoice.invoiceField.billingInfo, invoice.taxations));

        /*     let urlName = doc.createElement("InvoiceUrlNameText");
            urlName.textContent = 'APIX_PDFFILE';
            finvoice.appendChild(urlName);
            let urlText = doc.createElement("InvoiceUrlText");
            urlText.textContent = 'file://' + invoice.get("imageName");
            finvoice.appendChild(urlText); */

        if (invoice.invoiceLogo) {
            let logo = doc.createElement("InvoiceLogo")
            logo.textContent = invoice.invoiceLogo
            finvoice.appendChild(logo)
        }
        let xs = new XMLSerializer();
        let header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" + "<!DOCTYPE Finvoice SYSTEM \"Finvoice.dtd\">\n" + "<?xml-stylesheet type=\"text/xsl\" href=\"Finvoice.xsl\"?>\n";
        return header + xs.serializeToString(doc);
    }




    function createSellerPartyDetails(doc, invoice) {
        let details = doc.createElement("SellerPartyDetails");

        let id = doc.createElement("SellerPartyIdentifier");
        id.textContent = invoice.businessId
        details.appendChild(id);

        let name = doc.createElement("SellerOrganisationName");
        name.textContent = invoice.name
        details.appendChild(name);

        if (invoice.taxCode !== "") {
            let code = doc.createElement("SellerOrganisationTaxCode");
            code.textContent = invoice.taxCode;
            details.appendChild(code);
        }

        let officialPostAddress = doc.createElement("SellerPostalAddressDetails")
        let streetName = doc.createElement("SellerStreetName")
        streetName.textContent = invoice.address
        let townName = doc.createElement("SellerTownName")
        townName.textContent = invoice.postal
        let postCodeId = doc.createElement("SellerPostCodeIdentifier")
        postCodeId.textContent = invoice.zipCode
        let countryCode = doc.createElement("CountryCode")
        countryCode.textContent = invoice.country.value
        let countryName = doc.createElement("CountryName")
        countryName.textContent = invoice.country.name
        officialPostAddress.appendChild(streetName)
        officialPostAddress.appendChild(townName)
        officialPostAddress.appendChild(postCodeId)
        officialPostAddress.appendChild(countryCode)
        officialPostAddress.appendChild(countryName)
        details.appendChild(officialPostAddress);

        return details;
    }
    function createSellerInformationDetails(doc, invoice) {
        let details = doc.createElement("SellerInformationDetails");
        if (invoice.phone) {
            let phoneNum = doc.createElement("SellerPhoneNumber")
            phoneNum.textContent = invoice.phone
            details.appendChild(phoneNum)
        }
        if (invoice.fax) {
            let fax = doc.createElement("SellerFaxNumber")
            fax.textContent = invoice.fax
            details.appendChild(fax)
        }
        if (invoice.email) {
            let email = doc.createElement("SellerCommonEmailaddressIdentifier")
            email.textContent = invoice.email
            details.appendChild(email)
        }
        if (invoice.www) {
            let Www = doc.createElement("SellerWebaddressIdentifier")
            Www.textContent = invoice.www
            details.appendChild(Www)
        }

        let accDetails = doc.createElement("SellerAccountDetails")
        let sellerAccId = doc.createElement("SellerAccountID")
        sellerAccId.textContent = invoice.bankAccount.Value.replace(/ /g, '')

        if (invoice.paymentMethod !== "IBAN") {
            sellerAccId.setAttribute("IdentificationSchemeName", "BBAN");
        } else {
            sellerAccId.setAttribute("IdentificationSchemeName", "IBAN");
        }
        accDetails.appendChild(sellerAccId)
        let sellerBic = doc.createElement("SellerBic")
        if (invoice.BIC) {
            sellerBic.textContent = invoice.BIC
        } else {
            sellerBic.textContent = "00000000"
        }


        sellerBic.setAttribute("IdentificationSchemeName", "BIC")
        accDetails.appendChild(sellerBic)



        details.append(accDetails)

        return details;
    }
    function createBuyerPartyDetails(doc, invoice) {

        let details = doc.createElement("BuyerPartyDetails");
        let id = doc.createElement("BuyerPartyIdentifier");
        id.textContent = invoice.businessId
        details.appendChild(id);

        let name = doc.createElement("BuyerOrganisationName");
        name.textContent = invoice.name
        details.appendChild(name);
        let code = doc.createElement("BuyerOrganisationTaxCode");

        if (invoice.country.value === "FI") {
            code.textContent = "FI" + invoice.businessId.replace('-', '');
            details.appendChild(code)
        } else if (invoice.country.value === "SE") {
            code.textContent = "SE" + invoice.businessId.replace('-', '') + "01";
            details.appendChild(code)
        } else {
            code.textContent = invoice.businessId
            details.appendChild(code)
        }

        let postalDetails = doc.createElement("BuyerPostalAddressDetails")
        let streetName = doc.createElement("BuyerStreetName")
        streetName.textContent = invoice.address
        postalDetails.appendChild(streetName)
        let townName = doc.createElement("BuyerTownName")
        townName.textContent = invoice.postal
        postalDetails.appendChild(townName)
        let postcode = doc.createElement("BuyerPostCodeIdentifier")
        postcode.textContent = invoice.zipCode
        postalDetails.appendChild(postcode)
        let countryCode = doc.createElement("CountryCode")
        countryCode.textContent = invoice.country.value
        postalDetails.appendChild(countryCode)
        let countryName = doc.createElement("CountryName")
        countryName.textContent = invoice.country.name
        postalDetails.appendChild(countryName)

        details.appendChild(postalDetails)

        return details;
    }

    function createInvoiceDetails(doc, invoiceState, vats, taxations, appendices, additionalInfo) {
        let invoice = invoiceState.invoiceField.billingInfo
        let details = doc.createElement("InvoiceDetails");

        let typeCode = doc.createElement("InvoiceTypeCode");
        typeCode.textContent = invoice.creditNote ? 'INV02' : 'INV01'
        details.appendChild(typeCode)

        let typeText = doc.createElement("InvoiceTypeText");

        typeText.textContent = invoice.creditNote ?
            Vue.i18n.translateIn(invoiceLang, "invoice.titleCreditNote") :
            Vue.i18n.translateIn(invoiceLang, "invoice.title")
        details.appendChild(typeText)

        let originCode = doc.createElement("OriginCode");
        originCode.textContent = 'Original';
        details.appendChild(originCode)

        let id = doc.createElement("InvoiceNumber");
        id.textContent = invoice.invoiceNumber
        details.appendChild(id)

        let invoiceDate = doc.createElement("InvoiceDate");
        let dateString = invoice.sentDate
        let date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        invoiceDate.textContent = date;
        invoiceDate.setAttribute("Format", "CCYYMMDD");
        details.appendChild(invoiceDate)
        if (invoiceState.customerRecordSelected) {
            let sellersBuyerId = doc.createElement("SellersBuyerIdentifier")
            sellersBuyerId.textContent = invoiceState.customerRecordSelected.customerNumber
            details.appendChild(sellersBuyerId)
        }


        let orderId = doc.createElement("OrderIdentifier")
        orderId.textContent = invoice.orderReference
        details.appendChild(orderId)

        let vatExcluded = doc.createElement("InvoiceTotalVatExcludedAmount");
        vatExcluded.setAttribute("AmountCurrencyIdentifier", currency);
        vatExcluded.textContent = minusIfCreditnote(taxations['totalWithoutVAT'])
        details.appendChild(vatExcluded)

        let vatTotal = doc.createElement("InvoiceTotalVatAmount");
        vatTotal.textContent = minusIfCreditnote(taxations['totalVAT'])
        vatTotal.setAttribute("AmountCurrencyIdentifier", currency)
        details.appendChild(vatTotal)

        let vatIncluded = doc.createElement("InvoiceTotalVatIncludedAmount");
        vatIncluded.textContent = /* invoice.invoiceField.billingInfo.rounding ? invoice.taxations['totalRounded'] : */ minusIfCreditnote(taxations['total'])
        vatIncluded.setAttribute("AmountCurrencyIdentifier", currency)
        details.appendChild(vatIncluded)

        if (invoice.rounding) {
            let roundOff = doc.createElement("InvoiceTotalRoundoffAmount");
            let roundedSum = printDecimalFive(toNumber(taxations['totalRounded']) - toNumber(taxations['total']))
            if (invoice.creditNote) {
                if (roundedSum.includes("-")) {
                    roundedSum = roundedSum.replace("-", "")
                } else if (Number(roundedSum.replace(",", ".")) > 0) {
                    roundedSum = "-" + roundedSum
                }
            }
            roundOff.textContent = roundedSum
            roundOff.setAttribute("AmountCurrencyIdentifier", currency)
            details.appendChild(roundOff)
        }
        vats.forEach(vat => details.appendChild(vat))
        //details.appendChild(vats)

        let invoiceFreeText = doc.createElement("InvoiceFreeText")
        invoiceFreeText.textContent = additionalInfo
        details.appendChild(invoiceFreeText)

        if (invoiceState.userData.laskumappiSkin && invoiceState.userData.laskumappiSkin === "financelink") {
            let appendicesEle = doc.createElement("InvoiceFreeText")
            appendicesEle.textContent = invoiceState.transferClause;
            details.appendChild(appendicesEle)
        }

        if (appendices.files.length > 0) {
            let appendicesEle = doc.createElement("InvoiceFreeText")
            appendicesEle.textContent = "APIX_X:" + appendices.StorageID + "+" + appendices.StorageKey
            details.appendChild(appendicesEle)
        }

        if (invoice.reverseVAT && !isFakturamappen) {
            let vatFreeText = doc.createElement("InvoiceVatFreeText")
            vatFreeText.textContent = invoiceState.invoiceField.additionalInfo.vatFreeText
            details.appendChild(vatFreeText)
        }

        let termsDetails = doc.createElement("PaymentTermsDetails");
        let freeText = doc.createElement("PaymentTermsFreeText");
        freeText.textContent = invoice.paymentCondition + " " + Vue.i18n.translateIn(invoiceLang, "invoiceForm.daysShort")
        termsDetails.appendChild(freeText);

        let dueDate = doc.createElement("InvoiceDueDate");
        dateString = invoice.dueDate
        date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        dueDate.textContent = date
        dueDate.setAttribute("Format", "CCYYMMDD");
        termsDetails.appendChild(freeText);
        termsDetails.appendChild(dueDate);

        let overdue = doc.createElement("PaymentOverDueFineDetails")
        let perc = doc.createElement("PaymentOverDueFinePercent")
        perc.textContent = invoice.penaltyInterest
        overdue.appendChild(perc)
        termsDetails.appendChild(overdue)

        details.appendChild(termsDetails)

        if (toNumber(invoice.discount) > 0) {
            let discount = doc.createElement("DiscountDetails")
            let percent = doc.createElement("Percent")
            percent.textContent = invoice.discount
            discount.appendChild(percent)
            details.appendChild(discount)
        }

        return details;
    }

    function createPaymentStatusDetails(doc) {
        let details = doc.createElement("PaymentStatusDetails");
        let paymentCode = doc.createElement("PaymentStatusCode");
        paymentCode.textContent = 'NOTPAID';
        details.appendChild(paymentCode);
        return details;
    }

    function createVirtualBankBarcode(doc, invoice) {
        let code = doc.createElement("VirtualBankBarcode");
        code.textContent = invoice.generateBarcode(); /*<----------------------------------------------------------- */
        return code;
    }

    function createSpecsAndRows(doc, invoice) {
        let vats = [];
        let rows = [];
        let itemNumber = 1;
        rows = invoice.productRow.map(row => {
            return createInvoiceRow(doc, invoice, row)
        })

        let usedTaxCategories = invoice.productRow.reduce((acc, curr) => {
            if (!acc.includes(curr.VAT)) {
                acc.push(curr.VAT)
            }
            return acc
        }, [])

        taxCategories.forEach((entry) => {
            if (usedTaxCategories.includes(entry)) {
                vats.push(createVatSpec(doc, invoice, entry));
            }
        });
        return {
            vats, rows
        };
    }
    function insertDash(str, n) {
        var ret = [];
        var i;
        var len;

        for (i = 0, len = str.length; i < len; i += n) {
            ret.push(str.substr(i, n))
        }

        return ret
    };
    function createVatSpec(doc, invoice, entry) {
        let details = doc.createElement("VatSpecificationDetails");
        let baseAmount = doc.createElement("VatBaseAmount");
        baseAmount.setAttribute("AmountCurrencyIdentifier", currency)
        baseAmount.textContent = minusIfCreditnote(invoice.taxations[entry].withoutVAT)
        let rate = doc.createElement("VatRatePercent");
        rate.textContent = printDecimal(entry);

        let rateAmount = doc.createElement("VatRateAmount");
        rateAmount.setAttribute("AmountCurrencyIdentifier", currency);
        rateAmount.textContent = minusIfCreditnote(invoice.taxations[entry].VAT)
        details.appendChild(baseAmount)
        details.appendChild(rate)
        if (invoice.invoiceField.billingInfo.reverseVAT && !isFakturamappen) {
            let code = doc.createElement("VatCode")
            code.textContent = "AE"
            details.appendChild(code)
        }

        details.appendChild(rateAmount)
        if (invoice.invoiceField.billingInfo.reverseVAT && isFakturamappen && entry === 0) {
            let vatFreeText = doc.createElement("VatFreeText")
            vatFreeText.textContent = invoice.invoiceField.additionalInfo.vatFreeText
            details.appendChild(vatFreeText)
        }
        return details;
    }

    function createInvoiceRow(doc, invoice, row) {

        let invoiceRow = doc.createElement("InvoiceRow");
        let id = doc.createElement("ArticleIdentifier");
        id.textContent = row.code

        invoiceRow.appendChild(id)

        let name = doc.createElement("ArticleName");
        name.textContent = row.name

        invoiceRow.appendChild(name)

        let quantity = doc.createElement("DeliveredQuantity");
        quantity.textContent = isFakturamappen ? minusIfCreditnote(row.ammount) : row.ammount
        quantity.setAttribute("QuantityUnitCode", Vue.i18n.translateIn(invoiceLang, "units." + row.unit));

        invoiceRow.appendChild(quantity)

        let unitPrice = doc.createElement("UnitPriceAmount");
        unitPrice.textContent = isFakturamappen ? row.price : minusIfCreditnote(row.price)
        unitPrice.setAttribute("AmountCurrencyIdentifier", currency)
        unitPrice.setAttribute("UnitPriceUnitCode", currency + " " + Vue.i18n.translateIn(invoiceLang, "units." + row.unit));

        invoiceRow.appendChild(unitPrice)

        let freeText = doc.createElement("RowFreeText")
        freeText.textContent = row.info
        invoiceRow.appendChild(freeText)

        if (row.additionalFreeText) {
            row.additionalFreeText.forEach(aft => {
                freeText = doc.createElement("RowFreeText")
                freeText.textContent = aft
                invoiceRow.appendChild(freeText)
            })
        }

        if (toNumber(row.discount) > 0) {
            let rowDiscount = doc.createElement("RowDiscountPercent")
            rowDiscount.textContent = row.discount
            invoiceRow.appendChild(rowDiscount)
        }


        let rate = doc.createElement("RowVatRatePercent");
        rate.textContent = printDecimal(row.VAT);
        invoiceRow.appendChild(rate)

        let vatAmount = doc.createElement("RowVatAmount");
        vatAmount.textContent = minusIfCreditnote(row.totalVAT)
        vatAmount.setAttribute("AmountCurrencyIdentifier", currency)
        invoiceRow.appendChild(vatAmount)

        let withoutVAT = doc.createElement("RowVatExcludedAmount")
        withoutVAT.setAttribute("AmountCurrencyIdentifier", currency)
        withoutVAT.textContent = minusIfCreditnote(row.withoutVAT)
        invoiceRow.appendChild(withoutVAT)

        let rowTotal = doc.createElement("RowAmount");
        rowTotal.textContent = minusIfCreditnote(row.total)
        rowTotal.setAttribute("AmountCurrencyIdentifier", currency);
        invoiceRow.appendChild(rowTotal)

        return invoiceRow;
    }
    function createPrependMinus(isCreditNote) {
        if (isCreditNote) {
            return (str) => Number(str.replace(",", ".")) > 0 ? "-" + str : str
        } else {
            return (str) => str
        }
    }

    function createEpiDetails(doc, invoice, taxations) {
        let epi = doc.createElement("EpiDetails");

        let id = doc.createElement("EpiIdentificationDetails");
        let epiDate = doc.createElement("EpiDate");
        let dateString = invoice.sentDate
        let date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        epiDate.textContent = date;
        epiDate.setAttribute("Format", "CCYYMMDD");
        let epiRef = doc.createElement("EpiReference");
        epiRef.textContent = invoice.bankReference
        id.appendChild(epiDate);
        id.appendChild(epiRef);

        let epiParty = doc.createElement("EpiPartyDetails");
        // First epiParty child
        let bfi = doc.createElement("EpiBfiPartyDetails");
        if (invoice.BIC) {
            let bfiId = doc.createElement("EpiBfiIdentifier");
            bfiId.textContent = invoice.BIC
            bfiId.setAttribute("IdentificationSchemeName", "BIC");
            bfi.appendChild(bfiId);
        }
        let epiBfiname = doc.createElement("EpiBfiName")
        epiBfiname.textContent = invoice.name.substring(0, 35)
        bfi.appendChild(epiBfiname)

        // 2nd epiParty child
        let beneficiary = doc.createElement("EpiBeneficiaryPartyDetails");
        let epiName = doc.createElement("EpiNameAddressDetails");
        epiName.textContent = invoice.name
        beneficiary.appendChild(epiName);

        if (invoice.businessId.length < 12) {
            let bei = doc.createElement("EpiBei");
            bei.textContent = invoice.businessId
            beneficiary.appendChild(bei);
        }



        let account = doc.createElement("EpiAccountID");
        account.textContent = invoice.bankAccount.Value.replace(/ /g, '')
        if (invoice.paymentMethod !== "IBAN") {
            account.setAttribute("IdentificationSchemeName", "BBAN");
        } else {
            account.setAttribute("IdentificationSchemeName", "IBAN");
        }
        beneficiary.appendChild(account);

        epiParty.appendChild(bfi);
        epiParty.appendChild(beneficiary);

        let payment = doc.createElement("EpiPaymentInstructionDetails");
        let remittance = doc.createElement("EpiRemittanceInfoIdentifier");
        remittance.textContent = invoice.bankReference
        remittance.setAttribute("IdentificationSchemeName", "SPY");
        let amount = doc.createElement("EpiInstructedAmount");
        amount.textContent = minusIfCreditnote(invoice.rounding ? taxations['totalRounded'] : taxations['total'])
        amount.setAttribute("AmountCurrencyIdentifier", currency);
        let charge = doc.createElement("EpiCharge");
        charge.textContent = 'SHA';
        charge.setAttribute("ChargeOption", "SHA");
        let option = doc.createElement("EpiDateOptionDate");

        dateString = invoice.dueDate
        date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        option.textContent = date
        option.setAttribute("Format", "CCYYMMDD");
        [remittance, amount, charge, option].forEach((el) => {
            payment.appendChild(el);
        });

        [id, epiParty, payment].forEach((el) => {
            epi.appendChild(el);
        });
        return epi;
    }

    function printDecimal(number) {
        if (typeof number === 'string') {
            number = parseFloat(number);
        }
        return number.toLocaleString("fi-FI", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    function printDecimalFive(number) {
        if (typeof number === 'string') {
            number = parseFloat(number);
        }
        return number.toLocaleString("fi-FI", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 5
        });
    }
    function createDeliveryDetails(doc, invoice) {
        let delivery = doc.createElement("DeliveryDetails")
        let deliveryDate = doc.createElement("DeliveryDate")

        let dateString = invoice.deliveryDay
        let date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        deliveryDate.textContent = date
        deliveryDate.setAttribute("Format", "CCYYMMDD");
        let method = doc.createElement("DeliveryMethodText")
        method.textContent = invoice.deliveryMethod
        delivery.appendChild(deliveryDate)
        delivery.appendChild(method)

        return delivery
    }

    function createDeliveryPartyDetails(doc, details) {
        let delivery = doc.createElement("DeliveryPartyDetails")
        let name = doc.createElement("DeliveryOrganisationName")
        name.textContent = details.name.substring(0, 35)
        delivery.appendChild(name)

        let address = doc.createElement("DeliveryPostalAddressDetails")
        let street = doc.createElement("DeliveryStreetName")
        street.textContent = details.address
        address.appendChild(street)
        let town = doc.createElement("DeliveryTownName")
        town.textContent = details.postal
        address.appendChild(town)
        let postCode = doc.createElement("DeliveryPostCodeIdentifier")
        postCode.textContent = details.zipCode
        address.appendChild(postCode)

        let countryCode = doc.createElement("CountryCode")
        countryCode.textContent = details.country.value
        address.appendChild(countryCode)

        let country = doc.createElement("CountryName")
        country.textContent = details.country.name
        address.appendChild(country)

        delivery.appendChild(address)

        return delivery

    }
    function createInvoiceRecipientDetails(doc, details) {
        let recipient = doc.createElement("InvoiceRecipientPartyDetails")
        let id = doc.createElement("InvoiceRecipientPartyIdentifier")
        id.textContent = details.businessId
        recipient.appendChild(id)

        let name = doc.createElement("InvoiceRecipientOrganisationName")
        let name2 = doc.createElement("InvoiceRecipientOrganisationName")
        if (details.name.length > 35) {
            name.textContent = details.name.substring(0, 35)
            recipient.appendChild(name)
            name2.textContent = details.name.substring(35, 70)
            recipient.appendChild(name2)
        } else {
            name.textContent = details.name.substring(0, 35)
            recipient.appendChild(name)
        }

        let address = doc.createElement("InvoiceRecipientPostalAddressDetails")
        let street = doc.createElement("InvoiceRecipientStreetName")
        street.textContent = details.address
        address.appendChild(street)

        let town = doc.createElement("InvoiceRecipientTownName")
        town.textContent = details.postal
        address.appendChild(town)

        let postCode = doc.createElement("InvoiceRecipientPostCodeIdentifier")
        postCode.textContent = details.zipCode
        address.appendChild(postCode)

        let countryCode = doc.createElement("CountryCode")
        countryCode.textContent = details.country.value
        address.appendChild(countryCode)

        let country = doc.createElement("CountryName")
        country.textContent = details.country.name
        address.appendChild(country)

        recipient.appendChild(address)

        return recipient
    }

    function createMessageTransmissionDetails(doc, invoice) {
        let details = doc.createElement("MessageTransmissionDetails")
        let senderDetails = doc.createElement("MessageSenderDetails")
        let fromId = doc.createElement("FromIdentifier")
        let fromInter = doc.createElement("FromIntermediator")

        //billinginfo = sender einvoiceaddress

        fromId.textContent = invoice.userData.sendereInvoiceAddress ? invoice.userData.sendereInvoiceAddress : invoice.invoiceField.billingInfo.sendereInvoiceAddress //ovt
        fromInter.textContent = "003723327487"
        senderDetails.appendChild(fromId)
        senderDetails.appendChild(fromInter)

        let receiverDetails = doc.createElement("MessageReceiverDetails")
        let toId = doc.createElement("ToIdentifier") //TULOSTUSPALVELU
        let toInter = doc.createElement("ToIntermediator") //2-


        //customer einvoiceaddress

        toId.textContent = invoice.invoiceField.billingInfo.eInvoiceAddress.value
        toInter.textContent = "2-"

        receiverDetails.appendChild(toId)
        receiverDetails.appendChild(toInter)

        let messageDetails = doc.createElement("MessageDetails")
        let stamp = doc.createElement("MessageTimeStamp")
        stamp.textContent = moment().format("hh:mm:ss DD.MM.YYYY")
        let msgId = doc.createElement("MessageIdentifier")
        msgId.textContent = "InvoiceNumber" + invoice.invoiceField.billingInfo.invoiceNumber
        messageDetails.appendChild(msgId)
        messageDetails.appendChild(stamp)
        details.appendChild(senderDetails)
        details.appendChild(receiverDetails)
        details.appendChild(messageDetails)

        return details
    }
    return generateFinvoiceXML(invoice)
}

export function createPurchaseFinvoice(invoice, locale) {

    const taxCategories = invoice.taxCategories
    const currency = invoice.currency.value
    const invoiceLang = locale
    console.log(invoiceLang)

    return generateFinvoiceXML(invoice, invoice.taxations)

    function generateFinvoiceXML(invoice, taxations) {
        let doc = window.document.implementation.createDocument(null, "Finvoice", null);
        let finvoice = doc.firstChild;
        finvoice.setAttribute("Version", "2.01");

        finvoice.appendChild(createSellerPartyDetails(doc, invoice));
        finvoice.appendChild(createSellerInformationDetails(doc, invoice))
        finvoice.appendChild(createBuyerPartyDetails(doc, invoice));

        let elements = createSpecsAndRows(doc, taxations, invoice, invoice.categories);
        finvoice.appendChild(createInvoiceDetails(doc, invoice, elements.vats, taxations));
        finvoice.appendChild(createPaymentStatusDetails(doc));
        if (invoice.totals.total > 0) {
            finvoice.appendChild(createVirtualBankBarcode(doc, invoice));
        }
        elements.invoiceRows.forEach((row) => {
            finvoice.appendChild(row);
        });
        finvoice.appendChild(createEpiDetails(doc, invoice, taxations));

        let urlName = doc.createElement("InvoiceUrlNameText");
        urlName.textContent = 'APIX_PDFFILE';
        finvoice.appendChild(urlName);
        let urlText = doc.createElement("InvoiceUrlText");
        urlText.textContent = 'file://invoice.pdf';
        finvoice.appendChild(urlText)

        let xs = new XMLSerializer();
        let header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" + "<!DOCTYPE Finvoice SYSTEM \"Finvoice.dtd\">\n" + "<?xml-stylesheet type=\"text/xsl\" href=\"Finvoice.xsl\"?>\n";
        return header + xs.serializeToString(doc);
    }

    function createSellerPartyDetails(doc, invoice) {
        let details = doc.createElement("SellerPartyDetails");

        let id = doc.createElement("SellerPartyIdentifier");
        id.textContent = invoice.senderBusinessId
        details.appendChild(id);

        let name = doc.createElement("SellerOrganisationName");
        name.textContent = invoice.senderName
        details.appendChild(name);

        let code = doc.createElement("SellerOrganisationTaxCode");
        if (invoice.country === 'FI' && invoice.senderBusinessId.substring(0, 3) !== "999") {
            code.textContent = "FI" + invoice.senderBusinessId.replace('-', '');
        } else if (invoice.country === 'SE') {
            code.textContent = "SE" + invoice.senderBusinessId.replace('-', '') + "01";
        } else {
            code.textContent = invoice.senderBusinessId
        }
        details.appendChild(code);

        return details
    }

    //sllerinformation
    function createVirtualBankBarcode(doc, invoice) {

        const generateBarcode = function (inv) {
            let result = '4';
            let account = inv.bankAccount;
            if (!account) {
                return '';
            }
            let pad = "0000000000000000"; //16
            account = account.substring(2).replace(/ /g, '');
            let temp = (pad + account).slice(-pad.length);
            result += temp;
            pad = "00000000"; //8
            let amountToPay = printDecimal(inv.totals.total).replace(',', '');
            temp = (pad + amountToPay).slice(-pad.length);
            result += temp;
            result += '000';
            pad = "00000000000000000000"; //20
            let ref = inv.bankReference.replace(/ /g, '');
            let re = /\D/;
            if (re.test(ref)) {
                return '';
            }
            temp = (pad + ref).slice(-pad.length);
            result += temp;
            let date = inv.dueDate.replace(/-/g, '').substring(2);
            result += date;
            return result;
        }

        const generateBarcodeRF = function (inv) {
            let result = '5';
            let account = inv.bankAccount;
            if (!account) {
                return '';
            }
            let pad = "0000000000000000"; //16
            account = account.substring(2).replace(/ /g, '');
            let temp = (pad + account).slice(-pad.length);
            result += temp;
            pad = "00000000"; //8
            let amountToPay = printDecimal(inv.totals.total).replace(',', '');
            temp = (pad + amountToPay).slice(-pad.length);
            result += temp;
            //result += '000';
            pad = "00000000000000000000000"; //23
            let ref = inv.bankReference.replace(/ /g, '');
            ref = ref.substr(2, ref.length)
            let re = /\D/;
            if (re.test(ref)) {
                return '';
            }
            let checksum = ref.substr(0, 2)
            ref = ref.substr(2, ref.length)
            pad = pad.substr(0, pad.length - (checksum.length + ref.length))
            temp = checksum + pad + ref
            result += temp;
            let date = inv.dueDate.replace(/-/g, '').substring(2);
            result += date;
            return result;
        }


        let barcode = ""
        if (invoice.bankReference.substr(0, 2) === "RF") {
            barcode = generateBarcodeRF(invoice)
        } else {
            barcode = generateBarcode(invoice)
        }

        let code = doc.createElement("VirtualBankBarcode");
        code.textContent = barcode
        return code;
    }
    function createSellerInformationDetails(doc, invoice) {
        let details = doc.createElement("SellerInformationDetails");

        let accDetails = doc.createElement("SellerAccountDetails")
        let sellerAccId = doc.createElement("SellerAccountID")
        sellerAccId.textContent = invoice.bankAccount.replace(/ /g, '')

        if (invoice.paymentMethod !== "IBAN") {
            sellerAccId.setAttribute("IdentificationSchemeName", "BBAN");
        } else {
            sellerAccId.setAttribute("IdentificationSchemeName", "IBAN");
        }
        accDetails.appendChild(sellerAccId)

        let sellerBic = doc.createElement("SellerBic")
        if (invoice.BIC) {
            sellerBic.textContent = invoice.BIC
        } else {
            sellerBic.textContent = "00000000"
        }

        sellerBic.setAttribute("IdentificationSchemeName", "BIC")
        accDetails.appendChild(sellerBic)

        details.append(accDetails)

        return details;
    }
    function createBuyerPartyDetails(doc, invoice) {

        let details = doc.createElement("BuyerPartyDetails");
        let id = doc.createElement("BuyerPartyIdentifier");
        id.textContent = invoice.businessId
        details.appendChild(id);

        let name = doc.createElement("BuyerOrganisationName");
        name.textContent = invoice.name
        details.appendChild(name);
        return details
    }

    function createInvoiceDetails(doc, invoice, vats, taxations) {
        let details = doc.createElement("InvoiceDetails");

        let typeCode = doc.createElement("InvoiceTypeCode");
        typeCode.textContent = 'INV01'
        details.appendChild(typeCode)

        let typeText = doc.createElement("InvoiceTypeText");
        typeText.textContent = Vue.i18n.translateIn(invoiceLang, "invoice.title")
        details.appendChild(typeText)

        let originCode = doc.createElement("OriginCode");
        originCode.textContent = 'Original';
        details.appendChild(originCode)

        let id = doc.createElement("InvoiceNumber");
        id.textContent = invoice.invoiceNumber
        details.appendChild(id)

        let invoiceDate = doc.createElement("InvoiceDate");
        let dateString = invoice.sentDate
        let date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        invoiceDate.textContent = date;
        invoiceDate.setAttribute("Format", "CCYYMMDD");
        details.appendChild(invoiceDate)
        let vatExcluded = doc.createElement("InvoiceTotalVatExcludedAmount");
        vatExcluded.setAttribute("AmountCurrencyIdentifier", currency);
        vatExcluded.textContent = printDecimal(invoice.totals.totalWithoutVAT)
        details.appendChild(vatExcluded)

        let vatTotal = doc.createElement("InvoiceTotalVatAmount");
        vatTotal.textContent = printDecimal(invoice.totals.totalVAT)
        vatTotal.setAttribute("AmountCurrencyIdentifier", currency)
        details.appendChild(vatTotal)

        let vatIncluded = doc.createElement("InvoiceTotalVatIncludedAmount");
        vatIncluded.textContent = printDecimal(invoice.totals.total)
        vatIncluded.setAttribute("AmountCurrencyIdentifier", currency)
        details.appendChild(vatIncluded)

        vats.forEach(vat => details.appendChild(vat))

        let termsDetails = doc.createElement("PaymentTermsDetails");
        let freeText = doc.createElement("PaymentTermsFreeText");
        freeText.textContent = Vue.i18n.translateIn(invoiceLang, "invoice.dueDate")
        termsDetails.appendChild(freeText);

        let dueDate = doc.createElement("InvoiceDueDate");
        dateString = invoice.dueDate
        date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        dueDate.textContent = date
        dueDate.setAttribute("Format", "CCYYMMDD");
        termsDetails.appendChild(freeText);
        termsDetails.appendChild(dueDate);
        details.appendChild(termsDetails)

        return details
    }

    function createPaymentStatusDetails(doc) {
        let details = doc.createElement("PaymentStatusDetails");
        let paymentCode = doc.createElement("PaymentStatusCode");
        paymentCode.textContent = 'NOTPAID';
        details.appendChild(paymentCode);
        return details;
    }

    function createSpecsAndRows(doc, taxations, invoice, taxCategories) {
        let vats = [];
        let itemNumber = 1;
        let invoiceRows = []
        console.log(taxations)
        for (let i = 0; i < taxations.length; i++) {
            if (taxations[i].total !== 0) {
                invoiceRows.push(createInvoiceRow(doc, taxations[i], itemNumber))
                itemNumber++
                vats.push(createVatSpec(doc, taxations[i]));
            }


        }
        return {
            vats, invoiceRows
        };
    }
    function createVatSpec(doc, row) {
        let details = doc.createElement("VatSpecificationDetails");
        let baseAmount = doc.createElement("VatBaseAmount");
        baseAmount.setAttribute("AmountCurrencyIdentifier", currency)
        baseAmount.textContent = printDecimal(row.totalWithoutVAT)
        let rate = doc.createElement("VatRatePercent");
        rate.textContent = printDecimal(row.VAT);
        let rateAmount = doc.createElement("VatRateAmount");
        rateAmount.setAttribute("AmountCurrencyIdentifier", currency);
        rateAmount.textContent = printDecimal(row.totalVAT)
        details.appendChild(baseAmount)
        details.appendChild(rate)
        details.appendChild(rateAmount)
        return details;
    }
    function createInvoiceRow(doc, row, index) {
        let invoiceRow = doc.createElement("InvoiceRow");
        let id = doc.createElement("ArticleIdentifier");
        id.textContent = index

        invoiceRow.appendChild(id)

        let name = doc.createElement("ArticleName");
        name.textContent = Vue.i18n.translateIn(invoiceLang, "purchaseInvoice.products") + " (" + printDecimal(row.VAT) + " %)"

        invoiceRow.appendChild(name)

        let quantity = doc.createElement("DeliveredQuantity");
        quantity.textContent = "1,00"
        quantity.setAttribute("QuantityUnitCode", Vue.i18n.translateIn(invoiceLang, "purchaseInvoice.pce"));

        invoiceRow.appendChild(quantity)

        let unitPrice = doc.createElement("UnitPriceAmount");
        unitPrice.textContent = printDecimal(row.totalWithoutVAT)
        unitPrice.setAttribute("AmountCurrencyIdentifier", currency)
        unitPrice.setAttribute("UnitPriceUnitCode", Vue.i18n.translateIn(invoiceLang, "purchaseInvoice.pce"));

        invoiceRow.appendChild(unitPrice)


        let rate = doc.createElement("RowVatRatePercent");
        rate.textContent = printDecimal(row.VAT);
        invoiceRow.appendChild(rate)

        let vatAmount = doc.createElement("RowVatAmount");
        vatAmount.textContent = printDecimal(row.totalVAT)
        vatAmount.setAttribute("AmountCurrencyIdentifier", currency)
        invoiceRow.appendChild(vatAmount)

        let rowTotal = doc.createElement("RowAmount");
        rowTotal.textContent = printDecimal(row.total)
        rowTotal.setAttribute("AmountCurrencyIdentifier", currency);
        invoiceRow.appendChild(rowTotal)

        return invoiceRow;
    }

    function createEpiDetails(doc, invoice, taxations) {
        let epi = doc.createElement("EpiDetails");

        let id = doc.createElement("EpiIdentificationDetails");
        let epiDate = doc.createElement("EpiDate");
        let dateString = invoice.sentDate
        let date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        epiDate.textContent = date;
        epiDate.setAttribute("Format", "CCYYMMDD");
        let epiRef = doc.createElement("EpiReference");
        epiRef.textContent = invoice.bankReference
        id.appendChild(epiDate);
        id.appendChild(epiRef);

        let epiParty = doc.createElement("EpiPartyDetails");
        // First epiParty child
        let bfi = doc.createElement("EpiBfiPartyDetails");
        if (invoice.BIC) {
            let bfiId = doc.createElement("EpiBfiIdentifier");
            bfiId.textContent = invoice.BIC
            bfiId.setAttribute("IdentificationSchemeName", "BIC");
            bfi.appendChild(bfiId);
        }

        let epiBfiname = doc.createElement("EpiBfiName")
        epiBfiname.textContent = invoice.name.substring(0, 35)
        bfi.appendChild(epiBfiname)

        // 2nd epiParty child
        let beneficiary = doc.createElement("EpiBeneficiaryPartyDetails");
        let epiName = doc.createElement("EpiNameAddressDetails");
        epiName.textContent = invoice.senderName
        let bei = doc.createElement("EpiBei");
        bei.textContent = invoice.senderBusinessId
        let account = doc.createElement("EpiAccountID");
        account.textContent = invoice.bankAccount.replace(/ /g, '')
        if (invoice.paymentMethod !== "IBAN") {
            account.setAttribute("IdentificationSchemeName", "BBAN");
        } else {
            account.setAttribute("IdentificationSchemeName", "IBAN");
        }

        beneficiary.appendChild(epiName);
        beneficiary.appendChild(bei);
        beneficiary.appendChild(account);
        epiParty.appendChild(bfi);
        epiParty.appendChild(beneficiary);

        let payment = doc.createElement("EpiPaymentInstructionDetails");
        let remittance = doc.createElement("EpiRemittanceInfoIdentifier");
        remittance.textContent = invoice.bankReference
        remittance.setAttribute("IdentificationSchemeName", "SPY");
        let amount = doc.createElement("EpiInstructedAmount");
        amount.textContent = printDecimal(invoice.totals.total)
        amount.setAttribute("AmountCurrencyIdentifier", currency);
        let charge = doc.createElement("EpiCharge");
        charge.textContent = 'SHA';
        charge.setAttribute("ChargeOption", "SHA");
        let option = doc.createElement("EpiDateOptionDate");

        dateString = invoice.dueDate
        date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
        option.textContent = date
        option.setAttribute("Format", "CCYYMMDD");
        [remittance, amount, charge, option].forEach((el) => {
            payment.appendChild(el);
        });

        [id, epiParty, payment].forEach((el) => {
            epi.appendChild(el);
        });
        return epi;
    }
    function printDecimal(number) {
        if (typeof number === 'string') {
            number = parseFloat(number);
        }
        return number.toLocaleString("fi-FI", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

}

