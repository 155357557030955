<template>
  <div v-on:click="$emit('close')" v-if="isOpen" id="myModal" class="modal">
    <div v-on:click.stop class="modal-content">
      <span>
        <i v-on:click.stop="$emit('close')" class="fa fa-times close" aria-hidden="true"></i>
      </span>
      <div class="row">
        <div class="col">
          <div class="row">
            <h4
              class="tab"
              :class="{'open-tab':firstTab}"
              @click="firstTab = true"
            >{{ $t("invoiceRecord.incomplete") }}</h4>
            <h4
              class="tab"
              :class="{'open-tab':!firstTab}"
              @click="firstTab = false"
            >{{ $t("invoiceRecord.sent") }}</h4>
          </div>

          <ul v-if="firstTab" class="invoice-list">
            <li
              @click="selectedInvoice = i.invoiceId"
              v-bind:class="{'selected-item':selectedInvoice===i.invoiceId}"
              class="invoice-list-item"
              v-for="(i, key) in open"
              :key="key"
              v-on:dblclick="edit(false)"
            >
              <div v-if="currentInvoice===i.invoiceId" class="red-dot"></div>
              <div class="invoice-id">{{i | identifier}}</div>
              <div class="invoice-updated">{{i.updatedAt | date}}</div>
            </li>
            <h3 v-if="open.length===0" class="no-invoices">{{ $t("invoiceRecord.noInvoices") }}</h3>
          </ul>

          <ul v-else class="invoice-list">
            <li
              @click="selectedInvoice = s.invoiceId"
              v-bind:class="{'selected-item':selectedInvoice===s.invoiceId}"
              class="invoice-list-item"
              v-for="(s, key) in sent"
              :key="key"
              v-on:dblclick="edit(true)"
            >
              <div class="invoice-id">{{s | identifier }}</div>
              <div>{{s.updatedAt | date}}</div>
            </li>
            <h3 v-if="sent.length===0" class="no-invoices">{{ $t("invoiceRecord.noInvoices") }}</h3>
          </ul>
        </div>
      </div>
      <div class="row mt-2">
        <div style="margin:auto">
          <div class="buttons row" v-if="selectedInvoice">
            <button
              :disabled="!firstTab"
              @click="edit(false)"
              class="btn btn-secondary"
            >{{ $t("invoiceRecord.continue") }}</button>
            <button
              :disabled="!firstTab"
              @click="deleteAction"
              class="btn btn-warning"
            >{{ $t("invoiceRecord.delete") }}</button>
            <button
              @click="edit(true)"
              class="btn btn-secondary"
            >{{ $t("invoiceRecord.useAsTemplate") }}</button>
          </div>
          <div v-else style="margin:auto">
            <h3>{{ $t("invoiceRecord.choose")}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { formatDate, nullToEmptyObj } from "@/utils";
import { mapActions } from "vuex";
import moment from "moment";
const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  props: ["isOpen"],
  data() {
    return {
      firstTab: true,
      selectedInvoice: null,
      sent: [],
      open: []
    };
  },
  methods: {
    edit: function(isNew) {
      this.$store.dispatch("invoice/disableEditing", true);
      this.$store.dispatch("invoice/spin", true);
      this.$store.dispatch("invoice/selectInvoice", {
        id: this.selectedInvoice,
        isNew
      });
      this.$emit("close");
    },
    deleteAction: function() {
      let id = this.selectedInvoice;
      if (id === this.currentInvoice) {
        this.$store.dispatch("invoice/newInvoice");
        this.$store.dispatch("invoice/fetchPrefillCustomerData", null);
        this.$emit("close");
      }
      this.$store
        .dispatch("invoice/deleteInvoice", id)
        .then(response => {
          if (response.success) {
            this.selectedInvoice = null;

            let pos = this.open.map(i => i.invoiceId).indexOf(id);

            this.open = this.open.filter(i => i.invoiceId !== id);

            if (this.open.length === 0) {
              this.selectedInvoice = null;
            } else if (pos === this.open.length) {
              this.selectedInvoice = this.open[pos - 1].invoiceId;
            } else {
              this.selectedInvoice = this.open[pos].invoiceId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  filters: {
    date: function(val) {
      let pieces = val.split(" ");
      return pieces[1] + " " + formatDate(pieces[0]);
    },
    identifier: function(val) {
      if (val.name.length > 0) {
        return val.name + " " + val.invoiceNumber;
      } else if (val.invoiceNumber) {
        return val.invoiceNumber;
      } else {
        return val.invoiceId;
      }
    }
  },
  computed: {
    currentInvoice: function() {
      return this.$store.getters["invoice/getField"]("invoiceId");
    }
  },

  watch: {
    firstTab: function(val) {
      if (val) {
        this.selectedInvoice =
          this.open.length > 0 ? this.open[0].invoiceId : null;
      } else {
        this.selectedInvoice =
          this.sent.length > 0 ? this.sent[0].invoiceId : null;
      }
    },
    isOpen: function(val) {
      if (val) {
        this.$store.dispatch("invoice/fetchInvoices").then(data => {
          let open = [];
          let sent = [];
          data.forEach(i => {
            if (i.status === "OPEN") {
              open.push(nullToEmptyObj(i));
              if (i.invoiceId === this.currentInvoice) {
                this.firstTab = true;
              }
            } else if (i.status === "SENT") {
              sent.push(nullToEmptyObj(i));
              if (i.invoiceId === this.currentInvoice) {
                this.firstTab = false;
              }
            }
          });
          this.selectedInvoice = this.currentInvoice;
          this.open = open;
          this.sent = sent;
        });
      }
    }
  }
};
</script>

<style scoped>
button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
button:nth-child(2) {
  border-radius: 0;
}
button {
  margin: 0 !important;
}
.red-dot {
  margin-top: 8px;
  margin-left: 4px;
  height: 8px;
  width: 8px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  display: inline-block;
}
.no-invoices {
  margin-top: 20%;
  text-align: center;
}
.invoice-id {
  margin-left: 5px;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.invoice-updated {
  margin-left: auto;
  margin-right: 5px;
}
.tab {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 10px 10px 0 0;
  background-color:rgba(177, 169, 169, 0.5);
  margin-bottom: 0;
}
.tab:hover {
  background-color:  rgb(195, 187, 187);
}
.open-tab:hover {
  background-color:  rgb(195, 187, 187);

    /* background-color: rgba(177, 169, 169, 0.5); */
}
.open-tab {
  background-color:  rgb(195, 187, 187);
  color: inherit;
}
.invoice-list {
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: left;
  height: 300px;

  overflow: auto;
  background-color: rgba(177, 169, 169, 0.5);
}
.selected-item {
  background-color: rgba(0, 128, 0, 0.342) !important;
  /*  border:4px inset #999!important; */
  box-shadow: none !important;
}
/* .selected-item:hover {
    background-color: rgb(141, 138, 138) !important;
} */

.invoice-list-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  /*   background-color: rgb(177, 169, 169, 0.5);
 */ /*border:4px outset #999;*/
  /*     box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
 */
}
.invoice-list-item:nth-child(even) {
  background-color: none;
 /*  background-color: rgba(0, 0, 0, 0.05); */
}
.invoice-list-item:nth-child(odd) {
  background-color: rgb(177, 169, 169, 0.5);
}
.invoice-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
/* The Modal (background) */
.modal {
  display: block;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% 10% auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 600px;
  box-shadow: 5px 5px 15px hsla(0, 0%, 0%, 0.4);
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 800px;
    margin: 15% auto;
  }
}
@media screen and (max-width: 426px) {
  .modal-content {
    height: 600px;
    padding: 0;
  }
  .invoice-updated {
    font-size: 10px;
  }
  .invoice-list {
    height: 400px;
    font-size: 12px;
  }
}
</style>
