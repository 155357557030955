<template>
  <tr :id="'row-id-' + row.id">
    <td>
      <i
        :title="$t('invoiceForm.deleteRow') + ' (ctrl + delete)'"
        v-on:click="
          editingDisabled || productRow.length == 1 ? '' : deleteRow(row.id)
        "
        style="float: left"
        class="row-action-cell-split far fa-trash-alt white"
      ></i>
      <i
        :title="$t('invoiceForm.copyRow') + ' (ctrl + c)'"
        v-on:click="editingDisabled ? '' : copyRow(row)"
        style="float: right"
        class="row-action-cell-split far fa-copy white"
      ></i>
    </td>
    <td class="medium-cell">
      <row-viewer-product-input
        :input="code"
        v-on:changeVal="code = $event"
        v-on:addRow="addRow"
        v-on:copyRow="copyRow(row)"
        v-on:deleteRow="deleteRow(row.id)"
        v-on:select="insertData(row, $event)"
        :disabled="editingDisabled"
        :valid="codeIsValid"
      />
    </td>
    <td class="long-cell">
      <input
        name="name"
        class="form-control form-control-sm"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
        v-bind:class="{
          '': nameIsValid == null,
          'is-valid': nameIsValid == true,
          'is-invalid': nameIsValid == false,
        }"
        v-model="name"
        v-bind:placeholder="$t('product.name')"
        :disabled="editingDisabled"
      />
    </td>
    <td>
      <input
        name="ammount"
        class="form-control form-control-sm"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
        v-bind:class="{
          '': ammountIsValid == null,
          'is-valid': ammountIsValid == true,
          'is-invalid': ammountIsValid == false,
        }"
        v-on:blur="row ? formatNumberBlur($event) : ''"
        v-model="ammount"
        v-bind:placeholder="$t('product.ammount')"
        :disabled="editingDisabled"
      />
    </td>
    <td>
      <select
        class="form-control form-control-sm"
        v-model="unit"
        :disabled="editingDisabled"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
      >
        <option v-for="(unit, index) in unitTypes" :key="index" :value="unit">
          {{ $t("units." + unit) }}
        </option>
      </select>
    </td>
    <td class="medium-cell">
      <input
        name="price"
        class="form-control form-control-sm"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
        v-bind:class="{
          '': priceIsValid == null,
          'is-valid': priceIsValid == true,
          'is-invalid': priceIsValid == false,
        }"
        v-on:blur="row ? formatNumberBlur($event) : ''"
        v-model="price"
        v-bind:placeholder="$t('product.price')"
        :disabled="editingDisabled"
      />
    </td>
    <td>
      <input
        name="discount"
        class="form-control form-control-sm"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
        v-bind:class="{
          '': discountIsValid == null,
          'is-valid': discountIsValid == true,
          'is-invalid': discountIsValid == false,
        }"
        v-on:blur="row ? formatNumberBlur($event) : ''"
        v-model="discount"
        v-bind:placeholder="$t('product.discount')"
        :disabled="editingDisabled"
      />
    </td>
    <td>
      <select
        class="form-control form-control-sm"
        name="VAT"
        v-model="VAT"
        :disabled="
          editingDisabled || (reverseVAT && entryPoint == 'laskumappi')
        "
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
      >
        <option v-for="(item, index) in VATRates" :key="index" :value="item">
          {{ formatNumber(item) }}
        </option>
      </select>
    </td>
    <td class="white">{{ row.withoutVAT }}</td>
    <td class="white">{{ row.totalVAT }}</td>
    <td class="white">{{ row.total }}</td>

    <!-- if mobile, render free text as cell see: RowViewer -->
    <td v-if="windowWidth <= 800" class="aft-cell-mobile">
      <input
        maxlength="96"
        class="form-control form-control-sm"
        @keyup.ctrl.enter="addRow"
        @keyup.ctrl.c="copyRow(row)"
        @keyup.ctrl.delete="deleteRow(row.id)"
        v-model="info"
        v-bind:placeholder="$t('product.comment')"
        :disabled="editingDisabled"
      />

      <i
        @click="
          editingDisabled || row.additionalFreeText.length > 9
            ? ''
            : addCommentRow(row.id)
        "
        class="fa fa-2x fa-plus-circle white aft-action-mobile"
      ></i>
    </td>
    <template v-if="windowWidth <= 800">
      <td
        v-for="(aft, aftKey) in row.additionalFreeText"
        :key="index + '-' + aftKey"
        class="aft-cell-mobile"
      >
        <input
          maxlength="96"
          class="form-control form-control-sm"
          :value="aft"
          v-on:input="
            $emit('update-comment-row', {
              index: aftKey,
              value: $event.target.value,
            })
          "
          v-bind:placeholder="$t('product.comment')"
          :disabled="editingDisabled"
        />

        <i
          v-on:click="
            editingDisabled
              ? ''
              : deleteCommentRow({
                  rowId: row.id,
                  commentIndex: aftKey,
                })
          "
          :title="$t('invoiceForm.deleteRow')"
          class="fa fa-2x fa-minus-circle white aft-action-mobile"
        >
        </i>
      </td>
    </template>
  </tr>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import {
  firstLetterUpper,
  validateNumberInput,
  validateAmountInput,
  simpleValidation,
  validateDiscount,
  formatNumber,
  roundFiveD,
  toNumber,
} from "../utils";
import { Fragment } from "vue-fragment";
import RowViewerProductInput from "@/components/common/RowViewerProductInput";
import { createHelpers } from "vuex-map-fields";

const { mapMultiRowFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});
export default {
  props: ["row", "index", "windowWidth"],
  mounted: function () {
    this.$store.subscribeAction((action, state) => {
      if (action.type === "invoice/sendInvoice") {
        this.ammountIsValid === null ? (this.ammountIsValid = false) : "";
        this.nameIsValid === null ? (this.nameIsValid = false) : "";
        this.codeIsValid === null ? (this.codeIsValid = false) : "";
        this.priceIsValid === null ? (this.priceIsValid = false) : "";
      }
    });
  },
  data() {
    return {
      validateNumberInput,
      validateAmountInput,
      simpleValidation,
      validateDiscount,
      formatNumber,
      roundFiveD,
      toNumber,
      unitTypes: [
        "piece",
        "hour",
        "day",
        "cm",
        "gram",
        "person",
        "kilogram",
        "month",
        "kilometer",
        "kilowattHour",
        "liter",
        "box",
        "meter",
        "squareMeter",
        "cubicMeter",
        "minute",
        "millimeter",
        "bottle",
        "can",
        "bag",
        "roll",
        "page",
        "sack",
        "series",
        "tube",
        "ton",
        "year",
        "week",
      ],
      codeIsValid: null,
      nameIsValid: null,
      ammountIsValid: null,
      priceIsValid: null,
      discountIsValid: null,
    };
  },
  components: {
    RowViewerProductInput,
  },
  computed: {
    ...mapFields([
      "invoiceField.editingDisabled",
      "userData.entryPoint",
      "taxCategories",
      "invoiceField.billingInfo.selectedTaxCategory",
      "productRow",
      "invalidFields",
      "invoiceField.billingInfo.reverseVAT",
    ]),
    wholeDiscount: function () {
      let discount = this.$store.getters["invoice/getField"](
        "invoiceField.billingInfo.discount"
      );
      return this.validateDiscount(discount) ? discount : 0;
    },
    allNumbersValid: function () {
      return (
        this.ammountIsValid &&
        this.priceIsValid &&
        this.discountIsValid !== false
      );
    },
    code: {
      get: function () {
        return this.row.code;
      },
      set: function (value) {
        this.$emit("update", { field: "code", value });
      },
    },
    name: {
      get: function () {
        return this.row.name;
      },
      set: function (value) {
        this.$emit("update", { field: "name", value });
      },
    },

    ammount: {
      get: function () {
        return this.row.ammount;
      },
      set: function (value) {
        this.$emit("update", { field: "ammount", value });
      },
    },
    unit: {
      get: function () {
        return this.row.unit;
      },
      set: function (value) {
        this.$emit("update", { field: "unit", value });
      },
    },
    price: {
      get: function () {
        return this.row.price;
      },
      set: function (value) {
        this.$emit("update", { field: "price", value });
      },
    },
    discount: {
      get: function () {
        return this.row.discount;
      },
      set: function (value) {
        this.$emit("update", { field: "discount", value });
      },
    },
    VAT: {
      get: function () {
        return this.row.VAT;
      },
      set: function (value) {
        this.$emit("update", { field: "VAT", value });
      },
    },
    info: {
      get: function () {
        return this.row.info;
      },
      set: function (value) {
        this.$emit("update", { field: "info", value });
      },
    },
    VATRates: function () {
      return this.taxCategories[this.selectedTaxCategory];
    },
  },
  methods: {
    ...mapActions("invoice", [
      "addRow",
      "deleteRow",
      "copyRow",
      "addToInvalidFields",
      "deleteCommentRow",
      "addCommentRow",
    ]),
    formatNumberBlur: function (event) {
      let name = event.target.name;
      if (this[name + "IsValid"]) {
        this.$emit("update", {
          field: name,
          value: this.rowInputNumberFormat(this[name]),
        });
      }
    },
    rowInputNumberFormat: function (number) {
      if (!isNaN(Number(number.toString().replace(",", ".")))) {
        number = Number(number.toString().replace(",", "."));
      }
      let numStr = number.toString().trim();
      if (numStr.includes(".") || numStr.includes(",")) {
        numStr = numStr.replace(".", ",");
        let split = numStr.split(",");
        if (split[1].length === 1) {
          numStr = split[0] + "," + split[1] + "0";
        } else {
          numStr = split[0] + "," + split[1];
        }
      } else {
        numStr += ",00";
      }
      return numStr;
    },
    count: function () {
      if (this.allNumbersValid) {
        let withoutVAT = roundFiveD(
          toNumber(this.price) *
            toNumber(this.ammount) *
            (1 - toNumber(this.discount) / 100)
        );
        let totalVAT = roundFiveD(withoutVAT * (toNumber(this.VAT) / 100));
        let total = roundFiveD(withoutVAT + totalVAT);
        totalVAT = roundFiveD(totalVAT);
        withoutVAT = roundFiveD(withoutVAT);
        totalVAT = formatNumber(totalVAT);
        withoutVAT = formatNumber(withoutVAT);
        total = formatNumber(total);
        this.$emit("update", {
          field: "withoutVAT",
          value: withoutVAT,
        });
        this.$emit("update", { field: "totalVAT", value: totalVAT });
        this.$emit("update", { field: "total", value: total });
      } else {
        this.$emit("update", { field: "withoutVAT", value: "0,00" });
        this.$emit("update", { field: "totalVAT", value: "0,00" });
        this.$emit("update", { field: "total", value: "0,00" });
      }
    },
    insertData(row, data) {
      this.$emit("update", { field: "name", value: data.name });
      this.$emit("update", {
        field: "price",
        value: data.unitPrice.replace(".", ","),
      });
      this.$emit("update", { field: "unit", value: data.unit });
      this.$emit("update", { field: "code", value: data.code });
      this.$emit("update", { field: "productId", value: data.productId });
      if (this.VATRates.includes(Number(data.taxPercent)) && !this.reverseVAT) {
        this.$emit("update", {
          field: "VAT",
          value: Number(data.taxPercent),
        });
      } else {
        this.$emit("update", { field: "VAT", value: 0 });
      }
    },
  },
  watch: {
    ammount: {
      immediate: true,
      deep: true,

      handler: function (val) {
        if (val === "") {
          this.ammountIsValid = null;
        } else {
          this.ammountIsValid = this.validateAmountInput(val);
        }
        this.$emit("update", {
          field: "ammountIsValid",
          value: this.ammountIsValid,
        });
        this.count();
      },
    },
    reverseVAT: function (val) {
      if (val && this.entryPoint === "laskumappi") {
        this.$emit("update", { field: "VAT", value: 0 });
      }
    },
    price: {
      immediate: true,
      deep: true,

      handler: function (val) {
        if (val === "") {
          this.priceIsValid = null;
        } else {
          this.priceIsValid = this.validateNumberInput(val);
        }
        this.$emit("update", {
          field: "priceIsValid",
          value: this.priceIsValid,
        });
        this.count();
      },
    },
    discount: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val === "") {
          this.discountIsValid = null;
        } else {
          this.discountIsValid = this.validateNumberInput(val);
        }
        this.$emit("update", {
          field: "discountIsValid",
          value: this.discountIsValid,
        });
        this.count();
      },
    },
    code: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val === "") {
          this.codeIsValid = null;
        } else {
          this.codeIsValid = this.simpleValidation(val);
        }
        this.$emit("update", {
          field: "codeIsValid",
          value: this.codeIsValid,
        });
      },
    },
    name: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val === "") {
          this.nameIsValid = null;
        } else {
          this.nameIsValid = this.simpleValidation(val);
        }
        this.$emit("update", {
          field: "nameIsValid",
          value: this.nameIsValid,
        });
      },
    },
    VAT: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.count();
      },
    },
    wholeDiscount: {
      immediate: true,
      handler: function (val) {
        this.count();
      },
    },
    VATRates: {
      immediate: true,
      handler: function (val) {
        if (!val.includes(this.VAT)) {
          this.$emit("update", { field: "VAT", value: 0 });
        }
      },
    },
  },
};
</script>

<style scoped>
.form-control-sm {
  padding: 0 2em 0 0.5em;
}

.table tbody + tbody {
  border-top: none;
}
.white {
  padding-top: 0.5em;
  color: #96cad4;
}

input {
  width: 100%;
  border-radius: 0;
}
.form-control-sm {
  height: 2.25em !important;
}
select {
  border-radius: 0;
}
.row-action-cell {
  padding: 0 1em 0 1em;
  cursor: pointer;
}
.row-action-cell-split {
  padding: 0.5em 0 0.5em 0;
  color: #96cad4;
  font-size: 16px;
  width: 50%;
  cursor: pointer;
}
.row-action-cell-split:hover {
  background-color: #5294a7;
}
.row-action-cell:hover {
  background-color: #5294a7;
}

@media screen and (min-width: 1441px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }
}
@media screen and (max-width: 1440px) {
  tbody > tr > td {
    width: 9%;
    padding: 0;
    position: relative;
  }
}

@media only screen and (max-width: 800px) {
  #row-viewer {
    margin-top: 0.5em;
    margin-bottom: 2rem;
  }
  .flip-table .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .flip-table * html .cf {
    zoom: 1;
  }
  .flip-table *:first-child + html .cf {
    zoom: 1;
  }

  .flip-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-table th,
  .flip-table td {
    margin: 0;
    vertical-align: top;
  }
  .flip-table th {
    text-align: left;
    min-height: 40px;
  }

  .flip-table table {
    display: block;
    position: relative;
    width: 100%;
  }
  .flip-table thead {
    display: block;
    float: left;
    width: 7em;
  }
  .flip-table tbody {
    display: block;
    width: 60%;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .flip-table thead tr {
    display: block;
    border-top: 1px solid #dee2e6;
  }
  .flip-table th {
    display: block;
    text-align: center;
    border: none;
  }
  .flip-table tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .flip-table td {
    display: block;
    min-height: 40px;
    text-align: left;
    width: 100%;
  }
  .flip-table td > input {
    height: 100%;
  }

  .row-action-cell-split {
    text-align: center;
  }
  .aft-action-mobile {
    position: absolute;
    right: 0.2em;
    top: 0em;
    padding-top: 0em;
  }
  .aft-cell-mobile {
    position: relative;
  }
  .aft-cell-mobile > input {
    padding-right: 3em;
  }
}
@media only screen and (max-width: 426px) {
  .flip-table tbody {
    width: 300px;
  }
}
@media only screen and (max-width: 376px) {
  .flip-table tbody {
    width: 190px;
  }
}
/* FLIP END */
</style>
