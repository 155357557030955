export const fi = [
     {  
        "value":"AF",
        "name":"Afganistan"
     },
     {  
        "value":"NL",
        "name":"Alankomaat"
     },
     {  
        "value":"AN",
        "name":"Alankomaiden Antillit"
     },
     {  
        "value":"AL",
        "name":"Albania"
     },
     {  
        "value":"DZ",
        "name":"Algeria"
     },
     {  
        "value":"AS",
        "name":"Amerikan Samoa"
     },
     {  
        "value":"AD",
        "name":"Andorra"
     },
     {  
        "value":"AO",
        "name":"Angola"
     },
     {  
        "value":"AI",
        "name":"Anguilla"
     },
     {  
        "value":"AQ",
        "name":"Antarktis"
     },
     {  
        "value":"AG",
        "name":"Antigua ja Barbuda"
     },
     {  
        "value":"AE",
        "name":"Arabiemiirikunnat"
     },
     {  
        "value":"AR",
        "name":"Argentiina"
     },
     {  
        "value":"AM",
        "name":"Armenia"
     },
     {  
        "value":"AW",
        "name":"Aruba"
     },
     {  
        "value":"AU",
        "name":"Australia"
     },
     {  
        "value":"AZ",
        "name":"Azerbaidzan"
     },
     {  
        "value":"BS",
        "name":"Bahama"
     },
     {  
        "value":"BH",
        "name":"Bahrain"
     },
     {  
        "value":"BD",
        "name":"Bangladesh"
     },
     {  
        "value":"BB",
        "name":"Barbados"
     },
     {  
        "value":"BE",
        "name":"Belgia"
     },
     {  
        "value":"BZ",
        "name":"Belize"
     },
     {  
        "value":"BJ",
        "name":"Benin"
     },
     {  
        "value":"BM",
        "name":"Bermuda"
     },
     {  
        "value":"BT",
        "name":"Bhutan"
     },
     {  
        "value":"BO",
        "name":"Bolivia"
     },
     {  
        "value":"BA",
        "name":"Bosnia-Hertsegovina"
     },
     {  
        "value":"BW",
        "name":"Botswana"
     },
/*      {  
        "value":"BV",
        "name":"Bouvet´nsaari"
     }, */
     {  
        "value":"BR",
        "name":"Brasilia"
     },
     {  
        "value":"GB",
        "name":"Britannia"
     },
/*      {  
        "value":"IO",
        "name":"Brittiläinen Intian valtameren alue"
     }, */
/*      {  
        "value":"VG",
        "name":"Brittiläiset Neitsytsaaret"
     }, */
     {  
        "value":"BN",
        "name":"Brunei"
     },
     {  
        "value":"BG",
        "name":"Bulgaria"
     },
     {  
        "value":"BF",
        "name":"Burkina Faso"
     },
     {  
        "value":"BI",
        "name":"Burundi"
     },
     {  
        "value":"PK",
        "name":"Pakistan"
     },
     {  
        "value":"KY",
        "name":"Caymansaaret"
     },
     {  
        "value":"CL",
        "name":"Chile"
     },
/*      {  
        "value":"CK",
        "name":"Cookinsaaret"
     }, */
     {  
        "value":"CR",
        "name":"Costa Rica"
     },
     {  
        "value":"DJ",
        "name":"Djibouti"
     },
     {  
        "value":"DM",
        "name":"Dominica"
     },
     {  
        "value":"DO",
        "name":"Dominikaaninen tasavalta"
     },
     {  
        "value":"EC",
        "name":"Ecuador"
     },
     {  
        "value":"EE",
        "name":"Eesti"
     },
     {  
        "value":"EG",
        "name":"Egypti"
     },
     {  
        "value":"SV",
        "name":"El Salvador"
     },
     {  
        "value":"ER",
        "name":"Eritrea"
     },
     {  
        "value":"ES",
        "name":"Espanja"
     },
     {  
        "value":"ZA",
        "name":"Etelä-Afrikka"
     },
     {  
        "value":"KR",
        "name":"Etelä-Korea"
     },
/*      {  
        "value":"GS",
        "name":"Etelä-Georgia ja Eteläiset Sandwichsaaret"
     }, */
     {  
        "value":"ET",
        "name":"Etiopia"
     },
     {  
        "value":"FK",
        "name":"Falklandinsaaret"
     },
     {  
        "value":"FJ",
        "name":"Fidzi"
     },
     {  
        "value":"PH",
        "name":"Filippiinit"
     },
     {  
        "value":"FO",
        "name":"Färsaaret"
     },
     {  
        "value":"GA",
        "name":"Gabon"
     },
     {  
        "value":"GM",
        "name":"Gambia"
     },
     {  
        "value":"GE",
        "name":"Georgia"
     },
     {  
        "value":"GH",
        "name":"Ghana"
     },
     {  
        "value":"GI",
        "name":"Gibraltar"
     },
     {  
        "value":"GD",
        "name":"Grenada"
     },
     {  
        "value":"GL",
        "name":"Grönlanti"
     },
     {  
        "value":"GP",
        "name":"Guadeloupe"
     },
     {  
        "value":"GU",
        "name":"Guam"
     },
     {  
        "value":"GT",
        "name":"Guatemala"
     },
     {  
        "value":"GN",
        "name":"Guinea"
     },
     {  
        "value":"GW",
        "name":"Guinea-Bissau"
     },
     {  
        "value":"GY",
        "name":"Guyana"
     },
     {  
        "value":"HT",
        "name":"Haiti"
     },
/*      {  
        "value":"HM",
        "name":"Heard ja McDonaldsaaret"
     }, */
     {  
        "value":"HN",
        "name":"Honduras"
     },
     {  
        "value":"HK",
        "name":"Hong kong"
     },
     {  
        "value":"SJ",
        "name":"Huippuvuoret ja Jan Mayen"
     },
     {  
        "value":"ID",
        "name":"Indonesia"
     },
     {  
        "value":"IN",
        "name":"Intia"
     },
     {  
        "value":"IQ",
        "name":"Irak"
     },
     {  
        "value":"IR",
        "name":"Iran"
     },
     {  
        "value":"IE",
        "name":"Irlanti"
     },
     {  
        "value":"IS",
        "name":"Islanti"
     },
     {  
        "value":"IL",
        "name":"Israel"
     },
     {  
        "value":"IT",
        "name":"Italia"
     },
     {  
        "value":"TL",
        "name":"Itä-Timor"
     },
     {  
        "value":"AT",
        "name":"Itävalta"
     },
     {  
        "value":"JM",
        "name":"Jamaika"
     },
     {  
        "value":"JP",
        "name":"Japani"
     },
     {  
        "value":"YE",
        "name":"Jemen"
     },
     {  
        "value":"JO",
        "name":"Jordania"
     },
     {  
        "value":"CX",
        "name":"Joulusaari"
     },
     {  
        "value":"KH",
        "name":"Kambodza"
     },
     {  
        "value":"CM",
        "name":"Kamerun"
     },
     {  
        "value":"CA",
        "name":"Kanada"
     },
     {  
        "value":"CV",
        "name":"Kap Verde"
     },
     {  
        "value":"KZ",
        "name":"Kazakstan"
     },
     {  
        "value":"KE",
        "name":"Kenia"
     },
     {  
        "value":"CF",
        "name":"Keski-Afrikka"
     },
     {  
        "value":"CN",
        "name":"Kiina"
     },
     {  
        "value":"KG",
        "name":"Kirgisia"
     },
     {  
        "value":"KI",
        "name":"Kiribati"
     },
     {  
        "value":"CO",
        "name":"Kolumbia"
     },
     {  
        "value":"KM",
        "name":"Komorit"
     },
     {  
        "value":"CG",
        "name":"Kongo"
     },
     {  
        "value":"CD",
        "name":"Kongon Demokraattinen Tasavalta"
     },
/*      {  
        "value":"CC",
        "name":"Kookossaaret"
     }, */
     {  
        "value":"GR",
        "name":"Kreikka"
     },
     {  
        "value":"HR",
        "name":"Kroatia"
     },
     {  
        "value":"CU",
        "name":"Kuuba"
     },
     {  
        "value":"KW",
        "name":"Kuwait"
     },
     {  
        "value":"CY",
        "name":"Kypros"
     },
     {  
        "value":"LA",
        "name":"Laos"
     },
     {  
        "value":"LV",
        "name":"Latvia"
     },
     {  
        "value":"LS",
        "name":"Lesotho"
     },
     {  
        "value":"LB",
        "name":"Libanon"
     },
     {  
        "value":"LR",
        "name":"Liberia"
     },
     {  
        "value":"LY",
        "name":"Libya"
     },
     {  
        "value":"LI",
        "name":"Liechtenstein"
     },
     {  
        "value":"LT",
        "name":"Liettua"
     },
     {  
        "value":"LU",
        "name":"Luxemburg"
     },
     {  
        "value":"EH",
        "name":"Länsi-Sahara"
     },
     {  
        "value":"MO",
        "name":"Macao"
     },
     {  
        "value":"MG",
        "name":"Madagaskar"
     },
     {  
        "value":"MK",
        "name":"Makedonia"
     },
     {  
        "value":"MW",
        "name":"Malawi"
     },
     {  
        "value":"MV",
        "name":"Malediivit"
     },
     {  
        "value":"MY",
        "name":"Malesia"
     },
     {  
        "value":"ML",
        "name":"Mali"
     },
     {  
        "value":"MT",
        "name":"Malta"
     },
     {  
        "value":"MA",
        "name":"Marokko"
     },
     {  
        "value":"MH",
        "name":"Marshallinsaaret"
     },
     {  
        "value":"MQ",
        "name":"Martinique"
     },
     {  
        "value":"MR",
        "name":"Mauritania"
     },
     {  
        "value":"MU",
        "name":"Mauritius"
     },
     {  
        "value":"YT",
        "name":"Mayotte"
     },
     {  
        "value":"MX",
        "name":"Meksiko"
     },
     {  
        "value":"FM",
        "name":"Mikronesia"
     },
     {  
        "value":"MD",
        "name":"Moldova"
     },
     {  
        "value":"MC",
        "name":"Monaco"
     },
     {  
        "value":"SN",
        "name":"Mongolia MN Senegal"
     },
     {  
        "value":"MS",
        "name":"Montserrat"
     },
     {  
        "value":"MZ",
        "name":"Mosambik"
     },
     {  
        "value":"MM",
        "name":"Myanmar"
     },
     {  
        "value":"NA",
        "name":"Namibia"
     },
     {  
        "value":"NR",
        "name":"Nauru"
     },
     {  
        "value":"NP",
        "name":"Nepal"
     },
     {  
        "value":"NI",
        "name":"Nicaragua"
     },
     {  
        "value":"NE",
        "name":"Niger"
     },
     {  
        "value":"NG",
        "name":"Nigeria"
     },
     {  
        "value":"NU",
        "name":"Niuensaari"
     },
     {  
        "value":"NF",
        "name":"Norfolkinsaari"
     },
     {  
        "value":"NO",
        "name":"Norja"
     },
     {  
        "value":"CI",
        "name":"Norsunluurannikko"
     },
     {  
        "value":"OM",
        "name":"Oman"
     },
     {  
        "value":"PW",
        "name":"Palau"
     },
/*      {  
        "value":"PS",
        "name":"Palestiina"
     }, */
     {  
        "value":"PA",
        "name":"Panama"
     },
     {  
        "value":"PG",
        "name":"Papua-Uusi-Guinea"
     },
     {  
        "value":"PY",
        "name":"Paraguay"
     },
     {  
        "value":"PE",
        "name":"Peru"
     },
     {  
        "value":"PN",
        "name":"Pitcairn"
     },
     {  
        "value":"KP",
        "name":"Pohjois-Korea"
     },
     {  
        "value":"MP",
        "name":"Pohjois-Mariaanit"
     },
     {  
        "value":"PT",
        "name":"Portugali"
     },
     {  
        "value":"PR",
        "name":"Puerto Rico"
     },
     {  
        "value":"PL",
        "name":"Puola"
     },
     {  
        "value":"GQ",
        "name":"Päiväntasaajan Guinea"
     },
     {  
        "value":"QA",
        "name":"Qatar"
     },
     {  
        "value":"FR",
        "name":"Ranska"
     },
     {  
        "value":"GF",
        "name":"Ranskan Guayana"
     },
     {  
        "value":"PF",
        "name":"Ranskan Polynesia"
     },
     {  
        "value":"TF",
        "name":"Ranskan eteläiset ja antarktiset alueet"
     },
     {  
        "value":"RO",
        "name":"Romania"
     },
     {  
        "value":"RW",
        "name":"Ruanda"
     },
     {  
        "value":"SE",
        "name":"Ruotsi"
     },
     {  
        "value":"RE",
        "name":"Réunion"
     },
     {  
        "value":"SH",
        "name":"Saint Helena"
     },
     {  
        "value":"KN",
        "name":"Saint Kitts ja Nevis"
     },
     {  
        "value":"LC",
        "name":"Saint Lucia"
     },
     {  
        "value":"VC",
        "name":"Saint Vincent ja Grenadiinit"
     },
     {  
        "value":"PM",
        "name":"Saint-Pierre ja Miquelon"
     },
     {  
        "value":"DE",
        "name":"Saksa"
     },
     {  
        "value":"SB",
        "name":"Salomonsaaret"
     },
     {  
        "value":"ZM",
        "name":"Sambia"
     },
     {  
        "value":"WS",
        "name":"Samoa"
     },
     {  
        "value":"SM",
        "name":"San Marino"
     },
     {  
        "value":"ST",
        "name":"Sao Tomé ja Príncipe"
     },
     {  
        "value":"SA",
        "name":"Saudi-Arabia"
     },
     {  
        "value":"CS",
        "name":"Serbia ja Montenegro (ent. Jugoslavia)"
     },
     {  
        "value":"SC",
        "name":"Seychellit"
     },
     {  
        "value":"SL",
        "name":"Sierra Leone"
     },
     {  
        "value":"SG",
        "name":"Singapore"
     },
     {  
        "value":"SK",
        "name":"Slovakia"
     },
     {  
        "value":"SI",
        "name":"Slovenia"
     },
     {  
        "value":"SO",
        "name":"Somalia"
     },
     {  
        "value":"LK",
        "name":"Sri Lanka"
     },
     {  
        "value":"SD",
        "name":"Sudan"
     },
     {  
        "value":"FI",
        "name":"Suomi"
     },
     {  
        "value":"SR",
        "name":"Suriname"
     },
     {  
        "value":"CH",
        "name":"Sveitsi"
     },
     {  
        "value":"SZ",
        "name":"Swazimaa"
     },
     {  
        "value":"SY",
        "name":"Syyria"
     },
     {  
        "value":"TJ",
        "name":"Tadzikistan"
     },
     {  
        "value":"TW",
        "name":"Taiwan"
     },
     {  
        "value":"TZ",
        "name":"Tansania"
     },
     {  
        "value":"DK",
        "name":"Tanska"
     },
     {  
        "value":"TH",
        "name":"Thaimaa"
     },
     {  
        "value":"TG",
        "name":"Togo"
     },
     {  
        "value":"TK",
        "name":"Tokelau"
     },
     {  
        "value":"TO",
        "name":"Tonga"
     },
     {  
        "value":"TT",
        "name":"Trinidad ja Tobago"
     },
     {  
        "value":"TD",
        "name":"Tsad"
     },
     {  
        "value":"CZ",
        "name":"Tsekki"
     },
     {  
        "value":"TN",
        "name":"Tunisia"
     },
     {  
        "value":"TR",
        "name":"Turkki"
     },
     {  
        "value":"TM",
        "name":"Turkmenistan"
     },
     {  
        "value":"TC",
        "name":"Turks- ja Caicossaaret"
     },
     {  
        "value":"TV",
        "name":"Tuvalu"
     },
     {  
        "value":"UG",
        "name":"Uganda"
     },
     {  
        "value":"UA",
        "name":"Ukraina"
     },
     {  
        "value":"HU",
        "name":"Unkari"
     },
     {  
        "value":"UY",
        "name":"Uruguay"
     },
     {  
        "value":"NC",
        "name":"Uusi-Kaledonia"
     },
     {  
        "value":"NZ",
        "name":"Uusi-Seelanti"
     },
     {  
        "value":"UZ",
        "name":"Uzbekistan"
     },
     {  
        "value":"BY",
        "name":"Valko-Venäjä"
     },
     {  
        "value":"VU",
        "name":"Vanuatu"
     },
     {  
        "value":"VA",
        "name":"Vatikaani"
     },
     {  
        "value":"VE",
        "name":"Venezuela"
     },
     {  
        "value":"RU",
        "name":"Venäjä"
     },
     {  
        "value":"VN",
        "name":"Vietnam"
     },
     {  
        "value":"WF",
        "name":"Wallis- ja Futunasaaret"
     },
     {  
        "value":"US",
        "name":"Yhdysvallat"
     },
/*      {  
        "value":"VI",
        "name":"Yhdysvaltain Neitsytsaaret"
     }, */
     {  
        "value":"UM",
        "name":"Yhdysvaltain Tyynenmeren pienet erillissaaret"
     },
     {  
        "value":"ZW",
        "name":"Zimbabwe"
     }
]