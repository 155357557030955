<template>
    <div id="customer-additional" class="form-container">
        <h4 v-on:click="show" class="opener-link">
            {{ $t("invoiceForm.additionalInfo") }}
            <i v-if="display" class="fa fa-minus"></i>
            <i v-else class="fa fa-plus"></i>
        </h4>
        <div v-if="display">
            <div class="row col-lg">
                <input-field
                    form-group="additionalInfo"
                    data-field="deliveryMethod"
                    :label="$t('invoiceForm.deliveryMethod')"
                    :validation="null"
                />
                <input-field
                    form-group="additionalInfo"
                    type="date"
                    data-field="deliveryDay"
                    :label="$t('invoiceForm.deliveryDay')"
                    :validation="null"
                />
            </div>
            <div class="row col-lg">
                <text-field
                    form-group="additionalInfo"
                    data-field="additionalInfo"
                    :max="'255'"
                    :label="$t('invoiceForm.additionalInfo')"
                    :validation="null"
                />
            </div>
            <div v-if="reverseVAT">
                <div class="form-group">
                    <text-field
                        form-group="additionalInfo"
                        data-field="vatFreeText"
                        :label="$t('invoiceForm.vatFreeText')"
                        :validation="simpleValidation"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { mapActions } from "vuex";
import { firstLetterUpper, simpleValidation, dateValidation } from "../utils";
import InputField from "./InputField.vue";
import TextField from "./common/TextField";

const { mapFields } = createHelpers({
    getterType: "invoice/getField",
    mutationType: "invoice/updateField"
});
export default {
    data() {
        return {
            simpleValidation: simpleValidation,
            dateValidation: dateValidation
        };
    },
    mounted: function() {
        this.$store.subscribeAction((action, state) => {
            if (action.type === "invoice/sendInvoice") {
                this.invalidFields.additionalInfo.forEach(field => {
                    this.validations[field + "IsValid"] = false;
                });
            }
        });
    },
    computed: {
        display: {
            get: function() {
                return this.$store.getters["invoice/getField"](
                    "sectionDisplay.additionalInfo"
                );
            },
            set: function(val) {
                this.$store.dispatch("invoice/toggleSection", {
                    path: "additionalInfo",
                    value: val
                });
            }
        },
        isFakturamappen: function() {
            return this.userData.entryPoint === "fakturamappen";
        },
        ...mapFields([
            "invalidFields",
            "userData",
            "productRow",
            "invoiceField.additionalInfo.validations",
            "invoiceField.additionalInfo.deliveryMethod",
            "invoiceField.additionalInfo",
            "invoiceField.additionalInfo.deliveryDay",
            "invoiceField.additionalInfo.vatFreeText",
            "invoiceField.billingInfo.reverseVAT",
            "invoiceField.billingInfo.invoiceLanguage",
            "invoiceField.billingInfo",
            "invoiceField.billingAddress"
        ]),
        emptyFields: function() {
            return this.deliveryMethod === "" && this.deliveryDay === "";
        }
    },
    watch: {
        reverseVAT: {
            handler: function(val) {
                if (val) {
                    this.display = true;
                    this.vatFreeText = !this.isFakturamappen
                        ? this.vatFreeText ||
                          this.$tlang(
                              this.invoiceLanguage,
                              "invoiceForm.defaultVatFreeText"
                          )
                        : this.vatFreeText;
                } else {
                    this.vatFreeText = "";
                }
                this.validationHandler();
            },
            immediate: true,
            deep: true
        },
        productRow: {
            handler: function(val) {
                if (this.isFakturamappen) {
                    let found = false;
                    this.productRow.forEach(r => {
                        if (r.VAT === 0) {
                            found = true;
                        }
                    });
                    this.reverseVAT = found;
                }
            },
            deep: true,
            immediate: true
        },
        additionalInfo: {
            handler: function(val) {
                this.validationHandler();
            },
            deep: true
        },
        emptyFields: function(value) {
            if (value) {
                let fields = ["deliveryDay", "deliveryMethod"];
                fields.forEach(field => {
                    this.$store.dispatch("invoice/validateField", {
                        formGroup: "additionalInfo",
                        dataField: field,
                        value: null
                    });
                });
                let name = "additionalInfo";
                this.addToInvalidFields({ name, fields: [] });
            } else {
                this.display = true;
            }
        }
    },
    methods: {
        show: function() {
            this.display = !this.display;
        },
        validationHandler: function() {
            let invalidFields = [];
            if (this.reverseVAT) {
                if (this.validations.vatFreeTextIsValid !== true) {
                    invalidFields.push("vatFreeText");
                }
            }
            let name = "additionalInfo";
            let obj = { name, fields: invalidFields };
            this.addToInvalidFields(obj);
        },
        ...mapActions("invoice", ["addToInvalidFields"])
    },
    components: {
        InputField,
        TextField
    }
};
</script>

<style scoped>
.fa {
    float: right;
}
#customer-additional {
    margin: 0 1em 0 0.5em;
    min-width: 100%;
}
@media screen and (max-width: 1025px) {
    #customer-additional {
        margin-left: 0em;
    }
}
@media screen and (max-width: 768px) {
    #customer-additional {
        max-width: 100%;
        width: 100%;
        margin: 1em 0 1em 0;
    }
}
</style>
