export const operators = {fi:[
    {
        "name": "Aktia",
        "value": "HELSFIHH"
    },
    {
        "name": "Apix Messaging",
        "value": "003723327487"
    },

    {
        "name": "Basware",
        "value": "BAWCFI22"
    },
    {
        "name": "Bankgirocentralen",
        "value": "BGCSE"
    },
    {
        "name": "CGI",
        "value": "003703575029"
    },
    {
        "name": "Danskebank",
        "value": "DABAFIHH"
    },

    {
        "name": "Handelsbanken",
        "value": "HANDFIHH"
    },
    {
        "name": "HighJump",
        "value": "00885790000000418"
    },
    {
        "name": "InExchange",
        "value": "INEXCHANGE"
    },

    {
        "name": "Lexmark",
        "value": "LEXMARK"
    },
    {
        "name": "Liaison",
        "value": "003708599126"
    },

    {
        "name": "Maventa",
        "value": "003721291126"
    },
    {
        "name": "Netbox",
        "value": "003726044706"
    },
    {
        "name": "Nordea",
        "value": "NDEAFIHH"
    },
    {
        "name": "OpusCapita",
        "value": "003710948874"
    },
    {
        "name": "Osuuspankki",
        "value": "OKOYFIHH"
    },
    {
        "name": "Pagero",
        "value": "PAGERO"
    },
    {
        "name": "Palette",
        "value": "PALETTESE"
    },
    {
        "name": "POP Pankki",
        "value": "POPFFI22"
    },
    {
        "name": "Proceedo",
        "value": "PROCEEDO"
    },
    {
        "name": "Ropo Capital",
        "value": "003714377140"
    },
    {
        "name": "ScanCloud",
        "value": "SCANCLOUD"
    },
    {
        "name": "Säästöpankki",
        "value": "ITELFIHH"
    },
    {
        "name": "S-Pankki",
        "value": "SBANFIHH"
    },
    {
        "name": "Stralfors",
        "value": "003701150617"
    },
    {
        "name": "TeliaSonera",
        "value": "003714756079"
    },
    {
        "name": "Tieto",
        "value": "003701011385"
    },
    {
        "name": "Tradeshift",
        "value": "00885060259470028"
    },
    {
        "name": "YAP Solutions",
        "value": "FIYAPSOL"
    },
    {
        "name": "Ålandsbanken",
        "value": "AABAFI22"
    },
    {
        "name": "Peppol-AP",
        "value": "Peppol-AP"
    }], 
    se:




    [
    {
        "name": "Apix Messaging",
        "value": "003723327487"
    },
    {
        "name": "Basware",
        "value": "BAWCFI22"
    },
    {
        "name": "Bankgirocentralen",
        "value": "BGCSE"
    },
    {
        "name": "CGISE",
        "value": "CGISE"
    },
    {
        "name": "Crediflow",
        "value": "CREDIFLOW"
    },
    {
        "name": "Enfo",
        "value": "003714377140"
    },
    {
        "name": "Evry",
        "value": "EVRYSE"
    },
    {
        "name": "InExchange",
        "value": "INEXCHANGE"
    },
    {
        "name": "Lexmark",
        "value": "LEXMARK"
    },
    {
        "name": "Nordea",
        "value": "NDEAFIHH"
    },
    {
        "name": "OpusCapita",
        "value": "003710948874"
    },
    {
        "name": "Pagero",
        "value": "PAGERO"
    },
    {
        "name": "Palette",
        "value": "PALETTESE"
    },
    {
        "name": "Peppol-AP",
        "value": "Peppol-AP"
    },

    {
        "name": "ScanCloud",
        "value": "SCANCLOUD"
    },
    {
        "name": "Strålfors",
        "value": "003701150617"
    },
    {
        "name": "Tieto",
        "value": "003701011385"
    },
    {
        "name": "VismaProceedo",
        "value": "PROCEEDOSE"
    }]}

/* Kofax 
Sproom  */