import { sv } from './sv'
import { fi } from './fi'
import { en } from './en'
import { svfi } from './svfi'

export const messages = {
    en: en,
    fi: fi,
    sv: sv,
    svfi: svfi
  }