import { mutations } from './mutations.js'
import { actions } from './actions.js'
import { getField } from 'vuex-map-fields';
import { initialState } from './initialState.js'

export const invoice = (userdata) => {

    const state = {
        namespaced: true,
        state: initialState(userdata),    
        getters: {
            getField,
        },
        mutations: mutations,
        actions: actions 
}
return state
}
