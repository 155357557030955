<template>
    <div>
        <button :disabled="isDisabled" v-on:click="open = !open" class="appendice-btn btn btn-secondary">{{ $t("common.appendices")}} ({{appendices.files.length}})</button>
        <appendices :is-open="open" v-on:close="open = !open"></appendices>
     </div>
</template>
<script>
export default {
  data() {
      return {
        open: false,
        files: [],
      }
  },
  computed: {
      appendices: {
          get() {
              return this.$store.getters['invoice/getField']('appendices')
          }
      },
      isDisabled: {
          get() {
              return this.$store.getters['invoice/getField']('invoiceField.editingDisabled')
          }
      }
  },
  components: {
      Appendices: () => import("./Appendices.vue")
  }
}
</script>
<style>
.appendice-btn {
    border-radius: 0!important;
    margin-right: 0!important;
    border-right: #5a6268 1px solid;
    border-left: #5a6268 1px solid;
}
</style>

