<template>
      <span :style="style" class="tooltiptext">{{text}}
          <slot></slot>
      </span>
</template>

<script>
export default {
    props: ['text', 'visible', 'type', 'width'],
    computed: {
        style: function() {
            if (this.visible) {
                let styleObj = {
                    visbility: "visible",
                    "background-color": this.type === "error" ? "red" : "black",
                }
                if (this.width) {
                    styleObj.width = this.width + "px"
                }
                return styleObj
            } else {
                return {
                    visibility: "hidden"
                }
            }
        }
    }
}
</script>

<style>
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

 .tooltiptext {
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 55%;
    left: 50%;
    margin-left: -60px;
}

 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
}

.tooltiptext:hover {
    visibility: visible;
}

</style>
