export const sv = [
    {  
        "name":"Afghanistan",
        "value":"AF"
     },
     {  
        "name":"Åland Islands",
        "value":"AX"
     },
     {  
        "name":"Albania",
        "value":"AL"
     },
     {  
        "name":"Algeriet",
        "value":"DZ"
     },
     {  
        "name":"Samoa",
        "value":"AS"
     },
     {  
        "name":"Andorra",
        "value":"AD"
     },
     {  
        "name":"Angola",
        "value":"AO"
     },
     {  
        "name":"Anguilla",
        "value":"AI"
     },
     {  
        "name":"Antarctica",
        "value":"AQ"
     },
     {  
        "name":"Antigua och Barbuda",
        "value":"AG"
     },
     {  
        "name":"Argentina",
        "value":"AR"
     },
     {  
        "name":"Armenien",
        "value":"AM"
     },
     {  
        "name":"Aruba",
        "value":"AW"
     },
     {  
        "name":"Australia",
        "value":"AU"
     },
     {  
        "name":"Österrike",
        "value":"AT"
     },
     {  
        "name":"Azerbajdzjan",
        "value":"AZ"
     },
     {  
        "name":"Bahamas",
        "value":"BS"
     },
     {  
        "name":"Bahrain",
        "value":"BH"
     },
     {  
        "name":"Bangladesh",
        "value":"BD"
     },
     {  
        "name":"Barbados",
        "value":"BB"
     },
     {  
        "name":"Belarus",
        "value":"BY"
     },
     {  
        "name":"Belgien",
        "value":"BE"
     },
     {  
        "name":"Belize",
        "value":"BZ"
     },
     {  
        "name":"Benin",
        "value":"BJ"
     },
     {  
        "name":"Bermuda",
        "value":"BM"
     },
     {  
        "name":"Bhutan",
        "value":"BT"
     },
     {  
        "name":"Bolivia",
        "value":"BO"
     },
     {  
        "name":"Bosnien och Hercegovina",
        "value":"BA"
     },
     {  
        "name":"Botswana",
        "value":"BW"
     },
 /*     {  
        "name":"Bouvet Island",
        "value":"BV"
     }, */
     {  
        "name":"Brazil",
        "value":"BR"
     },
 /*     {  
        "name":"British Indian Ocean Territory",
        "value":"IO"
     }, */
     {  
        "name":"Brunei",
        "value":"BN"
     },
     {  
        "name":"Bulgarien",
        "value":"BG"
     },
     {  
        "name":"Burkina Faso",
        "value":"BF"
     },
     {  
        "name":"Burundi",
        "value":"BI"
     },
     {  
        "name":"Cambodia",
        "value":"KH"
     },
     {  
        "name":"Kamerun",
        "value":"CM"
     },
     {  
        "name":"Canada",
        "value":"CA"
     },
     {  
        "name":"Kap Verde",
        "value":"CV"
     },
     {  
        "name":"Caymanöarna",
        "value":"KY"
     },
     {  
        "name":"Centralafrikanska republiken",
        "value":"CF"
     },
     {  
        "name":"Tchad",
        "value":"TD"
     },
     {  
        "name":"Chile",
        "value":"CL"
     },
     {  
        "name":"Kina",
        "value":"CN"
     },
     {  
        "name":"Julön",
        "value":"CX"
     },
 /*     {  
        "name":"Cocos (Keeling) Islands",
        "value":"CC"
     }, */
     {  
        "name":"Colombia",
        "value":"CO"
     },
     {  
        "name":"Komorerna",
        "value":"KM"
     },
     {  
        "name":"Congo",
        "value":"CG"
     },
     {  
        "name":"Kongo, Demokratiska republiken",
        "value":"CD"
     },
  /*    {  
        "name":"Cook Islands",
        "value":"CK"
     }, */
     {  
        "name":"Costa Rica",
        "value":"CR"
     },
     {  
        "name":"Elfenbenskusten",
        "value":"CI"
     },
     {  
        "name":"Kroatien",
        "value":"HR"
     },
     {  
        "name":"Kuba",
        "value":"CU"
     },
     {  
        "name":"Cypern",
        "value":"CY"
     },
     {  
        "name":"Tjeckien",
        "value":"CZ"
     },
     {  
        "name":"Danmark",
        "value":"DK"
     },
     {  
        "name":"Djibouti",
        "value":"DJ"
     },
     {  
        "name":"Dominica",
        "value":"DM"
     },
     {  
        "name":"Dominikanska republiken",
        "value":"DO"
     },
     {  
        "name":"Ecuador",
        "value":"EC"
     },
     {  
        "name":"Egypt",
        "value":"EG"
     },
     {  
        "name":"El Salvador",
        "value":"SV"
     },
     {  
        "name":"Ekvatorialguinea",
        "value":"GQ"
     },
     {  
        "name":"Eritrea",
        "value":"ER"
     },
     {  
        "name":"Estland",
        "value":"EE"
     },
     {  
        "name":"Etiopien",
        "value":"ET"
     },
     {  
        "name":"Falklandsöarna (Malvinas)",
        "value":"FK"
     },
     {  
        "name":"Färöarna",
        "value":"FO"
     },
     {  
        "name":"Fiji",
        "value":"FJ"
     },
     {  
        "name":"Finland",
        "value":"FI"
     },
     {  
        "name":"Frankrike",
        "value":"FR"
     },
     {  
        "name":"Franska Guyana",
        "value":"GF"
     },
     {  
        "name":"Franska Polynesien",
        "value":"PF"
     },
     {  
        "name":"franska södra territorier",
        "value":"TF"
     },
     {  
        "name":"Gabon",
        "value":"GA"
     },
     {  
        "name":"Gambia",
        "value":"GM"
     },
     {  
        "name":"Georgia",
        "value":"GE"
     },
     {  
        "name":"Tyskland",
        "value":"DE"
     },
     {  
        "name":"Ghana",
        "value":"GH"
     },
     {  
        "name":"Gibraltar",
        "value":"GI"
     },
     {  
        "name":"Grekland",
        "value":"GR"
     },
     {  
        "name":"Grönland",
        "value":"GL"
     },
     {  
        "name":"Grenada",
        "value":"GD"
     },
     {  
        "name":"Guadeloupe",
        "value":"GP"
     },
     {  
        "name":"Guam",
        "value":"GU"
     },
     {  
        "name":"Guatemala",
        "value":"GT"
     },
     {  
        "name":"Guernsey",
        "value":"GG"
     },
     {  
        "name":"Guinea",
        "value":"GN"
     },
     {  
        "name":"Guinea-Bissau",
        "value":"GW"
     },
     {  
        "name":"Guyana",
        "value":"GY"
     },
     {  
        "name":"Haiti",
        "value":"HT"
     },
 /*     {  
        "name":"Heard Island och McDonald Islands",
        "value":"HM"
     }, */
     {  
        "name":"Vatikanstaten",
        "value":"VA"
     },
     {  
        "name":"Honduras",
        "value":"HN"
     },
     {  
        "name":"HongKong",
        "value":"HK"
     },
     {  
        "name":"Ungern",
        "value":"HU"
     },
     {  
        "name":"Isländska",
        "value":"IS"
     },
     {  
        "name":"Indien",
        "value":"IN"
     },
     {  
        "name":"Indonesien",
        "value":"ID"
     },
     {  
        "name":"Iran",
        "value":"IR"
     },
     {  
        "name":"Irak",
        "value":"IQ"
     },
     {  
        "name":"Irland",
        "value":"IE"
     },
 /*     {  
        "name":"Isle of Man",
        "value":"IM"
     }, */
     {  
        "name":"Israel",
        "value":"IL"
     },
     {  
        "name":"Italien",
        "value":"IT"
     },
     {  
        "name":"Jamaica",
        "value":"JM"
     },
     {  
        "name":"Japan",
        "value":"JP"
     },
     {  
        "name":"Jersey",
        "value":"JE"
     },
     {  
        "name":"Jordan",
        "value":"JO"
     },
     {  
        "name":"Kazakstan",
        "value":"KZ"
     },
     {  
        "name":"Kenya",
        "value":"KE"
     },
     {  
        "name":"Kiribati",
        "value":"KI"
     },
     {  
        "name":"Korea, Demokratiska Folkets Republiken ",
        "value":"KP"
     },
     {  
        "name":"Korea",
        "value":"KR"
     },
     {  
        "name":"Kuwait",
        "value":"KW"
     },
     {  
        "name":"Kirgizistan",
        "value":"KG"
     },
     {  
        "name":"Laos",
        "value":"LA"
     },
     {  
        "name":"Lettland",
        "value":"LV"
     },
     {  
        "name":"Libanon",
        "value":"LB"
     },
     {  
        "name":"Lesotho",
        "value":"LS"
     },
     {  
        "name":"Liberia",
        "value":"LR"
     },
     {  
        "name":"Libyen Arabiska Jamahiriya",
        "value":"LY"
     },
     {  
        "name":"Liechtenstein",
        "value":"LI"
     },
     {  
        "name":"Litauen",
        "value":"LT"
     },
     {  
        "name":"Luxemburg",
        "value":"LU"
     },
     {  
        "name":"Macao",
        "value":"MO"
     },
     {  
        "name":"Makedonien",
        "value":"MK"
     },
     {  
        "name":"Madagaskar",
        "value":"MG"
     },
     {  
        "name":"Malawi",
        "value":"MW"
     },
     {  
        "name":"Malaysia",
        "value":"MY"
     },
     {  
        "name":"Maldiverna",
        "value":"MV"
     },
     {  
        "name":"Mali",
        "value":"ML"
     },
     {  
        "name":"Malta",
        "value":"MT"
     },
     {  
        "name":"Marshallöarna",
        "value":"MH"
     },
     {  
        "name":"Martinique",
        "value":"MQ"
     },
     {  
        "name":"Mauretanien",
        "value":"MR"
     },
     {  
        "name":"Mauritius",
        "value":"MU"
     },
     {  
        "name":"Mayotte",
        "value":"YT"
     },
     {  
        "name":"Mexico",
        "value":"MX"
     },
     {  
        "name":"Mikronesien",
        "value":"FM"
     },
     {  
        "name":"Moldavien",
        "value":"MD"
     },
     {  
        "name":"Monaco",
        "value":"MC"
     },
     {  
        "name":"Mongoliet",
        "value":"MN"
     },
     {  
        "name":"Montserrat",
        "value":"MS"
     },
     {  
        "name":"Marocko",
        "value":"MA"
     },
     {  
        "name":"Moçambique",
        "value":"MZ"
     },
     {  
        "name":"Myanmar",
        "value":"MM"
     },
     {  
        "name":"Namibia",
        "value":"NA"
     },
     {  
        "name":"Nauru",
        "value":"NR"
     },
     {  
        "name":"Nepal",
        "value":"NP"
     },
     {  
        "name":"Nederländerna",
        "value":"NL"
     },
     {  
        "name":"Nederländska Antillerna",
        "value":"AN"
     },
     {  
        "name":"Nya Kaledonien",
        "value":"NC"
     },
     {  
        "name":"Nya Zeeland",
        "value":"NZ"
     },
     {  
        "name":"Nicaragua",
        "value":"NI"
     },
     {  
        "name":"Niger",
        "value":"NE"
     },
     {  
        "name":"Nigeria",
        "value":"NG"
     },
     {  
        "name":"Niue",
        "value":"NU"
     },
     {  
        "name":"Norfolk Island",
        "value":"NF"
     },
 /*     {  
        "name":"Northern Mariana Islands",
        "value":"MP"
     }, */
     {  
        "name":"Norge",
        "value":"NO"
     },
     {  
        "name":"Oman",
        "value":"OM"
     },
     {  
        "name":"Pakistan",
        "value":"PK"
     },
     {  
        "name":"Palau",
        "value":"PW"
     },
 /*     {  
        "name":"palestinska territoriet, ockuperade",
        "value":"PS"
     }, */
     {  
        "name":"Panama",
        "value":"PA"
     },
     {  
        "name":"Papua Nya Guinea",
        "value":"PG"
     },
     {  
        "name":"Paraguay",
        "value":"PY"
     },
     {  
        "name":"Peru",
        "value":"PE"
     },
     {  
        "name":"Filippinerna",
        "value":"PH"
     },
     {  
        "name":"Pitcairn",
        "value":"PN"
     },
     {  
        "name":"Polen",
        "value":"PL"
     },
     {  
        "name":"Portugal",
        "value":"PT"
     },
     {  
        "name":"Puerto Rico",
        "value":"PR"
     },
     {  
        "name":"Qatar",
        "value":"QA"
     },
     {  
        "name":"Reunion",
        "value":"RE"
     },
     {  
        "name":"Rumänien",
        "value":"RO"
     },
     {  
        "name":"Ryska federationen",
        "value":"RU"
     },
     {  
        "name":"Rwanda",
        "value":"RW"
     },
     {  
        "name":"Saint Helena",
        "value":"SH"
     },
     {  
        "name":"Saint Kitts och Nevis",
        "value":"KN"
     },
     {  
        "name":"Saint Lucia",
        "value":"LC"
     },
     {  
        "name":"Saint Pierre och Miquelon",
        "value":"PM"
     },
     {  
        "name":"Saint Vincent och Grenadinerna",
        "value":"VC"
     },
     {  
        "name":"Samoa",
        "value":"WS"
     },
     {  
        "name":"San Marino",
        "value":"SM"
     },
     {  
        "name":"Sao Tomé och Principe",
        "value":"ST"
     },
     {  
        "name":"Saudiarabien",
        "value":"SA"
     },
     {  
        "name":"Senegal",
        "value":"SN"
     },
     {  
        "name":"Serbien och Montenegro",
        "value":"CS"
     },
     {  
        "name":"Seychellerna",
        "value":"SC"
     },
     {  
        "name":"Sierra Leone",
        "value":"SL"
     },
     {  
        "name":"Singapore",
        "value":"SG"
     },
     {  
        "name":"Slovakien",
        "value":"SK"
     },
     {  
        "name":"Slovenien",
        "value":"SI"
     },
     {  
        "name":"Salomonöarna",
        "value":"SB"
     },
     {  
        "name":"Somalia",
        "value":"SO"
     },
     {  
        "name":"Sydafrika",
        "value":"ZA"
     },
 /*     {  
        "name":"South Georgia och South Sandwich Islands",
        "value":"GS"
     }, */
     {  
        "name":"Spanien",
        "value":"ES"
     },
     {  
        "name":"Sri Lanka",
        "value":"LK"
     },
     {  
        "name":"Sudan",
        "value":"SD"
     },
     {  
        "name":"Surinam",
        "value":"SR"
     },
     {  
        "name":"Svalbard och Jan Mayen",
        "value":"SJ"
     },
     {  
        "name":"Swaziland",
        "value":"SZ"
     },
     {  
        "name":"Sverige",
        "value":"SE"
     },
     {  
        "name":"Schweiz",
        "value":"CH"
     },
     {  
        "name":"Syrien",
        "value":"SY"
     },
     {  
        "name":"Taiwan, provinsen Kina",
        "value":"TW"
     },
     {  
        "name":"Tadzjikistan",
        "value":"TJ"
     },
     {  
        "name":"Tanzania",
        "value":"TZ"
     },
     {  
        "name":"Thailand",
        "value":"TH"
     },
     {  
        "name":"Östtimor",
        "value":"TL"
     },
     {  
        "name":"Togo",
        "value":"TG"
     },
     {  
        "name":"Tokelau",
        "value":"TK"
     },
     {  
        "name":"Tonga",
        "value":"TO"
     },
     {  
        "name":"Trinidad och Tobago",
        "value":"TT"
     },
     {  
        "name":"Tunisia",
        "value":"TN"
     },
     {  
        "name":"Turkiet",
        "value":"TR"
     },
     {  
        "name":"Turkmenistan",
        "value":"TM"
     },
     {  
        "name":"Turks- och Caicosöarna",
        "value":"TC"
     },
     {  
        "name":"Tuvalu",
        "value":"TV"
     },
     {  
        "name":"Uganda",
        "value":"UG"
     },
     {  
        "name":"Ukraina",
        "value":"UA"
     },
     {  
        "name":"Förenade Arabemiraten",
        "value":"AE"
     },
     {  
        "name":"Storbritannien",
        "value":"GB"
     },
     {  
        "name":"USA",
        "value":"US"
     },
     {  
        "name":"Förenta staternas mindre avlägsnaöar",
        "value":"UM"
     },
     {  
        "name":"Uruguay",
        "value":"UY"
     },
     {  
        "name":"Uzbekistan",
        "value":"UZ"
     },
     {  
        "name":"Vanuatu",
        "value":"VU"
     },
     {  
        "name":"Venezuela",
        "value":"VE"
     },
     {  
        "name":"Vietnam",
        "value":"VN"
     },
 /*     {  
        "name":"Jungfruöarna, brittiska",
        "value":"VG"
     },
     {  
        "name":"Jungfruöarna, USA",
        "value":"VI"
     }, */
     {  
        "name":"Wallis och Futuna",
        "value":"WF"
     },
 /*     {  
        "name":"västsahara",
        "value":"EH"
     }, */
     {  
        "name":"Jemen",
        "value":"YE"
     },
     {  
        "name":"Zambia",
        "value":"ZM"
     },
     {  
        "name":"Zimbabwe",
        "value":"ZW"
     }
]