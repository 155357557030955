import Vue from 'vue'
import VueRouter from 'vue-router'
const InvoiceBuilder = (/* webpackChunkName: "invoice-builder" */) => import('@/components/InvoiceBuilder.vue')
const PurchaseInvoice = (/* webpackChunkName: "customer-record" */) => import('@/components/purchaseInvoice/PurchaseInvoice')
const CustomerRecord = (/* webpackChunkName: "customer-record" */) => import('@/components/customer/CustomerRecord.vue')
const ProductRecord = (/* webpackChunkName: "product-record" */) => import('@/components/product/ProductRecord.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'invoiceBuilder',
    component: InvoiceBuilder,
  },
  {
    path: '/purchase',
    name: 'purchaseInvoice',
    component: PurchaseInvoice,
  },
  {
    path: '/customer',
    name: 'customerRecord',
    component: CustomerRecord,
  },
  {
    path: '/product',
    name: 'productRecord',
    component: ProductRecord,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
