<template>
  <div id="billing-info" class="form-container col-lg">
    <h4 v-on:click="show('billing')" class="opener-link">
      {{ $t("invoiceForm.billingInfo") }}
      <i
        style="font-size: 20px; float: right; margin-top: 0.5em"
        v-if="display['billing']"
        class="fa fa-minus"
      ></i>
      <i
        style="font-size: 20px; float: right; margin-top: 0.5em"
        v-else
        class="fa fa-plus"
      ></i>
    </h4>
    <div v-bind:class="{ '': display['billing'], hide: !display['billing'] }">
      <div class="row col-lg">
        <input-field
          form-group="billingInfo"
          type="date"
          :label="$t('invoiceForm.sentDate')"
          data-field="sentDate"
          :validation="simpleValidation"
        />
        <input-field
          form-group="billingInfo"
          data-field="invoiceNumber"
          :label="$t('invoiceForm.invoiceNumber')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingInfo"
          data-field="contactPerson"
          :label="$t('invoiceForm.contactPerson')"
          :validation="simpleValidation"
        />
        <input-field
          form-group="billingInfo"
          data-field="orderReference"
          :label="$t('invoiceForm.orderReference')"
          :validation="null"
        />
      </div>
    </div>
    <h4 v-on:click="show('payment')" class="opener-link">
      {{ $t("invoiceForm.paymentInfo") }}
      <i
        style="font-size: 20px; float: right; margin-top: 0.5em"
        v-if="display['payment']"
        class="fa fa-minus"
      ></i>
      <i
        style="font-size: 20px; float: right; margin-top: 0.5em"
        v-else
        class="fa fa-plus"
      ></i>
    </h4>
    <div v-bind:class="{ '': display['payment'], hide: !display['payment'] }">
      <div class="row col-lg">
        <div class="form-group">
          <label class="form-label">{{
            $t("invoiceForm.paymentSolution")
          }}</label>
          <select
            :disabled="editingDisabled"
            style="width: 7em; padding: 0"
            class="form-control form-control-sm"
            v-model="paymentMethod"
          >
            <option
              v-for="(acc, index) in paymentMethods"
              :value="acc"
              :key="index"
            >
              {{ acc }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg">
          <label class="form-label">{{ $t("invoiceForm.bankAccount") }}</label>
          <select
            :disabled="editingDisabled"
            class="form-control form-control-sm"
            v-model="bankAccount"
            v-bind:class="{
              '': bankAccountIsValid === null,
              'is-valid': bankAccountIsValid === true,
              'is-invalid': bankAccountIsValid === false,
            }"
          >
            <option
              v-if="bankAccountsByType.length === 0"
              :value="{ Type: '', Value: '' }"
              selected
            >
              {{ $t("invoiceForm.noAccounts") }}
            </option>
            <option v-else :value="{ Type: '', Value: '' }" selected>
              {{ $t("invoiceForm.select") + " " + paymentMethod }}
            </option>
            <option
              v-for="(item, index) in bankAccountsByType"
              :value="item"
              :key="index"
            >
              {{
                paymentMethod === "IBAN" ? spaceOutIBAN(item.Value) : item.Value
              }}
            </option>
          </select>

          <tooltip
            :visible="bankAccountsByType.length === 0"
            :text="$t('invoiceForm.addAccount')"
            :type="'error'"
          >
            <p class="tooltip-link" v-on:click.prevent="changePath">
              {{ $t("invoiceForm.here") }}
            </p>
          </tooltip>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <input-field
            form-group="billingInfo"
            data-field="BIC"
            :label="$t('invoiceForm.BIC')"
            :validation="bicValidator"
          />
        </div>
        <div class="col-lg">
          <p style="margin-bottom: 0">{{ $t("invoiceForm.total") }}</p>
          <p style="margin-left: 1em">{{ invoiceSum }} {{ currency.value }}</p>
        </div>
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingInfo"
          data-field="bankReference"
          :label="$t('invoiceForm.bankReference')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingInfo"
          data-field="discount"
          :label="$t('invoiceForm.discount')"
          :validation="validateDiscount"
          :blur="formatDiscount"
        />
        <div class="col-lg form-group">
          <label class="form-label">{{ $t("invoiceForm.currency") }}</label>
          <select
            :disabled="editingDisabled"
            class="form-control form-control-sm"
            v-model="currency"
          >
            <option
              v-for="(item, index) in currencies"
              :key="index"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row col-lg">
        <div class="col-lg form-group">
          <label class="form-label">{{
            $t("invoiceForm.paymentCondition")
          }}</label>
          <select
            :disabled="editingDisabled"
            class="form-control form-control-sm"
            v-model="paymentCondition"
          >
            <option
              v-for="(item, index) in paymentConditionDays"
              :value="item.value"
              :key="index"
            >
              {{ item.name }} {{ $t("invoiceForm.daysShort") }}
            </option>
          </select>
        </div>
        <input-field
          :blur="formatPenaltyInterest"
          form-group="billingInfo"
          data-field="penaltyInterest"
          :label="$t('invoiceForm.penaltyInterest') + ' %'"
          :validation="validateNumberInput"
        />
      </div>
      <div class="row col-lg">
        <div class="form-group">
          <label class="form-label">{{
            $t("invoiceForm.invoiceLanguage")
          }}</label>
          <select
            :disabled="editingDisabled"
            class="form-control form-control-sm"
            v-model="invoiceLanguage"
          >
            <option :value="'fi'">{{ $t("invoiceForm.finland") }}</option>
            <option :value="'en'">{{ $t("invoiceForm.england") }}</option>
            <option
              v-if="userData.entryPoint === 'fakturamappen'"
              :value="'sv'"
            >
              {{ $t("invoiceForm.sweden") }}
            </option>
            <option v-if="userData.entryPoint === 'laskumappi'" :value="'svfi'">
              {{ $t("invoiceForm.sweden") }}
            </option>
          </select>
        </div>
        <div class="form-group form-group-vat">
          <label class="form-label">{{ $t("invoiceForm.VATCountry") }}</label>
          <select
            :disabled="editingDisabled"
            class="form-control form-control-sm"
            v-model="selectedTaxCategory"
          >
            <option :value="'fi'">{{ $t("countries.finland") }}</option>
            <option :value="'se'">{{ $t("countries.sweden") }}</option>
            <option :value="'gb'">{{ $t("countries.england") }}</option>
            <option :value="'nl'">{{ $t("countries.netherlands") }}</option>
            <option :value="'at'">{{ $t("countries.austria") }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <checkbox
          :propValue="creditNote"
          :isDisabled="editingDisabled"
          v-on:check="creditNote = $event"
          :label="$t('invoiceForm.creditNote')"
        />
        <checkbox
          v-if="currency.value === 'NOK' || currency.value === 'SEK'"
          :propValue="rounding"
          :isDisabled="editingDisabled"
          v-on:check="rounding = $event"
          :label="$t('invoiceForm.rounding')"
        />
        <checkbox
          v-if="userData.entryPoint === 'laskumappi'"
          :propValue="reverseVAT"
          :isDisabled="editingDisabled"
          v-on:check="reverseVAT = $event"
          :label="$t('invoiceForm.reverseVAT')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InputField from "./InputField.vue";
import Tooltip from "./Tooltip.vue";
import SelectField from "./SelectField.vue";
import CountryInput from "./CountryInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import {
  simpleValidation,
  dateValidation,
  validateNumberInput,
  validateBIC,
  countDays,
  getBicCode,
  returnAlwaysTrue,
  formatNumber,
  validateReference,
  validateYtunnus,
  validateOrgNum,
  validateDiscount,
  validateBankAccount,
  validateFinOVT,
  validateMinEight,
  validateReferenceFakturamappen,
  toNumber,
  addDays,
  spaceOutIBAN,
  calculateReferenceVerification,
  roundTwoD,
} from "../utils";
import { codes, operators } from "../assets";
import { createHelpers } from "vuex-map-fields";
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField",
});

export default {
  data() {
    return {
      paymentMethods: ["IBAN", "PLUSGIRO", "BANKGIRO", "OTHER"],
      isSafari: false,
      validateFinOVT,
      simpleValidation,
      dateValidation,
      validateNumberInput,
      validateBIC,
      returnAlwaysTrue,
      validateYtunnus,
      validateOrgNum,
      formatNumber,
      validateReference,
      validateMinEight,
      validateReferenceFakturamappen,
      codes,
      operators,
      spaceOutIBAN,
      validateDiscount,
      paymentConditionDays: [
        { name: "1", value: 1 },
        { name: "7", value: 7 },
        { name: "10", value: 10 },
        { name: "14", value: 14 },
        { name: "21", value: 21 },
        { name: "30", value: 30 },
        { name: "45", value: 45 },
        { name: "60", value: 60 },
        { name: "90", value: 90 },
      ],
    };
  },
  mounted: function () {
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );

    this.sentDate = new Date().toISOString().split("T")[0];
    this.$store.subscribeAction((action, state) => {
      if (action.type === "invoice/sendInvoice") {
        this.invalidFields.billingInfo.forEach((field) => {
          this.validations[field + "IsValid"] = false;
        });
      }
    });
  },
  computed: {
    ...mapFields([
      "invalidFields",
      "invoiceField.editingDisabled",
      "invoiceField.billingInfo.currency",
      "invoiceField.billingInfo.rounding",
      "invoiceField.billingInfo.dueDate",
      "invoiceField.billingInfo.paymentCondition",
      "invoiceField.billingInfo.paymentMethod",
      "invoiceField.billingInfo.bankAccount",
      "invoiceField.billingInfo.validations.bankAccountIsValid",
      "invoiceField.billingInfo.BIC",
      "invoiceField.billingInfo.eInvoiceAddressManual",
      "invoiceField.billingInfo.eInvoiceAddress",
      "invoiceField.billingInfo.operator",
      "invoiceField.billingInfo.discount",
      "invoiceField.billingInfo.bankReference",
      "invoiceField.billingInfo.invoiceNumber",
      "invoiceField.billingInfo.name",
      "invoiceField.billingInfo.address",
      "invoiceField.billingInfo.zipCode",
      "invoiceField.billingInfo.postal",
      "invoiceField.billingInfo.country",
      "invoiceField.billingInfo.businessId",
      "invoiceField.billingInfo.taxCode",
      "invoiceField.billingInfo.sentDate",
      "invoiceField.billingInfo.selectedTaxCategory",
      "invoiceField.billingInfo.creditNote",
      "invoiceField.billingInfo.validations",
      "invoiceField.billingInfo.reverseVAT",
      "invoiceField.billingInfo",
      "invoiceField.billingAddress",
      "userData",
      "customerRecordSelected",
      "eInvoiceAddresses",
      "rowsTotal",
      "currencies",
      "taxations",
      "bankAccounts",
      "invoiceField.billingInfo.invoiceLanguage",
    ]),
    invoiceSum: function () {
      let invoiceTotal = this.rounding
        ? this.taxations["totalRounded"]
        : this.taxations["total"];
      return formatNumber(roundTwoD(toNumber(invoiceTotal)));
    },
    display: {
      get: function () {
        return this.$store.getters["invoice/getField"](
          "sectionDisplay.billingInfo"
        );
      },
    },
    useSendereInvoiceAddress: function () {
      if (
        this.eInvoiceAddress.value.length > 0 &&
        !this.userData.sendereInvoiceAddress
      ) {
        return this.simpleValidation;
      } else {
        return null;
      }
    },

    sendingOutsideSweden: function () {
      let country = this.billingAddress.country;
      return (
        this.billingInfo.country &&
        this.billingInfo.country.value === "SE" &&
        country &&
        country.value !== "SE"
      );
    },
    today: function () {
      return new Date().toISOString().split("T")[0];
    },
    bankAccountsByType: function () {
      return this.bankAccounts.filter((acc) => this.paymentMethod === acc.Type);
    },
    countries: function () {
      return codes[this.userData.locale];
    },
    manualeInvoiceInput: function () {
      return this.eInvoiceAddress.value === "other";
    },
    sendingToEmail: function () {
      return this.eInvoiceAddress.value === "EMAIL";
    },
    isOrgNumber: function () {
      return this.country.value && this.country.value === "SE";
    },
    optionalFields: function () {
      let fields = [];
      if (this.discount === "") {
        fields.push("discount");
      }
      if (!this.sendingToEmail) {
        fields.push("receiverEmail");
      }
      if (!this.manualeInvoiceInput) {
        fields.push("eInvoiceAddressManual");
        fields.push("operator");
      }
      if (this.paymentMethod === "OTHER") {
        fields.push("BIC");
      }
      if (this.useSendereInvoiceAddress === null) {
        fields.push("sendereInvoiceAddress");
      }
      if (this.country.value !== "FI" && this.country.value !== "SE") {
        fields.push("businessId");
      }
      if (this.billingInfo.taxCode === "") {
        fields.push("taxCode");
      }

      return fields;
    },
    bicValidator: function () {
      if (this.paymentMethod === "OTHER") {
        return function (val) {
          return null;
        };
      } else {
        return this.validateBIC;
      }
    },
    businessIdValidator: function () {
      if (this.country.value) {

        if (this.country.value === "FI") {
          return this.validateYtunnus;
        } else if (this.country.value === "SE") {
          return this.validateOrgNum;
        } else {
          return null;
        }

      } else {
        return null;
      }
    },
    dueDateFromCondition: function () {
      if (this.sentDate) {
        return addDays(this.sentDate, this.paymentCondition);
      }
      return null;
    },
  },
  watch: {
    billingInfo: {
      handler: function (value) {
        let fields = [];
        for (const key of Object.keys(this.validations)) {

          // remove "IsValid" from the key
          if (this.optionalFields.indexOf(key.slice(0, -7)) === -1) {
            fields.push(key);
          }
        }

        let invalidFields = [];
        fields.forEach((field) => {
          if (this.validations[field] !== true) {
            invalidFields.push(field.slice(0, -7));
          }
        });
        let name = "billingInfo";
        let obj = { name, fields: invalidFields };
        this.addToInvalidFields(obj);
        this.$store.dispatch("invoice/saveForm");
      },
      deep: true,
    },
    bicValidator: function (val) {
      this.validations["BICIsValid"] = val(this.BIC);
    },

    sendingOutsideSweden: function (value) {
      if (value) {
        this.paymentMethod = "IBAN";
        this.paymentMethods = ["IBAN"];
        this.bankAccount = { Type: "", Value: "" };
      } else {
        this.paymentMethods = ["IBAN", "PLUSGIRO", "BANKGIRO", "OTHER"];
      }
    },
    //Deselect bankaccount on paymentMethod change
    paymentMethod: function (value) {
      if (this.bankAccount.Type !== value) {
        this.bankAccount = { Type: "", Value: "" };
      }
    },
    //Count due date
    dueDateFromCondition: {
      handler: function () {
        this.dueDate = this.dueDateFromCondition;
      },
      immediate: true,
      deep: true,
    },
    businessIdValidator: function (value) {
      this.$store.dispatch("invoice/validateField", {
        formGroup: "billingInfo",
        dataField: "businessId",
        value: value(this.businessId),
      });
    },
    //Create BIC
    bankAccount: {
      handler: function (value) {
        let valid = validateBankAccount(value.Value, this.paymentMethod);
        this.bankAccountIsValid = valid;
        if (valid) {
          if (this.paymentMethod === "IBAN") {
            this.BIC = getBicCode(value.Value);
          } else if (this.paymentMethod === "BANKGIRO") {
            this.BIC = "BGABSESS";
          } else if (this.paymentMethod !== "OTHER") {
            this.BIC = "PGSISESS";
          }
        }
      },
      deep: false,
    },
    invoiceLanguage: {
      handler: function (value) {
        if (value === "svfi") {
          value = "sv";
        }
        value = value.toUpperCase();
      },
      immediate: true,
    },
    //Open boxes if invalid field found
    validations: {
      handler: function (value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "businessId",
          "country",
        ];
        let foundInvalid = false;
        fields.forEach((name) => {
          if (this.validations[name + "IsValid"] === false) {
            foundInvalid = true;
          }
        });
        if (foundInvalid) {
          this.display["billing"] = true;
          this.display["payment"] = true;
          this.display["address"] = true;
        }
      },
      deep: true,
    },
    currency: function (val) {
      if (
        val.value === "EUR" ||
        val.value === "USD" ||
        val.value === "GBP" ||
        val.value === "CHF" ||
        val.value === "JPY" ||
        val.value === "PLN"
      ) {
        this.rounding = false;
      }
    },

    useSendereInvoiceAddress: function (value) {
      let validation = null;
      if (value) {
        validation = this.simpleValidation(
          this.billingInfo.sendereInvoiceAddress
        );
      }
      this.$store.dispatch("invoice/validateField", {
        formGroup: "billingInfo",
        dataField: "sendereInvoiceAddress",
        value: validation,
      });
    },
  },
  methods: {
    formatDiscount: function (value) {
      if (this.validateDiscount(value)) {
        this.$store.dispatch("invoice/updateField", {
          formGroup: "billingInfo",
          dataField: "discount",
          value: formatNumber(value),
        });
      }
      //this.$store.dispatch("invoice/updateSum");
    },
    invoiceNumberValidation: function (value) {
      let re = /^[0-9]+$/;
      if (value) {
        return re.test(value);
      }
      return null;
    },
    formatPenaltyInterest: function (value) {
      if (!value) {
        this.$store.dispatch("invoice/updateField", {
          formGroup: "billingInfo",
          dataField: "penaltyInterest",
          value: formatNumber("0,00"),
        });
      } else if (this.validateNumberInput(value)) {
        this.$store.dispatch("invoice/updateField", {
          formGroup: "billingInfo",
          dataField: "penaltyInterest",
          value: formatNumber(value),
        });
      }
    },
    createBankReference: function () {
      if (this.userData.entryPoint === "laskumappi") {
        this.bankReference =
          this.invoiceNumber +
          calculateReferenceVerification(this.invoiceNumber);
      }
    },

    //Change laskumappi path
    changePath: function () {
      window.parentIFrame.sendMessage(
        JSON.stringify({ type: "path", value: "tools" })
      );
    },

    checkDeletedStatus: function () {
      if (
        this.billingInfo.manualeInvoiceInput &&
        this.billingInfo.operator.value
      ) {
        this.isAddressDeleted(
          this.userData.TransferID,
          this.billingInfo.manualeInvoiceInput,
          this.billingInfo.operator
        );
      }
    },
    show: function (section) {
      let val = !this.display[section];
      this.$store.dispatch("invoice/toggleSection", {
        path: "billingInfo." + section,
        value: val,
      });
    },
    ...mapActions("invoice", ["addToInvalidFields"]),
  },
  components: {
    InputField,
    SelectField,
    CountryInput,
    Tooltip,
    Checkbox,
  },
};
</script>

<style scoped>
.tooltip-link {
  color: #0000ee;
  display: inline;
}
.tooltip-link:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.hide {
  display: none;
}
#billing-info {
  padding: 1em;
  margin-left: 0.5em;
}
p {
  text-align: left;
}
.form-group-vat {
  margin-left: 10px;
}

@media screen and (max-width: 1025px) {
  #billing-info {
    margin-left: 0em;
  }
}
@media screen and (min-width: 768px) {
  .text-field {
    margin-left: 1em;
  }
}
@media screen and (max-width: 768px) {
  .text-field {
    margin-left: 0;
  }
  #billing-info {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .row {
    padding: 0;
  }
  .form-group {
    margin: 0;
  }
  .form-group-vat {
    margin-left: 10px;
  }
}
@media screen and (max-width: 376px) {
  select {
    box-shadow: none;
  }
}
</style>
