<template>
    <div class="form-group col-lg">
        <label class="form-label">{{ label }}</label>
        <textarea
        :min="min" :maxlength="max ? max : '60'" 
        :disabled="isDisabled" 
        v-on:blur="handleBlur" 
        v-on:focus="handleFocus"
        class="form-control"  
        v-bind:type="type" 
        v-model.trim="fieldValue" 
        v-bind:placeholder="label"
        v-bind:class="{'':validationValue === null,
                      'is-valid':validationValue === true,
                      'is-invalid':validationValue === false,
                      'no-validation':validation===null}"></textarea>                    
        <tooltip v-if="tooltipObj" :visible="tooltipObj.visible" :text="tooltipObj.text" :type="tooltipObj.type" :width="tooltipObj.width"></tooltip>
    </div> 
</template>
<script>
import Tooltip from '../Tooltip.vue'
export default {
    name: 'textField',
    props: {
        formGroup: String,
        label: String,
        dataField: String,
        validation: Function,
        type: String,
        list: String,
        fix: String,
        blur: Function,
        min: String,
        max: String,
        focus: Function,
        tooltipObj: {
            default: null,
            type: Object
        },
        displayTooltip: {
            default: false,
            type: Boolean
        },
        tooltipText: {
            default: '',
            type: String
        }
    },

    computed: {
        fieldValue: {
            get() {
                return this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.' + this.dataField) 
            },
            set(value) {
                this.$store.dispatch('invoice/updateField', {formGroup:this.formGroup, dataField:this.dataField, value:value})
            }
        },
        validationValue: {
            get() {
                return this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.validations.' + this.dataField + 'IsValid')
            }
        },
        isDisabled: {
            get() {
                return this.$store.getters['invoice/getField']('invoiceField.editingDisabled')
            }
        } 
    },
    watch: { 
        fieldValue: {
            handler: function(value) {
                if (this.validation) {
                    this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:this.dataField, value:this.validation(value)})
                }
            }, deep: true, immediate: true
        }
    },
    methods: {
        handleBlur: function(event) {
            //this.fieldValue = this.fieldValue.trim()
            if (this.blur) {
                this.blur(this.fieldValue)
            }
        },
/*         mouseOver: function() {
            this.displayTooltip = !this.displayTooltip;
            console.log(this.displayTooltip)
        } */
        handleFocus: function(event) {
            if (this.focus) {
                this.focus(event)
            }
        }
    },
    components: {
        Tooltip
    }
}
</script>

<style scoped>
.form-control-sm {
    padding: 0 2em 0 0.5em;
}

.no-validation {
    padding: 0 0.5em 0 0.5em;
}
.form-group {
    margin-bottom: 0.5rem;
}

label {
    text-align: left;
    margin-bottom: 0.2rem;
}

textarea {
    border-radius: 0;
    box-shadow: 1px 1px 1px 1px black;
}

textarea:focus {
    border-radius: 0;
}


@media screen and (max-width: 425px) {
    textarea {
        width: 100%;
        margin-bottom: 1em;
        box-shadow: none;
    }

    textarea:focus {
        border-radius: 0;
    }

    .row {
        padding: 0;
    }

    .form-group {
        margin: 0;
    }



}



</style>
