export const en = {
  common: {
    invoiceWriter: "Invoice writing",
    invoice: "Invoice",
    customerRecord: "Customer record",
    productRecord: "Product record",
    send: "Send",
    delete: "Delete",
    reset: "Reset",
    new: "New",
    upload: "Upload",
    appendices: "Attachments",
    invoices: "Invoices"
  },
  trafficLight: {
    GRAY: "Could not fetch entries",
    GREEN: "No known payment delay entries for receiver",
    YELLOW: "Receiver has payment delay entries"
  },
  purchaseInvoice: {
    uploadPDF: "Please upload a PDF scan of the invoice",
    inputManually: "Manually enter purchase invoice details",
    sendToScanning: "Send to scanning service",
    ready: "Ready",
    basic: "Basic Information",
    recipient: "Payment Recipient",
    taxBase: "Tax base",
    withoutVAT: "Total without VAT",
    totalVAT: "VAT amount",
    total: "Total",
    image: "Image",
    back: "Back",
    products: "Products",
    pce: "Pce"
  },
  appendices: {
    selectFiles: "Select file(s)",
    size: "Size",
    type: "Type",
    name: "Name",
  },
  invoiceForm: {
    addAccount: "Add account",
    letterWillBeSent: "Invoice will be sent as letter",
    here: "here",
    name: "Company name",
    address: "Address",
    confirmReset: "Confirm resetting of invoice",
    contactPerson: "Contact person",
    businessId: "Business ID",
    Ytunnus: "Y-tunnus",
    orgNumber: "Organization number",
    customerId: "Customer number",
    zipCode: "ZIP code",
    postal: "City",
    country: "Country",
    bankAccount: "IBAN",
    bankReference: "Bank reference",
    orderReference: "Order reference",
    invoiceNumber: "Invoice number",
    invoiceChannel: "Invoice channel",
    sentDate: "Invoice date",
    dueDate: "Due date",
    penaltyInterest: "Penalty interest",
    discount: "Discount %",
    paymentCondition: "Payment condition",
    deliveryMethod: "Delivery method",
    billingAddress: "Billing address",
    deliveryAddress: "Delivery address",
    additionalInfo: "Additional information",
    deliveryDay: "Delivery date",
    customerAddress: "Customer address",
    paymentInfo: "Payment",
    rowInfo: "Rows",
    currency: "Currency",
    SEK: "Swedish krona",
    EUR: "Euro",
    NOK: "Norwegian krone",
    rounding: "Rounding",
    reverseVAT: "Reverse VAT",
    vatFreeText: "Reverse VAT instructions",
    defaultVatFreeText: "According to Article 8c of the AVL, the purchaser pays the VAT",
    VATCountry: "VAT country",
    creditNote: "Credit note",
    daysShort: "days",
    total: "Total",
    billingInfo: "Billing",
    eInvoiceAddress: "E-invoice address",
    eInvoiceAddressManual: "Other E-invoice address",
    eInvoiceAddrRemoved: "Not in use",
    operator: "Operator",
    addressInfo: "Addressinfo",
    paymentSolution: "Account",
    email: "Email",
    fax: "Fax",
    www: "Webpage",
    phone: "Phone number",
    changePrefillInfo: "Change your default values here",
    finland: "Finnish",
    england: "English",
    sweden: "Swedish",
    invoiceLanguage: "Invoice language",
    BIC: "BIC",
    piece: "pce",
    hour: "hour",
    noeInvoiceAddress: "No e-invoice addresses",
    noAccounts: "No accounts for user",
    sendingFail: "Sending invoice failed",
    sendingSuccess: "Invoice sent",
    invalidInput: "Check input data",
    taxCode: "VAT code",
    other: "Manual input",
    sendToPrinting: "Send to printing",
    sendToEmail: "Send to email",
    receiverEmail: "Email",
    row: "Row",
    copyRow: "Copy row",
    deleteRow: "Delete row",
    addRow: "Add row",
    sendereInvoiceAddress: "Sender eInvoice address",
    barcode: "Barcode",
    select: "Select",
    peppolAddrNotFound: "Peppol-address was not found",
    peppolInvalidFormat: "Peppol-address should begin with four digits and a colon",
    emailSendingAttachments: "Use pdf attachments when sending invoice to email"
  },
  invoice: {
    title: "INVOICE",
    titleCreditNote: "CREDIT NOTE",
    orderReference: "Your Reference",
    contactPerson: "Handled by",
    orderer: "Ordered by",
    billingAddress: "Invoice Address",
    deliveryAddress: "Delivery Address",
    paymentCondition: "Terms of Payment",
    dueDate: "Due Date",
    customerNumber: "Customer number",
    deliveryDate: "Date of Delivery",
    deliveryMethod: "Transport Mode",
    penaltyInterest: "Penalty Interest Rate",
    vatNumber: "VAT Number",
    bankReference: "Payment Reference",
    discount: "Discount",
    type: {
      eInvoice: "e-Invoice",
      Paper: "Letter",
      email: "Email"
    },
    row: {
      name: "Item",
      amount: "Amount",
      price: "Unit price",
      withoutVAT: "Net Total",
      VAT: "VAT",
      discount: "Discount",
      total: "Row Total",
      sum: "sum",
      withoutVATSum: "Total excl. VAT",
      VATSum: "Total incl. VAT",
    },
    rounding: "Rounding",
    amountToPay: "Amount to pay",
    bankInfo: "Bank details",
    phone: "Phone",
    fax: "Fax",
    email: "E-mail",
    vatNumShort: "VAT No.",
    Ytunnus: "Business ID",
    businessId: "Business ID",
    orgNumber: "Org. No.",
    flTransferClause: "Tämä saaminen on siirretty Finance Link Oy:lle. Maksun voi suorittaa pätevästi vain tilille IBAN: FI3215963000106205, BIC: NDEAFIHH. Saamiseen  kohdistuvat huomautukset on tehtävä välittömästi osoitteeseen laskutus@financelink.fi. Käsittelemme henkilötietoja kotisivuillamme financelink.fi/oikeudellista-tietoa olevan tietosuojaselosteen mukaisesti."
  },
  invoiceRecord: {
    noInvoices: "No invoices",
    invoices: "Invoices",
    useAsTemplate: "Use as template",
    delete: "Delete",
    continue: "Continue",
    incomplete: "Open",
    sent: "Sent",
    choose: "Select invoice"
  },
  product: {
    addRow: "Add row",
    name: "Product",
    code: "Code",
    ammount: "Amount",
    unit: "Unit",
    price: "Price",
    discount: "Discount %",
    VAT: "VAT",
    withoutVAT: "Without VAT",
    totalVAT: "Total VAT",
    total: "Total",
    comment: "Comment",
    delete: "Delete",
    search: "Name or code",
    searchShort: "Search",
    edit: "Edit",
    setFavorite: "Set favorite",
    save: "Save",
    cancel: "Cancel",
    back: "Back",
    moreActions: "More actions",
    add: "Add",
    removeSelected: "Remove selected",
    duplicateProduct: "Product is already in the list",
    noResults: "No results",
    overTen: "Over 10 results",
    confirmDelete: "Confirm deletion",
    table: {
      count: 'Showing {from}-{to} records|{count} results|One result',
      filter: 'Filter results:',
      filterPlaceholder: 'Search',
      limit: 'Results:',
      page: 'Page:',
      noResults: 'No results',
      filterBy: 'Search by {column}',
      loading: 'Searching...',
      defaultOption: 'Select {column}'
    },
  },
  customer: {
    setFavorite: "Set favorite",
    address: "Address",
    searchShort: "Search",
    save: "Save",
    back: "Back",
    create: "New",
    createNew: "Create",
    delete: "Delete",
    newCustomer: "New customer",
    addressType: "Address type",
    officialAddress: "Official address",
    billingAddress: "Billing address",
    deliveryAddress: "Delivery address",
    customerSearch: "Search for customer",
    eInvoiceAddress: "E-invoice address",
    customerNumber: "Customer number"
  },
  units: {
    cm: "cm",
    gram: "g",
    hour: "h",
    person: "pers.",
    kilogram: "kg",
    month: "mth",
    kilometer: "km",
    piece: "pce",
    kilowattHour: "kWh",
    liter: "l",
    box: "box",
    minute: "min",
    meter: "m",
    squareMeter: "sqm",
    cubicMeter: "cbm",
    millimeter: "mm",
    bottle: "btl",
    can: "can",
    bag: "bag",
    day: "day",
    roll: "rl",
    page: "pg",
    sack: "sck",
    series: "ser",
    tube: "tube",
    ton: "t",
    year: "yr",
    week: "wk",
  },
  countries: {
    finland: "Finland",
    sweden: "Sweden",
    england: "United Kingdom",
    netherlands: 'Netherlands',
    austria: 'Austria'
  }
}