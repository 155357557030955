<template>
  <div class="custom-datalist" v-click-outside="hideList">
    <input
      @focus="showList"
      @blur="hideList"
      @keyup.down="down"
      @keyup.enter="selectEnter(index)"
      @keyup.up="up"
      :placeholder="$t('customer.customerSearch')"
      class="custom-datalist-input form-control"
      :class="{'no-shadow':selected}"
      v-model="searchText"
      :disabled="selected || isDisabled"
    >
    <div class="search-icon">
      <i class="fa fa-search"></i>
    </div>
    <div class="selected-card" v-if="selected">
      <div class="select-text">{{ custName}}</div>
      <i @click="unselect" class="fa fa-times close"></i>
    </div>
    <div v-if="show" class="datalist">
      <div
        v-on:click.stop
        v-for="(item, key) in filteredCustomers"
        @mouseenter="mouseOver(key)"
        @mouseleave="index = null"
        :key="key"
        @click.stop="select(key)"
        class="datalist-item"
        v-bind:class="{'selected':key===index}"
        v-on:keyup.down="down(key)"
        v-on:keyup.up="up(key)"
      >
        {{item.customerNumber}}
        <div class="item-name-wrap">
          <span class="item-name">{{item.name}}</span>
        </div>
        <i class="fa fa-star yellow" v-if="item.isFavorite"></i>
      </div>
      <!--  <div v-if="loading">{{ $t("product.table.loading")}}</div> -->
      <div v-if="filteredCustomers.length === 0">{{ $t("product.noResults") }}</div>
    </div>
  </div>
</template>
<script>
import { pvAddressesFI, pvAddressesEN, pvAddressesSV } from "@/utils/addresses"
export default {
  name: "CustomerSelectInput",
  props: ["input", "valid", "disabled", "selected"],
/*   mounted: function() {
    this.$store.dispatch("customer/fetchCustomers", this.token).then(res => {
      this.customers.push(...res);
    });
  }, */
  data() {
    return {
      placeholder: {customerNumber: "", name: ""},
      show: false,
      index: null,
     // selected: null,
      loading: false,
      searchText: "",
      customers: [],
      total: 0
    };
  },
  computed: {
          isDisabled: {
          get() {
              return this.$store.getters['invoice/getField']('invoiceField.editingDisabled')
          }
    },
    token: function() {
      return this.$store.getters["invoice/getField"]("userData.authToken");
    },
    custName: function() {
      return typeof this.selected === 'object' ? this.selected.customerNumber + " " + this.selected.name : this.placeholder.customerNumber + " " + this.placeholder.name
    },
    upperBound: function() {
      return this.customers.length - 1;
    },
    filteredCustomers: function() {
      if (this.searchText) {
        return this.customers.filter(c => {
          return (
            c.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
            c.customerNumber.toString().includes(this.searchText)
          );
        });
      } else {
        return [...this.customers].sort(function(a, b) {
          let favorite = 0;
          let num = 0;
          if (a.isFavorite && !b.isFavorite) {
            favorite = -1;
          } else if (!a.isFavorite && b.isFavorite) {
            favorite = 1;
          }

          if (a.customerNumber < b.customerNumber) {
            num = -1;
          } else if (a.customerNumber > b.customerNumber) {
            num = 1;
          }

          if (favorite === 1) {
            return 1;
          } else if (favorite === -1) {
            return -1;
          } else if (favorite === 0 && num === 1) {
            return 1;
          } else if (favorite === 0 && num === -1) {
            return -1;
          } else if (favorite === 0 && num === 0) {
            return 0;
          } else {
            return 0;
          }
        });
      }
    }
  },
  methods: {
    down: function(key) {
      if (this.index === null) {
        this.index = 0;
      } else if (this.index < this.upperBound) {
        this.index++;
      }
    },
    up: function(key) {
      if (this.index > 0) {
        this.index--;
      }
    },
    mouseOver: function(key) {
      this.index = key;
    },
    showList: function() {
      this.show = true;
      this.index = null;
      let locale = this.$store.state.invoice.userData.locale || 'fi'
      this.customers = locale === 'fi' ? pvAddressesFI : locale === 'en' ? pvAddressesEN : pvAddressesSV
    },
    hideList: function() {
      if (this.index === null) {
        this.show = false;
        this.index = null;
      }
    },
    selectEnter: function(key) {
      if (key !== null) {
        this.select(key)
      }
    },
    select: function(key) {
     this.placeholder = this.filteredCustomers[key];
      this.$store.dispatch("invoice/fetchSelectedCustomerRecord", {
        number: this.filteredCustomers[key].customerNumber
      });
      this.index = null;
      this.hideList();
    },
    unselect: function() {
      if (!this.isDisabled) {
this.placeholder = {customerNumber: "", name: ""}
      this.$store.commit("invoice/DESELECT_CUSTOMER_RECORD")
      this.$store.dispatch("invoice/saveForm", null)
      }
      
    }
  }
};
</script>

<style scoped>
.custom-datalist:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}

.selected-card {
  display: flex;
  flex-direction: row;
  padding: 3px 10px 3px 10px;
  border-radius: 20px;
  background: gray;
  position: absolute;
  top: 0px;
  width: 100%;
  box-shadow: 1px 2px 1px 0px black;
  
}
.selected-card .close {
  font-size: 16px;
}
.select-text {
  color: white;
  text-overflow: ellipsis;
    overflow: hidden;
  white-space: nowrap;
}

.selected-card i {
  margin-left: auto;
  margin-top: 5px;
}
.yellow {
  color: #f5cc27;
  line-height: 1.3;
}
.selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.custom-datalist {
  position: relative;
  width: 100%;
}
.datalist {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  z-index: 1;
  position: absolute;
  top: 34px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 2px 4px 6px hsla(0, 0%, 0%, 0.4);
  background-color: white;
  border-radius: 5px;
}
.datalist-item {
  display: flex;
  flex-direction: row;
  /*   display: block;
 */
  text-align: left;
  padding: 3px 10px 3px 10px;
  color: #41474e;
}
.item-name {
  font-size: 12px;
  /*   text-overflow: ellipsis;
    overflow: hidden;
  white-space: nowrap; */
  color: #76787a;
}

.item-name-wrap {
  margin-left: 5px;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.custom-datalist-input {
  width: 100%;
  color: #495057;
  height: 31.5px;
  border-radius: 20px;
  padding: 0 2em 0 1em;
  font-size: 0.875rem;
  line-height: 1.5;
  box-shadow: 1px 2px 1px 0px black;
  margin-bottom: 0.5rem;
}

.no-shadow {
  box-shadow: none;
}
.custom-datalist-input:focus {
  box-shadow: none;
}
.list-end-item {
  color: #41474e;
  font-size: 14px;
}
.search-field > input {
  width: 100%;
}
.search-field:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 2.5px;
  font-size: 20px;
  color: hsla(0, 0%, 0%, 0.4);
}

@media screen and (max-width: 376px){
  .custom-datalist-input {
  box-shadow: none;
  }

}
</style>
