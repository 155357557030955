<template>
    <div class="form-group col-lg" v-bind:class="{'date-field':type==='date'}">
        <label class="form-label">{{ label }}</label>
        <input 
        :min="min" maxlength="60" 
        class="form-control form-control-sm"  
        v-bind:type="type" 
        v-bind:value="value"
        :placeholder="label"
        v-on:input="$emit('input', $event.target.value)" 
        v-bind:class="{'':valid === null,
                      'is-valid':valid === true,
                      'is-invalid':valid === false,
                      'no-validation':validation===null}">                    
    </div> 
</template>
<script>
export default {
    name: 'inputField',
    props: ['value', 'valid', 'formGroup', 'label', 'dataField', 'validation', 'validator', 'type', 'list', 'fix', 'blur', 'min'], 

    watch: { 
        value: { 
            handler:function() {
            if (this.validator) {
                this.$emit('validate', this.validator(this.value))
            }
        }, immediate: true
        }
    },
    methods: {
        handleBlur: function(event) {
            this.fieldValue = this.fieldValue.trim()
            if (this.blur) {
                this.blur(this.fieldValue)
            }
        }
    }
}
</script>

<style scoped>
.form-control-sm {
    padding: 0 2em 0 0.5em;
    height: 1.5em;
}

.no-validation {
    padding: 0 0.5em 0 0.5em;
}
.form-group {
    margin-bottom: 0.5rem;
}

label {
    text-align: left;
    margin-bottom: 0.2rem;
}

input {
    border-radius: 0;
    box-shadow: 1px 1px 1px 1px black;
    height: 1.5em;
}

input:focus {
    border-radius: 0;
}


@media screen and (max-width: 425px) {
    input {
        width: 100%;
        margin-bottom: 1em;
        box-shadow: none;
    }

    input:focus {
        border-radius: 0;
    }

    .row {
        padding: 0;
    }

    .form-group {
        margin: 0;
    }



}



</style>
