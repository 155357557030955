<template>
  <div id="billing-info" class="form-container col-lg">
    <h4 style="text-align:left;" v-on:click="show('address')" class="opener-link">
      {{ $t("invoiceForm.addressInfo") }}
      <i
        style="font-size:20px;float:right;margin-top:0.5em;"
        v-if="display['address']"
        class="fa fa-minus"
      ></i>
      <i style="font-size:20px;float:right;margin-top:0.5em;" v-else class="fa fa-plus"></i>
    </h4>
    <div v-bind:class="{'':display['address'], 'hide':!display['address']}">
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="name"
          :label="$t('invoiceForm.name')"
          :validation="simpleValidation"
        />
        <input-field
          form-group="billingInfo"
          data-field="address"
          :label="$t('invoiceForm.address')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="zipCode"
          :label="$t('invoiceForm.zipCode')"
          :validation="simpleValidation"
        />
        <input-field
          form-group="billingInfo"
          data-field="postal"
          :label="$t('invoiceForm.postal')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="businessId"
          :label="$t('invoiceForm.businessId')"
          :validation="businessIdValidator"
        />
        <select-field
          v-if="isSafari"
          form-group="billingInfo"
          data-field="country"
          :options="countries"
          :validation="simpleValidation"
        />
        <country-input v-else form-group="billingInfo" :countries="countries"/>
      </div>
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="taxCode"
          :label="$t('invoiceForm.taxCode')"
          :validation="vatIdValidator"
        />
      </div>
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="email"
          :label="$t('invoiceForm.email')"
          :validation="null"
        />
        <input-field
          form-group="billingInfo"
          data-field="phone"
          :label="$t('invoiceForm.phone')"
          :validation="null"
        />
      </div>
      <div class="row">
        <input-field
          form-group="billingInfo"
          data-field="www"
          :label="$t('invoiceForm.www')"
          :validation="null"
        />
        <input-field
          form-group="billingInfo"
          data-field="fax"
          :label="$t('invoiceForm.fax')"
          :validation="null"
        />
      </div>
            <div :class="{'hide':!useSendereInvoiceAddress}" class="row col-lg">
        <input-field
          form-group="billingInfo"
          data-field="sendereInvoiceAddress"
          :label="$t('invoiceForm.sendereInvoiceAddress')"
          :validation="useSendereInvoiceAddress"
        />
      </div>
    </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import InputField from "./InputField.vue";
import Tooltip from "./Tooltip.vue";
import SelectField from "./SelectField.vue";
import CountryInput from "./CountryInput.vue";
import {
  simpleValidation,
  validateYtunnus,
  validateOrgNum,
  validateFinOVT,
  validateMinEight,
  validateVatSimple
} from "../utils";
import { codes } from "../assets";
import { createHelpers } from "vuex-map-fields";
import moment from "moment";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  data() {
    return {
      isSafari: false,
      validateFinOVT,
      simpleValidation,
      validateYtunnus,
      validateOrgNum,
      validateMinEight,
      validateVatSimple,
      codes,
    };
  },
  mounted: function() {
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );
  },
  computed: {
    ...mapFields([
      "invalidFields",
      "invoiceField.editingDisabled",
      "invoiceField.billingInfo.name",
      "invoiceField.billingInfo.eInvoiceAddress",
      "invoiceField.billingInfo.address",
      "invoiceField.billingInfo.zipCode",
      "invoiceField.billingInfo.postal",
      "invoiceField.billingInfo.country",
      "invoiceField.billingInfo.businessId",
      "invoiceField.billingInfo.taxCode",
      "invoiceField.billingInfo.validations",
      "invoiceField.billingInfo",
      "invoiceField.billingAddress",
      "userData",
      "customerRecordSelected",
      "eInvoiceAddresses",
      "rowsTotal",
      "currencies",
      "taxations",
      "bankAccounts",
      "invoiceField.billingInfo.invoiceLanguage"
    ]),
    display: {
      get: function() {
        return this.$store.getters["invoice/getField"](
          "sectionDisplay.billingInfo"
        );
      }
    },
    useSendereInvoiceAddress: function() {
      if (
        this.eInvoiceAddress.value.length > 0 &&
        !this.userData.sendereInvoiceAddress
      ) {
        return this.simpleValidation;
      } else {
        return null;
      }
    },

    countries: function() {
      return codes[this.userData.locale];
    },
  
    isOrgNumber: function() {
      return this.country.value && this.country.value === "SE";
    },
  
    businessIdValidator: function () {
      if (this.country.value) {

        if (this.country.value === "FI") {
          return this.validateYtunnus;
        } else if (this.country.value === "SE") {
          return this.validateOrgNum;
        } else {
          return null;
        }
        
      } else {
        return null;
      }
    },
    
    vatIdValidator: function() {
      return this.validateVatSimple;
    }
  },
  watch: {

    businessIdValidator: function(value) {
      this.$store.dispatch("invoice/validateField", {
        formGroup: "billingInfo",
        dataField: "businessId",
        value: value(this.businessId)
      });
    },
    vatIdValidator: function(value) {
      this.$store.dispatch("invoice/validateField", {
        formGroup: "billingInfo",
        dataField: "taxCode",
        value: value(this.taxCode)
      });
    },

    //Open boxes if invalid field found
    validations: {
      handler: function(value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "businessId",
          "taxCode",
          "country"
        ];
        let foundInvalid = false;
        fields.forEach(name => {
          if (this.validations[name + "IsValid"] === false) {
            foundInvalid = true;
          }
        });
        if (foundInvalid) {
          this.display["billing"] = true;
          this.display["payment"] = true;
          this.display["address"] = true;
        }
      },
      deep: true
    },
    useSendereInvoiceAddress: function(value) {
      let validation = null;
      if (value) {
        validation = this.simpleValidation(
          this.billingInfo.sendereInvoiceAddress
        );
      }
      this.$store.dispatch("invoice/validateField", {
        formGroup: "billingInfo",
        dataField: "sendereInvoiceAddress",
        value: validation
      });
    }
  },
  methods: {
    show: function(section) {
      let val = !this.display[section];
      this.$store.dispatch("invoice/toggleSection", {
        path: "billingInfo." + section,
        value: val
      });
    },
    ...mapActions("invoice", ["addToInvalidFields"])
  },
  components: {
    InputField,
    SelectField,
    CountryInput,
    Tooltip,
  }
};
</script>

<style scoped>
.tooltip-link {
  color: #0000ee;
  display: inline;
}
.tooltip-link:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.hide {
  display: none;
}
#billing-info {
  padding: 1em;
  margin-left: 0.5em;
}
p {
  text-align: left;
}
.form-group-vat {
  margin-left: 10px;
}

@media screen and (max-width: 1025px) {
  #billing-info {
    margin-left: 0em;
  }
}
@media screen and (min-width: 768px) {
  .text-field {
    margin-left: 1em;
  }
}
@media screen and (max-width: 768px) {
  .text-field {
    margin-left: 0;
  }
  #billing-info {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .row {
    padding: 0;
  }
  .form-group {
    margin: 0;
  }
  .form-group-vat {
    margin-left: 10px;
  }
}
@media screen and (max-width: 376px) {
  select {
    box-shadow: none;
  }
}
</style>
