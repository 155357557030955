export const svfi = {
  common: {
    invoiceWriter: "Fakturan skrivs",
    invoice: "Faktura",
    customerRecord: "Kund",
    productRecord: "Produktrekord",
    send: "Skicka",
    delete: "Ta bort",
    reset: "Återställ",
    new: "Ny",
    upload: "Ladda upp",
    appendices: "Bilagor",
    invoices: "Fakturor"
  },
  trafficLight: {
    GRAY: "Inga poster hittades",
    GREEN: "Inga kända försenade betalningar för mottagaren",
    YELLOW: "Mottagaren har försenade betalningar"
  },
  purchaseInvoice: {
    uploadPDF: "Ladda upp en inskannad bild i PDF-format",
    inputManually: "Mata in fakturans information för hand",
    sendToScanning: "Skicka PDF till skanningstjänsten",
    ready: "Färdig",
    basic: "Grundinformation",
    recipient: "Betalningsmottagare",
    taxBase: "Skattesats",
    withoutVAT: "Totalt utan skatt",
    totalVAT: "Skatt",
    total: "Totalt",
    image: "Bild",
    back: "Tillbaka",
    products: "Produkter",
    pce: "St"
  },
  appendices: {
    selectFiles: "Välj fil(er)",
    size: "Filstorlek",
    type: "Filtyp",
    name: "Filnamn"
  },
  invoiceForm: {
    addAccount: "Lägg till konton",
    here: "här",
    name: "Företagets namn",
    address: "Adress",
    appendices: "Liitteet",
    confirmReset: "Bekräfta återställandet av fakturan",
    contactPerson: "Kontaktperson",
    businessId: "FO-nummer",
    Ytunnus: "FO-nummer",
    orgNumber: "Orgnummer",
    customerId: "Kundnummer",
    zipCode: "Postnummer",
    postal: "Stad",
    country: "Land",
    bankAccount: "Kontonummer",
    bankReference: "Betalningsreferens",
    orderReference: "Orderreferens",
    invoiceNumber: "Fakturanummer",
    invoiceChannel: "Fakturakanal",
    sentDate: "Fakturadatum",
    dueDate: "Förfallodatum",
    penaltyInterest: "Förseningsränta",
    discount: "Rabatt %",
    paymentCondition: "Betalningsvillkor",
    deliveryMethod: "Leveransmetod",
    billingAddress: "Fakturaadress",
    deliveryAddress: "Leveransadress",
    additionalInfo: "Ytterligare information",
    deliveryDay: "Leveransdag",
    customerAddress: "Kundens adress",
    paymentInfo: "Betalning",
    rowInfo: "Rader",
    currency: "Valuta",
    SEK: "Svensk krona",
    EUR: "Euro",
    NOK: "Norska kronan",
    rounding: "Avrundning",
    creditNote: "Kreditnota",
    reverseVAT: "Omvänd moms",
    vatFreeText: "Skälet för omvänd momsskyldighet",
    defaultVatFreeText: "Köparen är skatteskyldig enligt MomsL § 8c",
    VATCountry: "Land för moms",
    daysShort: "dagar",
    total: "Totalt",
    billingInfo: "Fakturering",
    eInvoiceAddress: "e-fakturaadress",
    eInvoiceAddressManual: "e-fakturaadress",
    eInvoiceAddrRemoved: "Används inte",
    operator: "Operator",
    addressInfo: "Vår adress",
    paymentSolution: "Typ",
    email: "E-postadress",
    fax: "Fax",
    www: "Webbsida",
    phone: "Telefon",
    changePrefillInfo: "Ändra din förvalda information här",
    finland: "Finska",
    england: "Engelska",
    sweden: "Svenska",
    invoiceLanguage: "Språk av faktura",
    BIC: "BIC",
    piece: "st",
    hour: "timme",
    noeInvoiceAddress: "Inga efakturaadresses",
    noAccounts: "No accounts for user",
    sendingFail: "Fakturan kunde inte skickas",
    sendingSuccess: "Fakturan har skickats",
    invalidInput: "Kontrollera fakturans innehåll",
    taxCode: "Momsnummer",
    other: "Skriv in manuellt",
    sendToPrinting: "Skicka till printing",
    sendToEmail: "Skicka till email",
    receiverEmail: "Email",
    row: "Rad",
    copyRow: "Kopiera rad",
    deleteRow: "Radera raden",
    addRow: "Läg till rad",
    sendereInvoiceAddress: "Avsändarens e-fakturaadress",
    barcode: "Streckkod",
    select: "Välj",
    letterWillBeSent: "Fakturan kommer att skickas som pappersbrev på posten",
    peppolAddrNotFound: "PEPPOL-adressen finns inte i PEPPOL-registret",
    peppolInvalidFormat: "PEPPOL-adresser måste börja med en fyrsiffrig kod följt av ett kolon (0007: eller 0088: i Sverige).",
    emailSendingAttachments: "Använd pdf-bilagor när du skickar fakturor med e-post"
  },
  invoice: {
    title: "FAKTURA",
    titleCreditNote: "KREDITNOTA",
    orderReference: "Er referens",
    contactPerson: "Vår kontakt",
    orderer: "Beställare",
    billingAddress: "Faktureringsadress",
    deliveryAddress: "Leveransadress",
    paymentCondition: "Betalningsvillkor",
    dueDate: "Förfallodatum",
    customerNumber: "Kundnummer",
    deliveryDate: "Leveransdatum",
    deliveryMethod: "Transport",
    penaltyInterest: "Förseningsränta",
    vatNumber: "Momsnummer",
    bankReference: "Betalningsreferens",
    discount: "Rabatt",
    type: {
      eInvoice: "e-Faktura",
      Paper: "Brev",
      email: "Email"
    },
    row: {
      name: "Artikel",
      amount: "Antal",
      price: "Á-pris",
      withoutVAT: "Netto",
      VAT: "Moms",
      discount: "Rabatt",
      total: "Totalt",
      sum: "sum",
      withoutVATSum: "Totalt exkl. Moms",
      VATSum: "Totalt inkl. Moms",
    },
    rounding: "Avrundning",
    amountToPay: "Summa att betala",
    bankInfo: "Bankförbindelse",
    phone: "Telefon",
    fax: "Fax",
    email: "E-post",
    vatNumShort: "VAT Nr",
    businessId: "FO-Nr",
    Ytunnus: "FO-Nr",
    orgNumber: "Orgnummer",
    flTransferClause: "Tämä saaminen on siirretty Finance Link Oy:lle. Maksun voi suorittaa pätevästi vain tilille IBAN: FI3215963000106205, BIC: NDEAFIHH. Saamiseen  kohdistuvat huomautukset on tehtävä välittömästi osoitteeseen laskutus@financelink.fi. Käsittelemme henkilötietoja kotisivuillamme financelink.fi/oikeudellista-tietoa olevan tietosuojaselosteen mukaisesti."
  },
  invoiceRecord: {
    noInvoices: "Inga fakturor",
    invoices: "Fakturor",
    useAsTemplate: "Använd som mall",
    delete: "Radera",
    continue: "Fortsätt",
    incomplete: "Öppna",
    sent: "Skickad",
    choose: "Välj faktura"
  },
  product: {
    addRow: "Lägg till rad",
    name: "Produkt",
    code: "Kod",
    ammount: "Antal",
    unit: "Enhet",
    price: "Pris",
    discount: "Rabatt %",
    VAT: "Moms",
    withoutVAT: "Utan moms",
    totalVAT: "Total moms",
    total: "Totalt",
    comment: "Kommentar",
    delete: "Ta bort",
    search: "Namn eller kod",
    searchShort: "Sök",
    edit: "Redigera",
    setFavorite: "Ange som favorit",
    save: "Spara",
    cancel: "Avbryt",
    back: "Tillbaka",
    moreActions: "Fler funktioner",
    add: "Lägg till",
    duplicateProduct: "Produkten finns redan i listan",
    removeSelected: "Ta bort valda",
    noResults: "Inga resultat",
    overTen: "Över 10 resultat",
    confirmDelete: "Bekräfta radering",
    table: {
      count: 'Visar {from}-{to} rader|{count} resultat|Ett resultat',
      filter: 'Filtrera resultat:',
      filterPlaceholder: 'Sök',
      limit: 'Resultat:',
      page: 'Sida:',
      noResults: 'Inga resultat',
      filterBy: 'Sök med {column}',
      loading: 'Söker...',
      defaultOption: 'Välj {column}'
    },
  },
  customer: {
    setFavorite: "Ange som favorit",
    address: "Adress",
    searchShort: "Sök",
    save: "Spara",
    back: "Tillbaka",
    create: "Ny",
    createNew: "Skapa",
    delete: "Ta bort",
    newCustomer: "Ny kund",
    addressType: "Adresstyp",
    officialAddress: "Officiell adress",
    billingAddress: "Faktureringsadress",
    deliveryAddress: "Leveransadress",
    customerSearch: "Sök efter kund",
    eInvoiceAddress: "e-fakturaadress",
    customerNumber: "Kundnummer"
  },
  units: {
    cm: "cm",
    gram: "g",
    hour: "timme",
    person: "pers.",
    kilogram: "kg",
    month: "mån",
    kilometer: "km",
    piece: "st",
    kilowattHour: "kWh",
    liter: "l",
    box: "ruta",
    minute: "min",
    meter: "m",
    squareMeter: "m2",
    cubicMeter: "m3",
    millimeter: "mm",
    bottle: "flaska",
    can: "burk",
    bag: "väska",
    day: "dag",
    roll: "roll",
    page: "sida",
    sack: "säck",
    series: "serie",
    tube: "tub",
    ton: "ton",
    year: "år",
    week: "vecka",
  },
  countries: {
    finland: "Finland",
    sweden: "Sverige",
    england: "Storbritannien",
    netherlands: 'Nederländerna',
    austria: 'Österrike'
  }
}