export const pvAddressesFI = [
    { customerNumber: 1, name: "Pääesikunta", eInvoiceAddress: "00370952029910000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Pääesikunta", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 2, name: "Puolustusvoimien Johtamisjärjestelmäkeskus", eInvoiceAddress: "00370952029910120", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien Johtamisjärjestelmäkeskus", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 3, name: "Maanpuolustuskorkeakoulu", eInvoiceAddress: "00370952029910210", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Maanpuolustuskorkeakoulu", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 4, name: "Sotilaslääketieteenkeskus", eInvoiceAddress: "00370952029910270", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Sotilaslääketieteenkeskus", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 5, name: "Merivoimien esikunta", eInvoiceAddress: "00370952029958000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Merivoimien esikunta", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 6, name: "Uudenmaan prikaati", eInvoiceAddress: "00370952029951200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Uudenmaan prikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 7, name: "Merisotakoulu", eInvoiceAddress: "00370952029958200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Merisotakoulu", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 8, name: "Maavoimien esikunta", eInvoiceAddress: "00370952029993400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Maavoimien esikunta", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 9, name: "Maasotakoulu", eInvoiceAddress: "00370952029990330", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Maasotakoulu", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 10, name: "Utin jääkärirykmentti", eInvoiceAddress: "00370952029993300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Utin jääkärirykmentti", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 11, name: "Karjalan prikaati", eInvoiceAddress: "00370952029993100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karjalan prikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 12, name: "Kainuun prikaati", eInvoiceAddress: "00370952029992200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kainuun prikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 13, name: "Jääkäriprikaati", eInvoiceAddress: "00370952029992300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Jääkäriprikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 14, name: "Porin prikaati", eInvoiceAddress: "00370952029994100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Porin prikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 15, name: "Panssariprikaati", eInvoiceAddress: "00370952029991100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Panssariprikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 16, name: "Kaartin jääkärirykmentti", eInvoiceAddress: "00370952029991800", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kaartin jääkärirykmentti", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 17, name: "Ilmavoimien Esikunta", eInvoiceAddress: "00370952029988500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Ilmavoimien Esikunta", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 18, name: "Lapin lennosto", eInvoiceAddress: "00370952029988610", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Lapin lennosto", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 19, name: "Satakunnan lennosto", eInvoiceAddress: "00370952029988630", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Satakunnan lennosto", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 20, name: "Karjalan lennosto", eInvoiceAddress: "00370952029988650", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karjalan lennosto", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 21, name: "Ilmasotakoulu", eInvoiceAddress: "00370952029988710", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Ilmasotakoulu", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 22, name: "Räjähdekeskus", eInvoiceAddress: "00370952029960690", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Räjähdekeskus", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 23, name: "Puolustusvoimien Tiedustelulaitos", eInvoiceAddress: "00370952029910400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien Tiedustelulaitos", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 24, name: "Puolustusvoimien Tutkimuslaitos", eInvoiceAddress: "00370952029910500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien Tutkimuslaitos", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 25, name: "Puolustusvoimien palvelukeskus", eInvoiceAddress: "00370952029910300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien palvelukeskus", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 26, name: "Puolustusvoimien palvelukeskus kansainvälinen sektori", eInvoiceAddress: "00370952029910310", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien palvelukeskus kansainvälinen sektori", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 27, name: "Rannikkoprikaati", eInvoiceAddress: "00370952029958250", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Rannikkoprikaati", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 28, name: "Rannikkolaivasto", eInvoiceAddress: "00370952029958100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Rannikkolaivasto", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 29, name: "Puolustusvoimien logistiikkalaitoksen esikunta", eInvoiceAddress: "00370952029966100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Puolustusvoimien logistiikkalaitoksen esikunta", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 30, name: "1. Logistiikkarykmentti", eInvoiceAddress: "00370952029966400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "1. Logistiikkarykmentti", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 31, name: "2. Logistiikkarykmentti", eInvoiceAddress: "00370952029966500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "2. Logistiikkarykmentti", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 32, name: "3. Logistiikkarykmentti", eInvoiceAddress: "00370952029966600", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "3. Logistiikkarykmentti", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 33, name: "Järjestelmäkeskus", eInvoiceAddress: "00370952029966200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Järjestelmäkeskus", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 34, name: "Logistiikkakoulu", eInvoiceAddress: "00370952029966180", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Logistiikkakoulu", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 35, name: "Huoltovarmuuskeskus", eInvoiceAddress: "003702024196", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Huoltovarmuuskeskus", address: "Aleksanterinkatu 48 A", postal: "HELSINKI", zipCode: "00100", country: "FI", businessId: "0202419-6" }] }
]

export const pvAddressesEN = [
    { customerNumber: 1, name: "Defence Command Finland", eInvoiceAddress: "00370952029910000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Defence Command Finland", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 2, name: "Finnish Defence Forces C5 Agency", eInvoiceAddress: "00370952029910120", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Finnish Defence Forces C5 Agency", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 3, name: "National Defence University", eInvoiceAddress: "00370952029910210", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "National Defence University", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 4, name: "Centre for Military Medicine", eInvoiceAddress: "00370952029910270", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Centre for Military Medicine", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 5, name: "Navy Command Finland", eInvoiceAddress: "00370952029958000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Navy Command Finland", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 6, name: "Nyland Brigade", eInvoiceAddress: "00370952029951200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Nyland Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 7, name: "Naval Academy", eInvoiceAddress: "00370952029958200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Naval Academy", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 8, name: "Army Command Finland", eInvoiceAddress: "00370952029993400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Army Command Finland", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 9, name: "Army Academy", eInvoiceAddress: "00370952029990330", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Army Academy", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 10, name: "Utti Jaeger Regiment", eInvoiceAddress: "00370952029993300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Utti Jaeger Regiment", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 11, name: "Karelia Brigade", eInvoiceAddress: "00370952029993100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karelia Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 12, name: "Kainuu Brigade", eInvoiceAddress: "00370952029992200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kainuu Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 13, name: "Jaeger Brigade", eInvoiceAddress: "00370952029992300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Jaeger Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 14, name: "Pori Brigade", eInvoiceAddress: "00370952029994100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Pori Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 15, name: "Armoured Brigade", eInvoiceAddress: "00370952029991100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Armoured Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 16, name: "Guard Jaeger Regiment", eInvoiceAddress: "00370952029991800", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Guard Jaeger Regiment", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 17, name: "Air Force Command Finland", eInvoiceAddress: "00370952029988500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Air Force Command Finland", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 18, name: "Lapland Air Command", eInvoiceAddress: "00370952029988610", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Lapland Air Command", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 19, name: "Satakunta Air Command", eInvoiceAddress: "00370952029988630", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Satakunta Air Command", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 20, name: "Karelia Air Command", eInvoiceAddress: "00370952029988650", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karelia Air Command", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 21, name: "Air Force Academy", eInvoiceAddress: "00370952029988710", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Air Force Academy", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 22, name: "Explosives Centre", eInvoiceAddress: "00370952029960690", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Explosives Centre", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 23, name: "Finnish Defence Intelligence Agency", eInvoiceAddress: "00370952029910400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Finnish Defence Intelligence Agency", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 24, name: "Finnish Defence Research Agency", eInvoiceAddress: "00370952029910500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Finnish Defence Research Agency", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 25, name: "Defence Forces Shared Services Centre", eInvoiceAddress: "00370952029910300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Defence Forces Shared Services Centre", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 26, name: "Defence Forces Shared Services Centre/ International Branch", eInvoiceAddress: "00370952029910310", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Defence Forces Shared Services Centre/ International Branch", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 27, name: "Coastal Brigade", eInvoiceAddress: "00370952029958250", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Coastal Brigade", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 28, name: "Coastal Fleet", eInvoiceAddress: "00370952029958100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Coastal Fleet", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 29, name: "Defence Forces Logistics Command Headquarters", eInvoiceAddress: "00370952029966100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Defence Forces Logistics Command Headquarters", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 30, name: "1st Logistics Regiment", eInvoiceAddress: "00370952029966400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "1st Logistics Regiment", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 31, name: "2nd Logistics Regiment", eInvoiceAddress: "00370952029966500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "2nd Logistics Regiment", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 32, name: "3rd Logistics Regiment", eInvoiceAddress: "00370952029966600", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "3rd Logistics Regiment", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 33, name: "Joint Systems Centre", eInvoiceAddress: "00370952029966200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Joint Systems Centre", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 34, name: "Logistics School", eInvoiceAddress: "00370952029966180", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Logistics School", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 35, name: "Huoltovarmuuskeskus", eInvoiceAddress: "003702024196", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Huoltovarmuuskeskus", address: "Aleksanterinkatu 48 A", postal: "HELSINKI", zipCode: "00100", country: "FI", businessId: "0202419-6" }] }
]

export const pvAddressesSV = [
    { customerNumber: 1, name: "Huvudstaben", eInvoiceAddress: "00370952029910000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Huvudstaben", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 2, name: "Försvarsmaktens ledningssystemcenter", eInvoiceAddress: "00370952029910120", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarsmaktens ledningssystemcenter", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 3, name: "Försvarshögskolan", eInvoiceAddress: "00370952029910210", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarshögskolan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 4, name: "Centret för militärmedicin", eInvoiceAddress: "00370952029910270", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Centret för militärmedicin", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 5, name: "Marinstaben", eInvoiceAddress: "00370952029958000", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Marinstaben", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 6, name: "Nylands brigad", eInvoiceAddress: "00370952029951200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Nylands brigad", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 7, name: "Sjökrigsskolan", eInvoiceAddress: "00370952029958200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Sjökrigsskolan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 8, name: "Arméstaben", eInvoiceAddress: "00370952029993400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Arméstaben", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 9, name: "Markstridsskolan", eInvoiceAddress: "00370952029990330", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Markstridsskolan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 10, name: "Uttis jägarregemente", eInvoiceAddress: "00370952029993300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Uttis jägarregemente", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 11, name: "Karelska brigaden", eInvoiceAddress: "00370952029993100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karelska brigaden", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 12, name: "Kajanalands brigad", eInvoiceAddress: "00370952029992200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kajanalands brigad", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 13, name: "Jägarbrigaden", eInvoiceAddress: "00370952029992300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Jägarbrigaden", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 14, name: "Björneborgs brigad", eInvoiceAddress: "00370952029994100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Björneborgs brigad", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 15, name: "Pansarbrigaden", eInvoiceAddress: "00370952029991100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Pansarbrigaden", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 16, name: "Gardesjägarregementet", eInvoiceAddress: "00370952029991800", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Gardesjägarregementet", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 17, name: "Flygstaben", eInvoiceAddress: "00370952029988500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Flygstaben", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 18, name: "Lapplands flygflottilj", eInvoiceAddress: "00370952029988610", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Lapplands flygflottilj", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 19, name: "Satakunta flygflottilj", eInvoiceAddress: "00370952029988630", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Satakunnan lennosto", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 20, name: "Karelens flygflottilj", eInvoiceAddress: "00370952029988650", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Karelens flygflottilj", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 21, name: "Luftkrigsskolan", eInvoiceAddress: "00370952029988710", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Luftkrigsskolan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 22, name: "Sprängmedelscentret", eInvoiceAddress: "00370952029960690", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Sprängmedelscentret", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 23, name: "Försvarsmaktens underrättelsetjänst", eInvoiceAddress: "00370952029910400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarsmaktens underrättelsetjänst", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 24, name: "Försvarsmaktens forskningsanstalt", eInvoiceAddress: "00370952029910500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarsmaktens forskningsanstalt", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 25, name: "Försvarsmaktens servicecenter", eInvoiceAddress: "00370952029910300", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarsmaktens servicecenter", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 26, name: "Försvarsmaktens servicecenter/internationella sektorn", eInvoiceAddress: "00370952029910310", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Försvarsmaktens servicecenter/internationella sektorn", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 27, name: "Kustbrigaden", eInvoiceAddress: "00370952029958250", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kustbrigaden", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 28, name: "Kustflottan", eInvoiceAddress: "00370952029958100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Kustflottan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 29, name: "Staben för Försvarsmaktens logistikverk", eInvoiceAddress: "00370952029966100", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Staben för Försvarsmaktens logistikverk", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 30, name: "1. Logistikregementet", eInvoiceAddress: "00370952029966400", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "1. Logistikregementet", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 31, name: "2. Logistikregementet", eInvoiceAddress: "00370952029966500", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "2. Logistikregementet", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 32, name: "3. Logistikregementet", eInvoiceAddress: "00370952029966600", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "3. Logistikregementet", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 33, name: "Systemcentret", eInvoiceAddress: "00370952029966200", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Systemcentret", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 34, name: "Logistikskolan", eInvoiceAddress: "00370952029966180", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Logistikskolan", address: "PL 10", postal: "ESPOO", zipCode: "02631", country: "FI", businessId: "0952029-9" }] },
    { customerNumber: 35, name: "Huoltovarmuuskeskus", eInvoiceAddress: "003702024196", operator: "Apix Messaging", addresses: [{ role: "PAYER", name: "Huoltovarmuuskeskus", address: "Aleksanterinkatu 48 A", postal: "HELSINKI", zipCode: "00100", country: "FI", businessId: "0202419-6" }] }
]

export const eInvoiceAddresses = [
    { name: '00370952029910000', value: '00370952029910000' },
    { name: '00370952029910120', value: '00370952029910120' },
    { name: '00370952029910210', value: '00370952029910210' },
    { name: '00370952029910270', value: '00370952029910270' },
    { name: '00370952029958000', value: '00370952029958000' },
    { name: '00370952029951200', value: '00370952029951200' },
    { name: '00370952029958200', value: '00370952029958200' },
    { name: '00370952029993400', value: '00370952029993400' },
    { name: '00370952029990330', value: '00370952029990330' },
    { name: '00370952029993300', value: '00370952029993300' },
    { name: '00370952029993100', value: '00370952029993100' },
    { name: '00370952029992200', value: '00370952029992200' },
    { name: '00370952029992300', value: '00370952029992300' },
    { name: '00370952029994100', value: '00370952029994100' },
    { name: '00370952029991100', value: '00370952029991100' },
    { name: '00370952029991800', value: '00370952029991800' },
    { name: '00370952029988500', value: '00370952029988500' },
    { name: '00370952029988610', value: '00370952029988610' },
    { name: '00370952029988630', value: '00370952029988630' },
    { name: '00370952029988650', value: '00370952029988650' },
    { name: '00370952029988710', value: '00370952029988710' },
    { name: '00370952029960690', value: '00370952029960690' },
    { name: '00370952029910400', value: '00370952029910400' },
    { name: '00370952029910500', value: '00370952029910500' },
    { name: '00370952029910300', value: '00370952029910300' },
    { name: '00370952029910310', value: '00370952029910310' },
    { name: '00370952029958250', value: '00370952029958250' },
    { name: '00370952029958100', value: '00370952029958100' },
    { name: '00370952029966100', value: '00370952029966100' },
    { name: '00370952029966400', value: '00370952029966400' },
    { name: '00370952029966500', value: '00370952029966500' },
    { name: '00370952029966600', value: '00370952029966600' },
    { name: '00370952029966200', value: '00370952029966200' },
    { name: '00370952029966180', value: '00370952029966180' },
    { name: '003702024196', value: '003702024196' }
]
