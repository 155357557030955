<template>
    <div>
        <button :disabled="isDisabled" v-on:click="open = !open" class="invoices-btn btn btn-secondary">{{$t("common.invoices")}}</button>
        <invoices :is-open="open" v-on:close="open = !open"></invoices>
     </div>
</template>
<script>
export default {
  data() {
      return {
        open: false,
        files: [],
      }
  },
  computed: {
      isDisabled: {
          get() {
              return this.$store.getters['invoice/getField']('invoiceField.editingDisabled')
          }
      }
  },
  components: {
      Invoices: () => import('./Invoices.vue')
  }
}
</script>

<style scoped>
.invoices-btn {
        border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* .appendices-container {
    position: relative;
} */
.hide {
    visibility: hidden;
}
.file-select {
    margin: 0 0 0 0;
}
.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: gray;
  width: 10em;

  border-radius: .3rem;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.file-select:hover > .select-button {
    background-color: rgb(155, 155, 134);
    border-radius: .3rem .3rem 0 0;

}

.file-select > input[type="file"] {
  display: none;
}
.input-form {
		transition: all 1s linear;
	-webkit-transition: all 1s linear;
  	-moz-transition: all 1s linear;
  	-o-transition: all 1s linear;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}
/* .file-list {
    position: absolute;
    bottom: -70%;
    visibility: hidden;
    background: white;
    z-index: 1;
    list-style: none;
    padding: 0 0.5em 0 0.5em;
}
.appendices-container:hover .file-list {
    visibility: visible;
}
.file-list:hover {
    visibility: visible;
} */
.appendices-container {
    position: relative;
    display: inline-block;
}

.file-list {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    /* display: none; */
    position: absolute;
    left: 0;
    padding: 0;
    border-radius: 0 .3rem .3rem .3rem;
    background-color: #2c3e50;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
}

.appendices-container:hover .file-list {
    display: block;
    max-height: 145px;
}

.file-list-item {
  text-align: center;
  padding: 0;
  margin: 0;
  color: white;
  width: 200px;
  display: block;
  white-space: nowrap;
  background-color: gray;
  text-overflow: ellipsis;
  overflow: hidden;
}
.file-list-item:hover {
  background-color: rgb(155, 155, 134);
}
</style>