<template>
  <div class="invoice-container">
    <div v-dragscroll="windowWidth > 768" class="zoom-container" v-bind:style="zoomContainerStyle">
      <div id="invoice-viewer" v-bind:style="zoomStyle">
        <div v-if="deliveryType" class="corner-ribbon top-left sticky blue shadow" v-bind:class="deliveryType"> {{ $t("invoice.type."+deliveryType)}}</div>
        <div id="invoice-header">
          <div id="invoice-header-left">
            <div id="invoice-logo">
              <img v-if="invoiceLogo" id="invoice-logo-image" :src="invoiceLogo">
              <p v-else id="invoice-header-seller-info">{{billingInfo.name}}, {{billingInfo.address}}, {{billingInfo.zipCode}} {{billingInfo.postal}}</p>
              </div>
            <ul v-if="currentPage==1" id="invoice-billing-address">
              <li class="bold">{{ $tlang(invoiceLanguage, "invoice.billingAddress") }}</li>
              <li style="height:12px;">{{ billingAddress.name }}</li>
              <li style="height:12px;">{{ billingAddress.address }}</li>
              <li style="height:12px;">
                {{ billingAddress.zipCode }}
                {{ billingAddress.postal }}
              </li>
              <li>{{ billingCountry.name }}</li>
            </ul>
            <ul v-if="currentPage==1" id="invoice-delivery-address">
              <li
                style="height:12px;"
                v-if="deliveryAddress.name"
                class="bold"
              >{{ $tlang(invoiceLanguage, "invoice.deliveryAddress") }}</li>
              <li style="height:12px;">{{ deliveryAddress.name}}</li>
              <li style="height:12px;">{{ deliveryAddress.address}}</li>
              <li style="height:12px;">{{ deliveryAddress.zipCode}} {{ deliveryAddress.postal}}</li>
              <li style="height:12px;">{{ deliveryCountry.name}}</li>
            </ul>
          </div>
          <div id="invoice-header-right">
            <ul id="invoice-billing-info">
              <li
                class="invoice-title"
              >{{ creditNote ? $tlang(invoiceLanguage, "invoice.titleCreditNote") : $tlang(invoiceLanguage, "invoice.title") }} {{ billingInfo.invoiceNumber}}</li>
              <li id="page-count">{{currentPage}}/{{pageCount}}</li>
              <br>
              <li style="height:12px;">{{formatDate(billingInfo.sentDate)}}</li>
            </ul>
            <div class="side-by-side">
              <ul class="left-side">
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.orderReference") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.contactPerson") }}</li>
              </ul>
              <ul class="right-side">
                <li style="height:12px;">{{ billingInfo.orderReference}}</li>
                <li style="height:12px;">{{ billingInfo.contactPerson }}</li>
              </ul>
            </div>
            <br>
            <ul v-if="currentPage==1" id="invoice-customer-address">
              <li><b>{{ $tlang(invoiceLanguage, "invoice.orderer") }}</b> ({{ customerAddress.businessId || billingAddress.businessId}})</li>
              <li
                style="max-height:24px;"
              >{{ customerAddress.name || billingAddress.name}}</li>
              <li
                style="height:12px;"
              >{{ customerAddress.address || billingAddress.address}}</li>
              <li
                style="height:12px;"
              >{{ customerAddress.zipCode || billingAddress.zipCode}} {{ customerAddress.postal || billingAddress.postal}}</li>
            </ul>
            <div v-if="currentPage==1" class="side-by-side">
              <ul class="bottom-no-margin left-side">
                <li
                  v-if="additionalInfo.deliveryDay"
                  class="bold"
                >{{ $tlang(invoiceLanguage, "invoice.deliveryDate") }}</li>
                <li
                  v-if="additionalInfo.deliveryMethod"
                  class="bold"
                >{{ $tlang(invoiceLanguage, "invoice.deliveryMethod") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.paymentCondition") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.dueDate") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.penaltyInterest") }}%</li>
                <li v-if="customerRecordSelected" class="bold">{{ $tlang(invoiceLanguage, "invoice.customerNumber") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.vatNumber") }}</li>
                <li class="bold">{{ $tlang(invoiceLanguage, "invoice.bankReference") }}</li>
                <li
                  v-if="toNumber(billingInfo.discount)>0"
                  class="bold"
                >{{ $tlang(invoiceLanguage, "invoice.discount") }}%</li>
              </ul>
              <ul class="bottom-no-margin right-side">
                <li v-if="additionalInfo.deliveryDay" style="height:12px;">{{ formatDate(additionalInfo.deliveryDay) }}</li>
                <li v-if="additionalInfo.deliveryMethod" style="height:12px;">{{ additionalInfo.deliveryMethod }}</li>            
                <li style="height:12px;">{{ billingInfo.paymentCondition }}</li>
                <li style="height:12px;">{{ formatDate(billingInfo.dueDate) }}</li>
                <li style="height:12px;">{{ toNumber(billingInfo.penaltyInterest)>0 ? billingInfo.penaltyInterest : "" }}</li>
                <li v-if="customerRecordSelected" style="height:12px;">{{ customerRecordSelected.customerNumber }}</li>
                <li style="height:12px;">{{ buyerTaxCode }} </li>
                <li style="height:12px;">{{ billingInfo.bankReference }}</li>
                <li v-if="toNumber(billingInfo.discount)>0" style="height:12px;">{{ billingInfo.discount }}</li>
              </ul>
            </div>
          </div>
        </div>
        <table id="invoice-rows">
          <tr id="invoice-rows-header">
            <th colspan="2">{{ $tlang(invoiceLanguage, "invoice.row.name") }}</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.row.amount") }}</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.row.price") }}</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.row.withoutVAT") }}</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.row.VAT") }}-%</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.discount") }}%</th>
            <th
              style="width:10%;text-align:right;"
            >{{ $tlang(invoiceLanguage, "invoice.row.total") }}</th>
          </tr>
          <tr v-if="billingInfo.reverseVAT">
            <th style="font-weight:normal" v-bind:class="{'no-border':isFakturamappen}" colspan="8">
            {{additionalInfo.vatFreeText}}
            </th>
          </tr>
          <tr v-if="currentPage==1">
            <td class="invoice-comment-row" colspan="8">{{additionalInfo.additionalInfo}}</td>
          </tr>
          <tr v-if="showTransferClause">
            <td class="invoice-comment-row" colspan="8">{{transferClause}}</td>
          </tr>
          <tbody ref="invoiceBody">
            <template v-for="(row, index) in splittedRows[currentPage-1]">
              <tr :key="'invoice-row-' + index">
                <td style="width:10%;">{{ row.code }}</td>
                <td style="width:30%">{{ row.name }}</td>
                <td
                  style="width:10%;text-align:right;"
                >{{ isFakturamappen ? prependMinus(row.ammount) : row.ammount }} {{ $tlang(invoiceLanguage, "units." + row.unit) }}</td>
                <td style="width:10%;text-align:right;">{{isFakturamappen ? row.price : prependMinus(row.price)}}</td>
                <td style="width:10%;text-align:right;">{{prependMinus(row.withoutVAT)}}</td>
                <td style="width:10%;text-align:right;">{{ formatNumber(row.VAT) }}</td>
                <td
                  style="width:10%;text-align:right;"
                >{{ toNumber(row.discount)>0 ? row.discount : "" }}</td>
                <td style="width:10%;text-align:right;">{{prependMinus(row.total)}}</td>
              </tr>
              <tr :key="'invoice-comment-row' + index" v-if="row.info">
                <td></td>
                <td class="invoice-comment-row" colspan="7">
                  <div>{{ row.info }}</div>
                </td>
              </tr>
              <tr v-for="(aft, key) in row.additionalFreeText" :key="'additional-free-text-'+index+'-'+key">
                <td></td>
                <td class="invoice-comment-row" colspan="7">
                  <div>{{ aft }}</div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div v-if="currentPage==pageCount" id="invoice-totals">
          <ul id="invoice-totals-left">
            <li>
              {{ $tlang(invoiceLanguage, "invoice.row.withoutVATSum") }}
              <div style="font-size:10px;display:inline;">{{billingInfo.currency.value}}</div>
            </li>
            <li v-for="(category, index) in usedTaxCategories" :key="index + '-left'">
              {{ $tlang(invoiceLanguage, "invoice.row.VAT") }} {{formatNumber(category)}} % {{ $tlang(invoiceLanguage, "invoice.row.sum") }} {{prependMinus(taxations[category].withoutVAT)}}
              <div style="font-size:10px;display:inline;">{{billingInfo.currency.value}}</div>
            </li>
            <li>
              {{ $tlang(invoiceLanguage, "invoice.row.VATSum") }}
              <div style="font-size:10px;display:inline;">{{billingInfo.currency.value}}</div>
            </li>
            <template>
              <li v-if="rounding">{{ $tlang(invoiceLanguage, "invoice.rounding") }}</li>
              <li v-if="rounding">{{ $tlang(invoiceLanguage, "invoice.amountToPay") }}</li>
            </template>
          </ul>
          <ul id="invoice-totals-right">
            <li>{{prependMinus(taxations['totalWithoutVAT'])}}</li>
            <li
              v-for="(category, index) in usedTaxCategories"
              :key="index + '-right'"
            >{{prependMinus(taxations[category].VAT)}}</li>
            <li class="invoice-totals-right-total">{{ prependMinus(invoiceSum) }}</li>
            <template v-if="rounding">

              <li >{{roundedOff}}</li>
              <li class="invoice-totals-right-rounding">{{prependMinus(taxations['totalRounded'])}}</li>
            </template>
          </ul>
        </div>


        <div id="invoice-footer">
        <p v-if="invoiceLanguage === 'sv'" style="position:absolute;top:-15px">
          <b>Godkänd för F-skatt</b>
        </p>

          <div class="invoice-footer-column">
            <div>{{billingInfo.name}}</div>
            <div>{{billingInfo.address}}</div>
            <div>{{billingInfo.zipCode}} {{billingInfo.postal}}</div>
            <div>{{billingInfo.country.name}}</div>
          </div>
          <div v-if="showContactInfo" class="invoice-footer-column">
            <div
              v-if="billingInfo.phone"
            >{{ $tlang(invoiceLanguage, "invoice.phone") }}: {{billingInfo.phone}}</div>
            <div
              v-if="billingInfo.fax"
            >{{ $tlang(invoiceLanguage, "invoice.fax") }}: {{billingInfo.fax}}</div>
            <div
              v-if="billingInfo.email"
            >{{ $tlang(invoiceLanguage, "invoice.email") }}: {{billingInfo.email}}</div>
            <div v-if="billingInfo.www">{{billingInfo.www}}</div>
          </div>
          <div
            v-bind:class="{'footer-narrower':!showContactInfo, 'footer-wider':showContactInfo}"
            class="invoice-footer-column"
          >
            <div>{{ $tlang(invoiceLanguage, "invoice.bankInfo") }}:</div>
            <div>{{spacedBankAccount}} {{billingInfo.BIC}}</div>
            <div></div>
          </div>
          <div class="invoice-footer-column">
            <div>{{ $tlang(invoiceLanguage, "invoice.businessId") }}: {{billingInfo.businessId}}</div>
            <div v-if="billingInfo.taxCode">{{ $tlang(invoiceLanguage, "invoice.vatNumShort") }}: {{ billingInfo.taxCode }}</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-arrows">
      <i v-on:click="zoomIn" class="viewer-interact-icon fas fa-search-plus"></i>
      <i class="fa fa-angle-left viewer-interact-icon" v-on:click="prevPage"/>
      <div class="page-count">{{ currentPage }}/{{ pageCount }}</div>
      <i class="fa fa-angle-right viewer-interact-icon" v-on:click="nextPage"/>
      <i v-on:click="zoomOut" class="viewer-interact-icon fas fa-search-minus"></i>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { codes } from "../assets/countrycodes";
import {
  firstLetterUpper,
  formatDate,
  formatComma,
  formatNumber,
  convertBlobToImage,
  toNumber,
  validateYtunnus,
  validateOrgNum,
  roundTwoD,
  roundFiveD
} from "../utils";
import { dragscroll } from "vue-dragscroll";

import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  name: "InvoiceViewer",
  directives: {
    dragscroll: dragscroll
  },
  props: ["isMobile", "windowWidth"],

  data() {
    return {
      validateYtunnus,
      formatNumber,
      formatDate,
      zoomCount: 1,
      currentPage: 1,
      toNumber,
    };
  },
  computed: {
    ...mapFields([
      "invoiceField.billingAddress",
      "invoiceField.customerAddress",
      "invoiceField.deliveryAddress",
      "invoiceField.billingInfo",
      "invoiceField.billingInfo.bankAccount",
      "invoiceField.additionalInfo",
      "productRow",
      "rowsTotal",
      "rowsTotalVAT",
      "userData",
      "rowsTotalWithoutVAT",
      "taxations",
      "currentInvoicePage",
      "invoiceLogo",
      "transferClause",
      "invoiceField.billingInfo.invoiceLanguage",
      "invoiceField.billingInfo.selectedTaxCategory",
      "invoiceField.billingInfo.rounding",
      "invoiceField.billingInfo.creditNote",
      "invoiceField.billingInfo.dueDate",
      "taxCategories",
      "deliveryType",
      "customerRecordSelected"
    ]),
    zoomStyle: function() {
      let zoomValue = this.isMobile
        ? this.windowWidth < 768
          ? 0.51
          : 1
        : this.zoomCount;
      return {
        transform: "scale(" + zoomValue + ")",
        "transform-origin": "top left"
      };
    },
    showTransferClause: function() {
      return this.userData.hasOwnProperty("laskumappiSkin") && this.userData.laskumappiSkin === "financelink"
    },
    zoomContainerStyle: function() {
      if (!this.isMobile && this.windowWidth > 768) {
        return {
          position: "absolute",
          border: "solid #96CAD4 2px",
          width: "624px",
          height: "880px",
          overflow: "scroll",
          visibility: "visible",
          "margin-left": "1em"
        };
      } else {
        return {
          position: "fixed",
          border: "none",
          width: "624px",
          height: "880px",
          overflow: "auto",
          visibility: "visible",
          "margin-left": "0em"
        };
      }
    },
    invoiceSum: function() {
      if (this.rounding) {
        return formatNumber(roundFiveD(toNumber(this.taxations['total'])))
      } else {
      return formatNumber(roundTwoD(toNumber(this.taxations['total'] )))

      }
    },
    roundedOff: function() {
      let roundOff = this.formatNumber(
        roundFiveD(
          this.toNumber(this.taxations["totalRounded"]) -
            this.toNumber(this.taxations["total"])
        )
      );
      if (this.billingInfo.creditNote) {
        if (roundOff.includes("-")) {
          return roundOff.replace("-", "");
        } else if (Number(roundOff.replace(",", ".")) > 0) {
          return "-" + roundOff;
        } else {
          return roundOff;
        }
      } else {
        return roundOff;
      }
    },
    deliveryCountry: function() {
      if (this.deliveryAddress.country.value) {
        return codes[this.invoiceLanguage].find(
          country =>
            country.value.toLowerCase() ===
            this.deliveryAddress.country.value.toLowerCase()
        );
      } else {
        return { name: "", value: "" };
      }
    },
    billingCountry: function() {
      if (this.billingAddress.country.value) {
        return codes[this.invoiceLanguage].find(
          country =>
            country.value.toLowerCase() ===
            this.billingAddress.country.value.toLowerCase()
        );
      } else {
        return { name: "", value: "" };
      }
    },
    sellerCountry: function() {
      if (this.billingInfo.country.value) {
        return codes[this.invoiceLanguage].find(
          country =>
            country.value.toLowerCase() ===
            this.billingInfo.country.value.toLowerCase()
        );
      } else {
        return { name: "", value: "" };
      }
    },

    spacedBankAccount: function() {
      if (this.bankAccount.Type === "IBAN") {
        let iban = this.bankAccount.Value.replace(/\s/g, "");
        let chunks = [];
        for (let i = 0; i < iban.length; i = i + 4) {
          chunks.push(iban.slice(i, i + 4));
        }
        return chunks.join(" ");
      } else {
        return this.bankAccount.Value;
      }
    },

    showContactInfo: function() {
      return (
        this.billingInfo.phone ||
        this.billingInfo.email ||
        this.billingInfo.fax ||
        this.billingInfo.www
      );
    },

    businessIdType: function() {
      if (this.billingInfo.country.value) {
        switch (this.billingInfo.country.value) {
          case "FI":
            return "Ytunnus";
          case "SE":
            return "orgNumber";
          default:
            return "businessId";
        }
      } else {
        return "businessId";
      }
    },

    sellerTaxCode: function() {
      if (
        validateYtunnus(this.billingInfo.businessId) &&
        this.billingInfo.country &&
        this.billingInfo.country.value === "FI"
      ) {
        return "FI" + this.billingInfo.businessId.replace("-", "");
      } else if (
        validateOrgNum(this.billingInfo.businessId) &&
        this.billingInfo.country &&
        this.billingInfo.country.value === "SE"
      ) {
        return "SE" + this.billingInfo.businessId.replace("-", "") + "01";
      } else {
        return this.billingInfo.taxCode;
      }
    },

    buyerTaxCode: function() {
      let bid = this.billingAddress.businessId || "";
      if (validateYtunnus(bid)) {
        return "FI" + bid.replace("-", "");
      } else if (validateOrgNum(bid)) {
        return "SE" + bid.replace("-", "") + "01";
      } else {
        return bid;
      }
    },
    isFakturamappen: function() {
      return this.userData.entryPoint === "fakturamappen"
    },

    firstPageBodyMaxHeight: function() {
      let height = 28
      if (!this.additionalInfo.deliveryMethod) {
        height += 1
      }
      if (!this.additionalInfo.deliveryDay) {
        height += 1
      }
      if (!this.customerRecordSelected) {
        height += 1
      }
      if (toNumber(this.billingInfo.discount)<=0) {
        height += 1
      }
      if (!this.billingInfo.reverseVAT) {
        height += 1
      }
      
      return height
    },
    splittedRows: function() {
      let val = [...this.productRow];
      let chunks = [];
      let firstPageBodyMaxHeight = this.firstPageBodyMaxHeight
      let heightUnits = val.map(r => {
        let height = 2
        if (r.info) {
          height++
        }
        if (r.additionalFreeText) {
          height += r.additionalFreeText.length
        }
        return height

      })
      let height = 0
      let breakIndexes = []
      for (let i = 0; i < heightUnits.length; i++) {
        height += heightUnits[i]
        if (height > firstPageBodyMaxHeight && i > 0 && breakIndexes.length == 0) {
          breakIndexes.push(i)
          height = heightUnits[i]
        } else if (breakIndexes.length > 0 && height > 50) {
          breakIndexes.push(i)
          height = heightUnits[i]
        }
      }

      let lastIdx = 0
      breakIndexes.forEach(idx => {
        chunks.push(val.slice(lastIdx, idx))
        lastIdx = idx
      })
      if (lastIdx < val.length) {
        chunks.push(val.slice(lastIdx, val.length))
      }

      return chunks;
    },
    rowLength: function() {
      return this.productRow.length
    },
    pageCount: function() {
      return this.splittedRows.length
    },
    usedTaxCategories: function() {
      let usedTaxCategories = this.productRow.reduce((acc, curr) => { 
        if (!acc.includes(curr.VAT)) {
          acc.push(curr.VAT)
        } 
        return acc
      }, [])
      usedTaxCategories.sort()

      return usedTaxCategories;
    }
  },
  filters: {
    prependMinusFilter: function(value) {
      if (value) {
        return this.billingInfo.creditNote &&
          Number(value.replace(",", ".")) > 0
          ? "-" + value
          : "" + value;
      }
      return value;
    }
  },
  methods: {
    prependMinus: function(value) {
      if (value) {
        return this.billingInfo.creditNote &&
          Number(value.replace(",", ".")) > 0
          ? "-" + value
          : "" + value;
      }
      value;
    },
    nextPage: function() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    prevPage: function() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    rowHeight: function(arr) {
      let height = 0;
      arr.map(row => {
        height++;
        if (row.info) {
          height++;
        }
        return height;
      });
    },
    zoomIn: function() {
      this.zoomCount = this.zoomCount + 0.3;
    },
    zoomOut: function() {
      if (this.zoomCount > 1) {
        this.zoomCount = this.zoomCount - 0.3;
      }
    },

  },
  watch: {
    pageCount: {
      handler: function() {
        if (this.currentPage > this.pageCount) {
          this.prevPage();
        }
      }
    }
  }
};
</script>

<style scoped>
.page-count {
  display: inline;
}

.nav-arrows {
  position: absolute;
  bottom: -60px;
  left: 37%;
  color: gray;
  font-size: 30px;
  display: inline;
  visibility: visible;
  background: rgba(179, 166, 166, 0.664);
  padding: 5px;
  border-radius: 15px;
  transform: translateY(0px);
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
}
.invoice-container {
  overflow: hidden;
  position: relative;
  width: 670px;
  height: 880px;
}

.invoice-container:hover > .nav-arrows {
  transform: translateY(-85px);
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
}
.viewer-interact-icon:hover {
  color: white;
  cursor: pointer;
}
.side-by-side {
  display: flex;
  flex-direction: row;
}

.left-side {
  width: 110px;
}
.right-side {
  width: 100px;
  margin-left: 10px;
}
.bold {
  font-weight: bold;
}

.invoice-comment-row {
  font-style: italic;
  padding: 0;
  max-width: 550px;
}

.invoice-comment-row > div {
  height: 10px;
}

ul {
  list-style: none;
  padding: 0;
}
li {
  text-align: left;
  line-height: 12px;
}

#invoice-totals {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 100px;
  right: 35px;
  font-weight: bold;
}
#invoice-totals > tr {
  height: 10px;
}
#invoice-totals-right {
  margin-left: 2.2em;
  border-top: black solid 1px;
}
/* #invoice-totals-right > li:last-child {
  border-top: black solid 1px;
} */
#invoice-totals-right > li {
  text-align: right;
  width: 10em;
}
.invoice-totals-right-total {
  border-bottom: 2px solid black;
  border-top: 1px solid black;
}
.invoice-totals-right-rounding {
  border-bottom: 1px solid black;
}
#invoice-totals-left > li {
  text-align: right;
}
#invoice-totals > tr > td {
  padding: 0 0 0 10px;
}
#totals-last-row {
  border-top: solid 1px black;
}


#invoice-footer {
  width: 90%;
  position: absolute;
  bottom: 20px;
}

#invoice-footer > tr {
  height: 10px;
}
#invoice-footer > tr > td {
  padding: 0;
  margin: 0;
}
#invoice-rows {
  width: 100%;
}

#invoice-header {
  display: flex;
  flex-flow: row;
}
#invoice-billing-address {
  clear: both;
}
#invoice-header-left {
  width: 50%;
}
#invoice-header-seller-info {
  position: absolute;
top: 44px;
  white-space: nowrap;

}
#invoice-header-right {
  width: 50%;
}

th {
  vertical-align: top;
  border-bottom: solid black 1px;
  text-align: left;
}
td {
  padding-top: 1em;
  margin-top: 3em;
  text-align: left;
}
.bottom-no-margin {
  margin-bottom: 1em;
}
.invoice-title {
  font-weight: bold;
  font-size: 10px;
}
#invoice-logo {
  position: relative;
  float: left;
  height: 107px;
  width: 100px;
}

#invoice-logo-image {
  float:left;
  height: 44px;
}
.invoice-container {
  position: relative;
}

#invoice-viewer {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 9px;
  position: relative;
  background-color: white;
  width: 620px;
  height: 876px;
  margin-left: 0em;
  visibility: visible;
  padding: 35px;
}

#invoice-footer {
  display: flex;
  flex-direction: row;
  border-top: solid black 0.5px;
}
.invoice-footer-column {
  width: 25%;
  text-align: left;
  line-height: 12px;
}
.footer-wider {
  width: 40%;
  margin-left: 4em;
}
.footer-narrower {
  width: 40%;
  margin-left: 7em;
}
#page-count {
  position: absolute;
  right: 35px;
  top: 35px;
}

.zoom-container {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
}
::-webkit-scrollbar {
  display: none;
}
::-moz-scrollbars {
  display: none;
}

/* RIBBON */
.corner-ribbon{
  width: 100px;
  background: #e43;
  position: absolute;
  text-align: center;
  line-height: 35px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

.corner-ribbon.top-left{
  top: 5px;
  left: -25px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.white{background: #f0f0f0; color: #555;}
.corner-ribbon.black{background: #333;}
.corner-ribbon.grey{background: #999;}
.corner-ribbon.eInvoice{
  background: #39d;
  font-size: 10px;    
  }
.corner-ribbon.Paper{
  background: #2c7;
  font-size: 14px;  
  }
.corner-ribbon.turquoise{background: #1b9;}
.corner-ribbon.email{
  background: #95b;
  font-size: 12px;    
  }
.corner-ribbon.red{background: #e43;}
.corner-ribbon.orange{background: #e82;}
.corner-ribbon.yellow{background: #ec0;}
.no-border {
  border: none;
  font-style: italic;
}
@media screen and (max-width: 768px) {
  .nav-arrows {
    position: absolute;
    color: gray;
    bottom: 0px;
    left: 4em;
    font-size: 30px;
    display: inline;
    visibility: visible;
    background: rgba(179, 166, 166, 1);
    padding: 5px;
    border-radius: 15px;
    transform: translateY(0px);
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }
  .invoice-container:hover > .nav-arrows {
    transform: translateY(0px);
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }
  .invoice-container {
    overflow: auto;
    position: relative;
    width: 660px;
    height: 500px;
  }
  .fa-search-plus {
    display: none;
  }
  .fa-search-minus {
    display: none;
  }
}
@media screen and (max-width: 620px) {
  #invoice-viewer {
    transform: scale(0.51);
    transform-origin: top left;
    margin-left: 4em;
  }
}
@media screen and (max-width: 320px) {
  #invoice-viewer {
    margin-left: 0;
  }
}
</style>
