<template>
    <div class="form-group col-lg" >
        <label class="form-label">{{ $t("invoiceForm.country") }}</label>
        <input
        :disabled="isDisabled || formGroup === 'billingAddress'" 
        :list="formGroup + '-countries'"
        class="form-control form-control-sm"  
        v-model.trim="input" 
        v-bind:placeholder="$t('invoiceForm.country')"
        v-bind:class="{'':validationValue === null,
                      'is-valid':validationValue === true,
                      'is-invalid':validationValue === false,
                      'no-validation':validation===null}">
        <datalist :id="formGroup + '-countries'">
            <option v-for="(country, index) in dataList" :key="index" :value="country.name">
            </option>
        </datalist>

    </div>
</template>

<script>
export default {
  props: ['formGroup', 'validation', 'fix', 'countries'],
  computed: {
    dataList: function() {
      if (this.input === '' || this.input.name === '') {
        return []
      }
      let count = 0
      return this.countries.filter(country => {
        return country.name.toLowerCase().startsWith(this.input.toLowerCase()) && count++ < 15
      })
    },
    input: {
      get() {
        let value = this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.country')
        return typeof value === 'object' ? value.name : value 
      },
      set(value) {
        value = this.countries.find(country => country.name.toLowerCase() === value.toLowerCase()) || value
        this.$store.dispatch('invoice/updateField', {formGroup:this.formGroup, dataField:'country', value:value})
      }
    },
    validationValue: {
      get() {
          return this.$store.getters['invoice/getField']('invoiceField.' + this.formGroup + '.validations.countryIsValid')
      }
    },
    isDisabled: {
      get() {
          return this.$store.getters['invoice/getField']('invoiceField.editingDisabled')
      },
      set() {
        let country = this.countries.find(country => country.name.toLowerCase() === this.input.toLowerCase())
          if (this.input && country) {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:true})
          } else if (this.input) {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:false})
          } else {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:null})
          }      
        }
    }
  },
  watch: {
    input: {
        handler: function(value) {
          let country = this.countries.find(country => country.name.toLowerCase() === value.toLowerCase())
          if (value && country) {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:true})
          } else if (value) {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:false})
          } else {
            this.$store.dispatch('invoice/validateField', {formGroup:this.formGroup, dataField:'country', value:null})
          }
        }, deep: true 
    }
  }
}
</script>

<style scoped>
.form-control-sm {
    padding: 0 2em 0 0.5em;
    height: 1.5em;
}

.no-validation {
    padding: 0 0.5em 0 0.5em;
}
.form-group {
    margin-bottom: 0.5rem;
}

label {
    text-align: left;
    margin-bottom: 0.2rem;
}

input {
    border-radius: 0;
    box-shadow: 1px 1px 1px 1px black;
    height: 1.5em;
}

input:focus {
    border-radius: 0;
}

@media screen and (max-width: 425px) {
    input {
        width: 100%;
        margin-bottom: 1em;
        box-shadow: none;
    }

    input:focus {
        border-radius: 0;
    }

    .row {
        padding: 0;
    }

    .form-group {
        margin: 0;
    }

    .date-field > label{
        display: inline;
    }
    .date-field > input {
        display: inline;
        width: 60%;
    }
}
</style>
