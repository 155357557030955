<template>
  <form id="billing-address" class="form-container">
    <h4 v-on:click="show" class="opener-link">
      {{ $t("invoiceForm.billingAddress") }}
      <i v-if="display" class="fa fa-minus"></i>
      <i v-else class="fa fa-plus"></i>
    </h4>
    <div v-bind:class="{'':display, 'hide':!display}">
      <div class="row">
        <customer-select-input class="col px-0" :selected="customerRecordSelected"></customer-select-input>
        <div v-if="entryPoint === 'laskumappi' && !laskumappiSkin" class="trafficlight col-lg-2">
          <div
            class="circle"
            :title="$t('trafficLight.'+trafficlight)"
            :class="{'circle_green':trafficlight==='GREEN',
                'circle_gray':trafficlight==='GRAY',
                'circle_yellow':trafficlight==='YELLOW'}"
          ></div>
        </div>
      </div>
      <div class="row col-lg">
        <input-field
          list="resultList"
          form-group="billingAddress"
          data-field="name"
          :label="$t('invoiceForm.name')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingAddress"
          data-field="businessId"
          :blur="handleBlur"
          :focus="handleFocus"
          :label="$t('invoiceForm.businessId')"
          :validation="businessIdValidator"
        />
        <select-field
          v-if="isSafari"
          form-group="billingAddress"
          data-field="country"
          :options="countries"
          :validation="simpleValidation"
        />
        <country-input v-else form-group="billingAddress" :countries="countries"/>
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingAddress"
          data-field="address"
          :label="$t('invoiceForm.address')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row col-lg">
        <input-field
          form-group="billingAddress"
          data-field="zipCode"
          :label="$t('invoiceForm.zipCode')"
          :validation="simpleValidation"
        />
        <input-field
          form-group="billingAddress"
          data-field="postal"
          :label="$t('invoiceForm.postal')"
          :validation="simpleValidation"
        />
      </div>
      <div class="row col-lg">
        <select-field
          form-group="billingInfo"
          data-field="eInvoiceAddress"
          :skin="entryPoint"
          :options="eInvoiceAddresses"
          :validation="null"
        />
      </div>
      <div v-bind:class="{'':manualeInvoiceInput, 'hide':!manualeInvoiceInput}">
        <input-field
          form-group="billingInfo"
          data-field="eInvoiceAddressManual"
          :label="$t('invoiceForm.eInvoiceAddressManual')"
          :validation="manualeInvoiceAddressValidator"
          :tooltip-obj="manualeInvoiceInputTooltip"
        />
        <select-field
          form-group="billingInfo"
          data-field="operator"
          :options="operators"
          :validation="simpleValidation"
        />
      </div>
      <div v-bind:class="{'':sendToEmail, 'hide':!sendToEmail}">
        <span class="text-danger" v-if="!sendingPDFattachments">{{ $t("invoiceForm.emailSendingAttachments")}}</span>
        <input-field
          form-group="billingInfo"
          data-field="receiverEmail"
          :label="$t('invoiceForm.receiverEmail')"
          :validation="validateEmail"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import {
  firstLetterUpper,
  simpleValidation,
  validateYtunnus,
  validateOrgNum,
  validateEmail,
  validateMinEight
} from "../utils";
import { codes, operators } from "../assets";
import InputField from "./InputField.vue";
import SelectField from "./SelectField.vue";
import CountryInput from "./CountryInput.vue";
import { createHelpers } from "vuex-map-fields";
import CustomerSelectInput from "@/components/customer/CustomerSelectInput.vue";

const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  name: "BillingAddress",
  data() {
    return {
      einvoiceAddrDeleted: false,
      willBeSentAsPaper: false,
      bidFocused: false,
      simpleValidation,
      validateYtunnus,
      validateOrgNum,
      validateMinEight,
      validateEmail,
      isSafari: false,
      trafficlight: "GRAY"
    };
  },
  mounted: function() {
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );
    this.$store.subscribeAction((action, state) => {
      if (action.type === "invoice/sendInvoice") {
        if (!this.emptyFields) {
          this.invalidFields.billingAddress.forEach(field => {
            this.validations[field + "IsValid"] = false;
          });
        }
      }
    });
  },
  computed: {
    ...mapFields([
      "invalidFields",
      "invoiceField.billingAddress",
      "invoiceField.customerAddress",
      "invoiceField.billingAddress.address",
      "invoiceField.billingAddress.country",
      "invoiceField.billingAddress.zipCode",
      "invoiceField.billingAddress.postal",
      "invoiceField.billingAddress.businessId",
      "invoiceField.billingAddress.validations",
      "invoiceField.billingAddress.name",
      "invoiceField.billingInfo",
      "invoiceField.billingInfo.eInvoiceAddressManual",
      "invoiceField.billingInfo.eInvoiceAddress",
      "invoiceField.billingInfo.operator",
      "invoiceField.billingInfo.receiverEmail",
      "customerRecordSelected",
      "userData.entryPoint",
      "userData.laskumappiSkin",
      "userData.TransferKey",
      "userData.TransferID",
      "userData.authToken",
      "eInvoiceAddresses",
      "userData",
      "appendices.files"
    ]),
    sendingPDFattachments: function() {
      if (this.sendToEmail) {
        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            if (!this.files[i].file.type.includes("pdf")) {
              return false
            }
          }
        }
      } else {
        return false
      }
      return true
    },
    eInvoiceAddressOptions: function() {
      let arr = this.eInvoiceAddresses
      if (arr.length === 0) {
        arr.push({name:"", value:""})
      }
      arr.push({name: this.$t("invoiceForm.sendToPrinting"), value: "TULOSTUS"})
      arr.push({name: this.$t("invoiceForm.sendToEmail"), value: "EMAIL"})
      return arr
    },
    
    operators: function() {
      return this.entryPoint === "laskumappi" ? operators.fi : operators.se
    },
    manualeInvoiceInput: function() {
      return this.eInvoiceAddress.value === "other";
    },
    manualeInvoiceInputTooltip: function() {
      if (this.einvoiceAddrDeleted) {
        return {
          visible: true,
          text: this.$t("invoiceForm.eInvoiceAddrRemoved"),
          type: "error"
        };
      } else if (this.willBeSentAsPaper && this.operator.name !== "Peppol-AP") {
        return {
          visible: true,
          width: 200,
          text: this.$t("invoiceForm.letterWillBeSent"),
          type: "error"
        };
      } else if (this.manualeInvoiceAddressValidator(this.eInvoiceAddressManual) === false && this.operator.name === "Peppol-AP"){ 
        return {
          visible: true,
          width: 200,
          text: this.$t("invoiceForm.peppolInvalidFormat"),
          type: "error"
        };
      } else if (this.willBeSentAsPaper && this.operator.name === "Peppol-AP") {
        return {
          visible: true,
          width: 200,
          text: this.$t("invoiceForm.peppolAddrNotFound"),
          type: "error"
        };  
      } else {
        return null
      }
    },
    sendToEmail: function() {
      return this.eInvoiceAddress.value === "EMAIL"
    },
    manualeInvoiceAddressValidator: function() {
      if (this.operator.name === "Peppol-AP") {
        return function(value) {
          if (value === "") {
            return null
          } else {
            return /^\d{4}:([A-z][A-z]){0,2}\d{1,}$/.test(value)
          }
        }
      }
      return this.simpleValidation
    },
    manualAddrAndOperatorWatchable: function() {
      return (
        this.billingInfo.eInvoiceAddressManual,
        this.billingInfo.operator,
        this.businessId,
        this.billingInfo.name,
        this.name,
        this.billingInfo.businessId,
        Date.now()
      );
    },
    display: {
      get: function() {
        return this.$store.getters["invoice/getField"](
          "sectionDisplay.billingAddress"
        );
      },
      set: function(val) {
        this.toggleSection({ path: "billingAddress", value: val });
      }
    },
    businessIdValidator: function() {
      switch (this.businessIdType) {
        case "Ytunnus":
          return this.validateYtunnus;
        case "orgNumber":
          return this.validateOrgNum;
        default:
          return this.validateMinEight;
      }
    },
    countries: function() {
      return codes[this.$store.getters["invoice/getField"]("userData.locale")];
    },
    businessIdType: function() {
      if (this.country.value) {
        switch (this.country.value) {
          case "FI":
            return "Ytunnus";
          case "SE":
            return "orgNumber";
          default:
            return "businessId";
        }
      } else {
        return "businessId";
      }
    }
  },
  watch: {
    sendToEmail: function(val) {
      if (!val) {
        this.receiverEmail = ""
      }
    },
    manualeInvoiceInput: function(val) {
      if (!val) {
        this.eInvoiceAddressManual = "";
        this.validations.eInvoiceAddressManualIsValid = null;
        this.operator = { name: "", value: "" };
        this.operatorIsValid = null;
      } else {
        window.parentIFrame.sendMessage(
          JSON.stringify({ type: "setContract" })
        ); //Set SuggestRoute contract
      }
    },
     manualeInvoiceAddressValidator: function(val) {
       this.billingInfo.validations.eInvoiceAddressManualIsValid = val(this.eInvoiceAddressManual)
    },
    eInvoiceAddresses: function(newVal, oldVal) {
      if (newVal.length === 0 && !(this.customerRecordSelected && this.customerRecordSelected.eInvoiceAddress) && this.eInvoiceAddress.value !== "other" && this.eInvoiceAddress.value !== "EMAIL") {
        this.billingInfo.eInvoiceAddress = { name: "", value: "" };
      }
    },
    eInvoiceAddress: function(val) {
      if (val.value === "") {
        this.setDeliveryType("Paper");
      } else if (val.value === "TULOSTUS") {
        this.setDeliveryType("Paper");
      } else if (val.value === "EMAIL") {
        this.setDeliveryType("email");
      } else {
        this.setDeliveryType("eInvoice");
      }
    },
    manualAddrAndOperatorWatchable: function() {
      if (this.saveTimer) clearTimeout(this.saveTimer);
      var that = this;
      this.saveTimer = setTimeout(() => {
        if (
          this.billingInfo.eInvoiceAddressManual.length >= 1 &&
          this.billingInfo.operator.value
        ) {
          this.isAddressDeleted({
            tid: this.userData.TransferID,
            operator: this.billingInfo.operator.value,
            address: this.billingInfo.eInvoiceAddressManual,
            tkey: this.userData.TransferKey
          })
            .then(response => {
              this.einvoiceAddrDeleted = response;
            })
            .then(() => {
            //  if (this.entryPoint === "fakturamappen") {
                
                let receiverBid = this.businessId || "x";
                if (this.operator.name === "Peppol-AP"){
                  receiverBid = "x"  
                }
                let senderBid = this.userData.Ytunnus;
                let senderName = this.billingInfo.name;
                let receiverName = this.name;
                let eInvoiceAddress = this.eInvoiceAddressManual;
                this.deliveryMethodQuery({
                  receiverBid,
                  senderBid,
                  senderName,
                  receiverName,
                  tid: this.TransferID,
                  tkey: this.TransferKey,
                  eInvoiceAddress
                }).then(data => {
                  if (data.find && !this.operator.name === "Peppol-AP") {
                    if (data.find(i => i["@type"] === "ChannelName")["$"] === 'eInvoice') {
                      this.businessId = data.find(i => i["@type"] === "ReceiverYtunnus")["$"]
                    }
                  }
                  if (data.find && this.operator.name === "Peppol-AP") {
                    let type =
                      data.find(i => i["@type"] === "ChannelName")["$"] ||
                      "Paper";
                    this.willBeSentAsPaper = type === "Paper";
                    this.billingInfo.validations.eInvoiceAddressManualIsValid = (!this.willBeSentAsPaper && this.manualeInvoiceAddressValidator(this.eInvoiceAddressManual))
                    this.setDeliveryType(type);
                  } else {
                    this.billingInfo.validations.eInvoiceAddressManualIsValid = true
                    this.willBeSentAsPaper = false;
                    this.setDeliveryType("eInvoice");
                  }
                });
            });
        } else if (this.eInvoiceAddress === "other") {
          this.willBeSentAsPaper = true;
          this.setDeliveryType("Paper");
        }
      }, 1000);
    },
    billingAddress: {
      handler: function(value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "country",
        ];
        if (this.businessId !== "") {
          fields.push("businessId")
        } 
        let invalidFields = [];
        fields.forEach(field => {
          if (this.validations[field + "IsValid"] !== true) {
            invalidFields.push(field);
          }
        });
        let name = "billingAddress";
        let obj = { name, fields: invalidFields };
        this.addToInvalidFields(obj);
      },
      deep: true
    },
    validations: {
      handler: function(value) {
        let fields = [
          "name",
          "address",
          "zipCode",
          "postal",
          "country"
        ];
        if (this.businessId !== "") {
          fields.push("businessId")
        }
        let foundInvalid = false;
        fields.forEach(name => {
          if (this.validations[name + "IsValid"] === false) {
            foundInvalid = true;
          }
        });
        if (foundInvalid) {
          this.display = true;
        }
      },
      deep: true
    },
/*     businessId: {
      handler: function(value) {
        if (value === "") {
          this.trafficlight = "GRAY";
        }
        if (this.businessIdValidator(value)) {
          let bid = value;
          if (bid.length >= 9 && this.bidFocused) {
            this.searchCustomer({
              bid: bid,
              token: this.authToken,
              field: "billingAddress"
            });
          }
          this.queryEInvoiceAddresses({
            bid: bid,
            tid: this.TransferID,
            tkey: this.TransferKey
          })
            .then(data => {
              this.$store.commit("invoice/SET_EINVOICE_ADDRESSES", data);
            })
            .catch(err => {
              console.log(err);
              this.$store.commit("invoice/RESET_EINVOICE_ADDRESSES");
              if (this.entryPoint === "fakturamappen") {
                let receiverBid = bid;
                let senderBid = this.billingInfo.businessId;
                let senderName = this.billingInfo.name;
                let receiverName = this.name;
                this.deliveryMethodQuery({
                  receiverBid,
                  senderBid,
                  senderName,
                  receiverName,
                  tid: this.TransferID,
                  tkey: this.TransferKey
                }).then(data => {
                  if (
                    data.find(
                      i => i["@type"] === "ChannelName" && i["$"] === "eInvoice"
                    )
                  ) {
                    let peppolAddress = "0007:" + bid.replace("-", "");
                    this.$store.commit("invoice/SET_EINVOICE_ADDRESSES", [
                      { value: peppolAddress, name: peppolAddress }
                    ]);
                  } else {
                    this.$store.commit("invoice/RESET_EINVOICE_ADDRESSES");
                  }
                });
              }
            });
          this.checkTrafficLight({
            bids: [bid],
            token: this.authToken
          })
            .then(response => {
              if (response && response.BusinessIDs) {
                this.trafficlight = response.BusinessIDs[0].Trafficlight;
              } else {
                this.trafficlight = "GRAY";
              }
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.resetEInvoiceAddresses();
        }
      },
      deep: true,
      immediate: true
    }, */
    businessIdValidator: function(value) {
      this.validateField({
        formGroup: "billingAddress",
        dataField: "businessId",
        value: value(this.businessId)
      });
    }
  },
  methods: {
    show: function() {
      this.display = !this.display;
    },
    handleFocus: function(evt) {
      this.bidFocused = true;
    },
    handleBlur: function(val) {
      this.bidFocused = false;
    },
    ...mapActions("invoice", [
      "addToInvalidFields",
      "isAddressDeleted",
      "validateField",
      "resetEInvoiceAddresses",
      "checkTrafficLight",
      "deliveryMethodQuery",
      "queryEInvoiceAddresses",
      "searchCustomer",
      "toggleSection",
      "setDeliveryType"
    ])
  },
  components: {
    InputField,
    SelectField,
    CountryInput,
    CustomerSelectInput
  }
};
</script>

<style scoped>
.hide {
  display: none;
}
.form-control-sm {
  padding: 0 1.5em 0 0.5em;
  height: 1.5em;
}

.form-group {
  margin-bottom: 0.5rem;
}
.fa {
  float: right;
}

#billing-address {
  max-width: 450px;
  margin-bottom: 0.5em;
}
.trafficlight {
  position: relative;
  width: 2.5em;
}
.circle {
  position: absolute;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  bottom: 7px;
  right: 0px;
  cursor: pointer;
}

.circle_green {
  background-color: green;
}

.circle_yellow {
  background-color: red;
}

.circle_gray {
  background-color: gray;
}
@media screen and (max-width: 769px) {
  #billing-address {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0.5em;
    margin-left: 0em;
  }
  .circle {
    width: 1.2em;
    height: 1.2em;
  }
}

@media screen and (max-width: 426px) {
  .row {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
  #billing-address {
    margin-left: 0em;
  }
}
</style>
