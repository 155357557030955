import { getField, updateField } from 'vuex-map-fields';
import { queryAddress } from "@/client"
import Vue from 'vue'
import { deliveryMethod, queryYTJ } from '../../client';
const BACKEND_URL = process.env.VUE_APP_BACKEND_URL //"http://localhost:11005" // "http://localhost:11005" 

export const customer = {
    namespaced: true,
    state: {
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },

    actions: {
        getNextFreeCustNum({ commit }, token) {
            return new Promise((resolve, reject) => {
                return Vue.http.get(BACKEND_URL + "/anyparty/next", { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        checkCustomerNumber({ commit }, { number, token }) {
            return new Promise((resolve, reject) => {
                return Vue.http.get(BACKEND_URL + "/anyparty/check/" + number, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        addCustomer({ commit }, { req, token }) {
            return new Promise((resolve, reject) => {
                return Vue.http.post(BACKEND_URL + "/anyparty/record", req, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        getCustomer({ commit }, { token, number }) {
            return new Promise((resolve, reject) => {
                return Vue.http.get(BACKEND_URL + "/anyparty/record/" + number, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data.data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        setFavoriteCustomer({ commit }, { req, token }) {
            return new Promise((resolve, reject) => {
                return Vue.http.put(BACKEND_URL + "/anyparty/record/favorite", req, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        deleteCustomer({ commit }, { token, customer }) {
            return new Promise((resolve, reject) => {
                return Vue.http.delete(BACKEND_URL + "/anyparty/record/" + customer, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        updateCustomer({ commit }, { req, token }) {
            return new Promise((resolve, reject) => {
                return Vue.http.put(BACKEND_URL + "/anyparty/record", req, { headers: { 'Authorization': 'Bearer ' + token } })
                    .then(response => response.json()).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        fetchCustomers({ commit }, token) {
            return new Promise((resolve, reject) => {
                Vue.http.get(BACKEND_URL + "/anyparty/record", { headers: { 'Authorization': 'Bearer ' + token } })
                    .then((response) => response.json()).then(data => {
                        resolve(data.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })

        },
        checkAddresses({ commit }, { bid, tid, tkey }) {
            return new Promise((resolve, reject) => {
        -           queryAddress(bid, tid, tkey).then((data) => {
                    console.log(data)
                    if (data !== "No einvoiceaddresses") {
                        resolve(data)
                    } else {
                        deliveryMethod(bid, tid, tkey).then((data) => {
                            resolve(data)
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                })
                    .catch(error => {
                        reject(error)
                    })

            })
        },

        deliveryMethodQuery({ commit }, { receiverBid, senderBid, senderName, receiverName, tid, tkey }) {
            return new Promise((resolve, reject) => {
                deliveryMethod(senderBid, receiverBid, senderName, receiverName, tid, tkey).then((data) => {
                    resolve(data)
                }).catch(error => {
                    resolve(error)
                })
            })
        },

        queryCustomer({commit}, bid) {
            return queryYTJ(bid, null)
        }
    },
}
