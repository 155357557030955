<template>
  <div
    class="form-group col-lg mt-auto"
    v-bind:class="{ 'date-field': type === 'date' }"
  >
    <label class="form-label">{{ label }}</label>
    <input
      :min="min"
      :maxlength="max ? max : '70'"
      :disabled="isDisabled || formGroup === 'billingAddress'"
      v-on:blur="handleBlur"
      v-on:focus="handleFocus"
      :list="list"
      class="form-control form-control-sm"
      v-bind:type="type"
      v-model.trim="fieldValue"
      v-bind:placeholder="label"
      v-bind:class="{
        '': validationValue === null,
        'is-valid': validationValue === true,
        'is-invalid': validationValue === false,
        'no-validation': validation === null,
      }"
    />
    <tooltip
      v-if="tooltipObj"
      :visible="tooltipObj.visible"
      :text="tooltipObj.text"
      :type="tooltipObj.type"
      :width="tooltipObj.width"
    ></tooltip>
  </div>
</template>
<script>
import Tooltip from "./Tooltip.vue";
export default {
  name: "inputField",
  props: {
    disabled: {
      default: null,
      type: Boolean,
    },
    formGroup: String,
    label: String,
    dataField: String,
    validation: Function,
    type: String,
    list: String,
    fix: String,
    blur: Function,
    min: String,
    max: String,
    focus: Function,
    tooltipObj: {
      default: null,
      type: Object,
    },
    displayTooltip: {
      default: false,
      type: Boolean,
    },
    tooltipText: {
      default: "",
      type: String,
    },
  },

  computed: {
    fieldValue: {
      get() {
        return this.$store.getters["invoice/getField"](
          "invoiceField." + this.formGroup + "." + this.dataField
        );
      },
      set(value) {
        this.$store.dispatch("invoice/updateField", {
          formGroup: this.formGroup,
          dataField: this.dataField,
          value: value,
        });
      },
    },
    validationValue: {
      get() {
        return this.$store.getters["invoice/getField"](
          "invoiceField." +
            this.formGroup +
            ".validations." +
            this.dataField +
            "IsValid"
        );
      },
    },
    isDisabled: {
      get() {
        if (this.disabled === null) {
          return this.$store.getters["invoice/getField"](
            "invoiceField.editingDisabled"
          );
        } else {
          return this.disabled;
        }
      },
    },
  },
  watch: {
    fieldValue: {
      handler: function (value) {
        if (this.validation) {
          this.$store.dispatch("invoice/validateField", {
            formGroup: this.formGroup,
            dataField: this.dataField,
            value: this.validation(value),
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    handleBlur: function (event) {
      if (this.blur) {
        this.blur(this.fieldValue);
      }
    },

    handleFocus: function (event) {
      if (this.focus) {
        this.focus(event);
      }
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style scoped>
.form-control-sm {
  padding: 0 2em 0 0.5em;
  height: 1.5em;
}

.no-validation {
  padding: 0 0.5em 0 0.5em;
}
.form-group {
  margin-bottom: 0.5rem;
}

label {
  text-align: left;
  margin-bottom: 0.2rem;
}

input {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px black;
  height: 1.5em;
}

input:focus {
  border-radius: 0;
}

@media screen and (max-width: 425px) {
  input {
    width: 100%;
    margin-bottom: 1em;
    box-shadow: none;
  }

  input:focus {
    border-radius: 0;
  }

  .row {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
}
</style>
