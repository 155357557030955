/*eslint-disable*/
import Vue from 'vue'
import { codes } from '../../assets/countrycodes'
import { eInvoiceAddresses } from "@/utils/addresses"
import { initTaxations } from '../../utils'

export function initInvoice(id, userData) {
    const locale = userData.locale || 'fi';
    const selectedTaxCategory = userData.entryPoint === 'laskumappi' ? 'fi' : 'se';
    const taxations = initTaxations(selectedTaxCategory);

    let defaultCountry = userData.entryPoint === 'laskumappi' ? codes[locale].find(c => c.value === 'FI') : codes[locale].find(c => c.value === 'SE')
    const state = {
        invoiceField: {
            editingDisabled: false,
            customerAddress: {
                businessId: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                validations: {
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: true,
                }
            },
            billingAddress: {
                businessId: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: defaultCountry,
                validations: {
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                }
            },
            deliveryAddress: {
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                validations: {
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                }
            },
            billingInfo: {
                contactPerson: '',
                receiverEmail: '',
                sentDate: '',
                dueDate: '',
                orderReference: '',
                invoiceNumber: '',
                sendereInvoiceAddress: '',
                bankAccount: { Type: '', Value: '' },
                bankReference: '',
                penaltyInterest: '0,00',
                discount: '',
                paymentCondition: 1,
                paymentMethod: userData.entryPoint === 'laskumappi' ? 'IBAN' : 'PLUSGIRO' || 'IBAN',
                taxCode: '',
                selectedTaxCategory: selectedTaxCategory,
                rounding: false,
                creditNote: false,
                reverseVAT: false,
                currency: userData.entryPoint === 'laskumappi' ? { name: 'EUR', value: 'EUR' } : { name: 'SEK', value: 'SEK' },
                additionalInfo: '',
                eInvoiceAddress: { name: '', value: '' },
                operator: { name: '', value: '' },
                eInvoiceAddressManual: '',
                invoiceLanguage: locale || 'fi',
                BIC: '',
                name: '',
                address: '',
                zipCode: '',
                postal: '',
                country: '',
                businessId: '',
                phone: '',
                fax: '',
                www: '',
                email: '',
                validations: {
                    contactPersonIsValid: null,
                    sentDateIsValid: null,
                    // dueDateIsValid: null,
                    //orderReferenceIsValid: null,
                    receiverEmailIsValid: null,
                    invoiceNumberIsValid: null,
                    sendereInvoiceAddressIsValid: null,
                    bankAccountIsValid: null,
                    bankReferenceIsValid: null,
                    penaltyInterestIsValid: true,
                    discountIsValid: null,
                    currencyIsValid: true,
                    businessIdIsValid: null,
                    nameIsValid: null,
                    addressIsValid: null,
                    zipCodeIsValid: null,
                    postalIsValid: null,
                    countryIsValid: null,
                    BICIsValid: null,
                    eInvoiceAddressManualIsValid: null,
                    operatorIsValid: null,
                    taxCodeIsValid: null,
                }
            },
            additionalInfo: {
                deliveryDay: '',
                deliveryMethod: '',
                additionalInfo: '',
                vatFreeText: '',
                validations: {
                    deliveryDayIsValid: null,
                    deliveryMethodIsValid: null,
                    vatFreeTextIsValid: null,
                }
            }
        },
        productRow: [{
            productId: null,
            id: 0,
            name: '',
            nameIsValid: null,
            code: '',
            codeIsValid: null,
            ammount: '',
            ammountIsValid: null,
            unit: 'piece',
            price: '',
            priceIsValid: null,
            discount: '',
            discountIsValid: null,
            VAT: 0,
            withoutVAT: 0,
            totalVAT: 0,
            total: 0,
            info: '',
            additionalFreeText: []
        }],
        appendices: {
            StorageID: '',
            StorageKey: '',
            StorageQuota: '',
            name: '',
            files: []
        },
        customerRecordSelected: null,
        taxations: taxations,
        currentRowId: 0,
        invoiceId: id,
        currentInvoicePage: 1,
        eInvoiceAddresses: eInvoiceAddresses,
        deliveryType: ''
    }
    return state
}